import { debounce } from 'lodash';
import { req } from 'react-reqq-lite';
import { toastSuccess } from 'react-geek-toast';

import { formatSort, reqLoader } from 'helper';
import { transformAttachments } from 'core-modules/merchant-onboarding/transformers';

import * as t from './transformers';
import * as c from './constants';

export const loadMerchantOptions = debounce(
  (keyword, callback, otherParams = {}) => {
    req.get({
      key: `${c.MERCHANT_LIST}/options`,
      url: '/profile/v1/my/merchants',
      params: {
        keyword,
        per_page: 10,
        ...otherParams,
      },
      transform: () => null,
      onSuccess: (res) => {
        callback((res?.response?.data || []).map(t.transformMerchantOptions));
      },
      onError: () => {
        callback([]);
      },
    });
  },
  500
);

export const listMerchants = (params, onSuccess) => {
  req.get({
    key: c.MERCHANT_LIST,
    url: '/profile/v1/my/merchants',
    params: formatSort(params),
    transform: (res) => (res.data || []).map(t.transformMerchant),
    onSuccess: (res) => {
      req.set(c.MERCHANT_PAGER, res?.response?.meta || {});
      req.set(c.MERCHANT_FILTER, params);
      if (onSuccess)
        onSuccess((res?.response?.data || []).map(t.transformMerchant));
    },
  });
};

export const selectMerchant = (id) => {
  req.get({
    key: c.MERCHANT_ITEM,
    url: `/profile/v1/my/merchants/${id}`,
    transform: (res) => t.transformMerchant(res.data),
  });
};

export const getOrgMerchant = () => {
  req.get({
    key: c.ORG_MERCHANT,
    url: '/profile/v1/my/merchants?is_org_merchant=1',
    transform: (res) =>
      res?.data?.[0] ? t.transformMerchant(res?.data?.[0]) : {},
  });
};

export const promiseGetMerchant = (id) =>
  new Promise((resolve) => {
    req.get({
      key: `${c.MERCHANT_ITEM}/quick`,
      url: `/profile/v1/my/merchants/${id}`,
      transform: (res) => t.transformMerchant(res?.data || {}),
      onSuccess: (res) =>
        resolve(t.transformMerchant(res?.response?.data || {})),
    });
  });

export const updateMerchantTheme = (id, payload, onSuccess) => {
  req.put({
    key: c.MERCHANT_ITEM_THEME,
    url: `/profile/v1/my/merchants/${id}/update_theme`,
    payload: t.payloadTheme(payload),
    onSuccess: (res) => {
      toastSuccess('Updated!');
      selectMerchant(id);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const createMerchant = (payload, onSuccess, onError) => {
  req.post({
    key: c.MERCHANT_ITEM,
    url: '/profile/v1/my/merchants',
    payload,
    onSuccess: (res, state) => {
      toastSuccess('Created!');
      setTimeout(() => {
        listMerchants(state[c.MERCHANT_FILTER]);
      }, 1000);
      const newData = t.transformMerchant(res?.response?.data);
      if (newData?.id) {
        selectMerchant(newData.id);
        req.set('NEW_DATA/merchant-profile', newData);
      }
      if (onSuccess) onSuccess(newData);
    },
    onError,
  });
};

export const updateMerchant = (id, payload, onSuccess) => {
  req.put({
    key: c.MERCHANT_ITEM,
    url: `/admin/user_mng/v1/user/${id}/profile`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess('Updated!');
      setTimeout(() => {
        listMerchants(state[c.MERCHANT_FILTER]);
      }, 1000);
      selectMerchant(id);
      if (onSuccess) onSuccess(t.transformMerchant(res?.response?.data));
    },
  });
};

export const updateMerchantEmail = (id, payload, onSuccess) => {
  req.put({
    key: c.MERCHANT_ITEM,
    url: `/admin/user_mng/v1/user/${id}`,
    payload,
    onSuccess: (res, state) => {
      toastSuccess('Updated!');
      setTimeout(() => {
        listMerchants(state[c.MERCHANT_FILTER]);
      }, 1000);
      selectMerchant(id);
      if (onSuccess) onSuccess(t.transformMerchant(res?.response?.data));
    },
  });
};

export const removeMerchant = (id, onSuccess) => {
  req.remove({
    key: c.MERCHANT_ITEM,
    url: `/profile/v1/my/merchants/${id}`,
    onSuccess: (res, state) => {
      toastSuccess('Removed!');
      setTimeout(() => {
        listMerchants(state[c.MERCHANT_FILTER]);
      }, 1000);
      req.set(c.MERCHANT_ITEM, null);
      if (onSuccess) onSuccess();
    },
  });
};

export const listMerchantSettlement = (params) => {
  req.get({
    key: c.MERCHANT_SETTLEMENT_LIST,
    url: '/admin/user_mng/v1/user',
    params: formatSort(params),
    transform: (res) => (res.data || []).map(t.transformMerchantSettlement),
    onSuccess: (res) => {
      req.set(c.MERCHANT_SETTLEMENT_PAGER, res?.response?.meta || {});
      req.set(c.MERCHANT_SETTLEMENT_FILTER, params);
    },
  });
};

export const getCountCard = {
  total_transactions: () => {
    reqLoader(`${c.COUNT_CARD}/total_collections`, true);
    req.get({
      key: `${c.COUNT_CARD}/total_transactions`,
      url: '/admin/game_mng/v1/game',
      per_page: 0,
      onSuccess: (res) => {
        req.set(`${c.COUNT_CARD}/total_transactions`, {
          value: res?.response?.transaction_human,
        });
        req.set(`${c.COUNT_CARD}/total_collections`, {
          value: res?.response?.collection_human,
        });
        reqLoader(`${c.COUNT_CARD}/total_collections`, false);
      },
      onError: () => {
        reqLoader(`${c.COUNT_CARD}/total_collections`, false);
      },
    });
  },
  total_settlement: () => {
    reqLoader(`${c.COUNT_CARD}/total_receivebles`, true);
    req.get({
      key: `${c.COUNT_CARD}/total_settlement`,
      url: '/admin/game_mng/v1/game',
      per_page: 0,
      onSuccess: (res) => {
        req.set(`${c.COUNT_CARD}/total_settlement`, {
          value: res?.response?.transaction_human,
        });
        req.set(`${c.COUNT_CARD}/total_receivebles`, {
          value: res?.response?.collection_human,
        });
        reqLoader(`${c.COUNT_CARD}/total_receivebles`, false);
      },
      onError: () => {
        reqLoader(`${c.COUNT_CARD}/total_receivebles`, false);
      },
    });
  },
};

/* MERCHANT PROFILE START */
export const getGeneralInfo = (account_id) => {
  req.get({
    key: c.MERCHANT_GENERAL_INFO,
    url: `/profile/v1/my/onboarding/${account_id}/general`,
    transform: (res) => t.transformGeneralInfo(res?.data || {}),
  });
};

export const getBusinessInfo = (account_id, type) => {
  const BUSINESS_TYPE_URL = {
    CORPORATION: `/profile/v1/my/onboarding/${account_id}/corporate`,
    SOLE_PROPRIETORSHIP: `/profile/v1/my/onboarding/${account_id}/sole`,
    INDIVIDUAL: `/profile/v1/my/onboarding/${account_id}/individual`,
    PARTNERSHIP: `/profile/v1/my/onboarding/${account_id}/partnership`,
  };
  req.get({
    key: c.MERCHANT_BUSINESS_INFO,
    url: BUSINESS_TYPE_URL[type],
    transform: (res) => t.transformBusinessInfo(res?.data || {}, type),
  });
};
/* MERCHANT PROFILE START */

/* MERCHANT CATEGORY & CHANNEL ATTACHMENT START */
export const listMerchantAttachedCategories = (id) => {
  req.get({
    key: c.LIST_PAYMENT_CATEGORIES(id),
    url: `/merchant_channel/v1/merchants/${id}/payment_categories`,
    transform: (res) =>
      (res.data || []).map(t.transformAttachedPaymentCategory),
  });
};

export const listPaymentCategoryChannels = (merchantId, paymentCategoryId) => {
  req.get({
    key: c.LIST_PAYMENT_CATEGORY_CHANNELS(paymentCategoryId),
    url: `/merchant_channel/v1/merchants/${merchantId}/payment_categories/${paymentCategoryId}/payment_channels`,
    transform: (res) => (res.data || []).map(t.transformAttachedPaymentChannel),
  });
};

export const toggleAttachedChannelStatus = (
  merchantId,
  categoryId,
  channelId,
  payload,
  successCallback,
  errorCallback
) => {
  req.put({
    key: c.TOGGLE_CHANNEL_STATUS,
    url: `/merchant_channel/v1/merchants/${merchantId}/payment_categories/${categoryId}/payment_channels/${channelId}/status`,
    payload,
    onSuccess: () => {
      if (typeof successCallback === 'function') successCallback();
    },
    onError: () => {
      if (typeof errorCallback === 'function') errorCallback();
    },
  });
};

export const updateAttachedChannelSequence =
  (merchantId, categoryId, onSuccess, onError) =>
  (channelId, payload, onInlineSuccess, onInlineError) => {
    req.put({
      key: c.UPDATE_CHANNEL_SEQUENCE,
      url: `/core_merchant_channel/v1/mng/merchants/${merchantId}/payment_categories/${categoryId}/payment_channels/${channelId}/sequence`,
      payload,
      onSuccess: () => {
        if (typeof onSuccess === 'function') onSuccess();
        if (typeof onInlineSuccess === 'function') onInlineSuccess();
      },
      onError: () => {
        if (typeof onError === 'function') onError();
        if (typeof onInlineError === 'function') onInlineError();
      },
    });
  };

/* MERCHANT CATEGORY & CHANNEL ATTACHMENT END */

export const merchantAttachments = (id) => {
  req.get({
    key: c.MERCHANT_ATTACHMENTS,
    url: `/profile/v1/my/onboarding/${id}/due_diligence`,
    transform: (res) => transformAttachments(res.data),
    onError: () => {
      req.set(c.MERCHANT_ATTACHMENTS, null);
    },
  });
};

export const getEReceipt = (account_id) => {
  req.get({
    key: c.MERCHANT_ERECEIPT,
    url: `/profile/v1/my/onboarding/${account_id}/ereceipt`,
    transform: (res) => t.transformEReceipt(res?.data || {}),
  });
};

export const getMerchantSettlementProfileOptions = (merchantId) => {
  req.get({
    key: `${c.MERCHANT_SETTLEMENT_TEMPLATE}/options`,
    url: `/merchant_settlement/v1/my/settlement_profiles`,
    params: {
      merchant_profile_uuid: merchantId,
    },
    transform: (res) =>
      (res?.data || []).map((x) => ({
        label: x?.name,
        value: x?.uuid,
        data: x,
      })),
  });
};

export const getMerchantSettlementTemplateList = (merchantId) => {
  req.get({
    key: `${c.MERCHANT_SETTLEMENT_TEMPLATE}/${merchantId}`,
    url: `/merchant_settlement/v1/my/settlement_templates`,
    params: {
      merchant_profile_uuid: merchantId,
    },
    transform: (res) => {
      return (res?.data || []).map((row) => ({
        id: String(row.uuid),
        name: row?.name,
        type: row?.type,
        profiles: (row?.profiles || []).map((x) => ({
          uuid: x?.settlement_profile?.uuid,
          value: x.value,
          label: x?.settlement_profile?.name,
          details: x?.settlement_profile,
        })),
      }));
    },
  });
};

export const createMerchantSettlementTemplate = (
  merchantId,
  payload,
  onSuccess,
  onError
) => {
  req.post({
    key: c.MERCHANT_SETTLEMENT_TEMPLATE,
    url: `/merchant_settlement/v1/my/settlement_templates`,
    payload: {
      ...payload,
      merchant_profile_uuid: merchantId,
    },
    onSuccess: (res) => {
      toastSuccess('Created!');
      setTimeout(() => {
        getMerchantSettlementTemplateList(merchantId);
      }, 1000);
      if (onSuccess) onSuccess(res);
    },
    onError,
  });
};

export const updateMerchantSettlementTemplate = (
  merchantId,
  id,
  payload,
  onSuccess,
  onError
) => {
  req.put({
    key: c.MERCHANT_SETTLEMENT_TEMPLATE,
    url: `/merchant_settlement/v1/my/settlement_templates/${id}`,
    payload: {
      ...payload,
      merchant_profile_uuid: merchantId,
    },
    onSuccess: (res) => {
      toastSuccess('Updated!');
      setTimeout(() => {
        getMerchantSettlementTemplateList(merchantId);
      }, 1000);
      if (onSuccess) onSuccess(res);
    },
    onError,
  });
};

export const deleteMerchantSettlementTemplate = (
  merchantId,
  id,
  onSuccess,
  onError
) => {
  req.remove({
    key: c.MERCHANT_SETTLEMENT_TEMPLATE,
    url: `/merchant_settlement/v1/my/settlement_templates/${id}`,
    onSuccess: (res) => {
      toastSuccess('Updated!');
      setTimeout(() => {
        getMerchantSettlementTemplateList(merchantId);
      }, 1000);
      if (onSuccess) onSuccess(res);
    },
    onError,
  });
};

export const getSettlementDashboard = {
  total: (params) => {
    reqLoader(`SETTLEMENT_COUNT_CARD/aggs`, true);
    req.get({
      key: `SETTLEMENT_COUNT_CARD/aggs`,
      url: `/merchant_aggs/v1/aggs/total_settlements`,
      params,
      onSuccess: ({ response }) => {
        req.set(`SETTLEMENT_COUNT_CARD/total`, {
          value: response?.data?.total_amount ?? '0.00',
        });
        req.set(`SETTLEMENT_COUNT_CARD/total_unsettled`, {
          value: response?.data?.total_unsettled ?? '0.00',
        });
        req.set(`SETTLEMENT_COUNT_CARD/total_settled`, {
          value: response?.data?.total_settled ?? '0.00',
        });
        reqLoader(`SETTLEMENT_COUNT_CARD/aggs`, false);
      },
      onError: () => {
        reqLoader(`SETTLEMENT_COUNT_CARD/aggs`, false);
      },
    });
  },
};

export const getMerchantSettlementLedgerList = (merchantId, params) => {
  req.get({
    key: c.MERCHANT_SETTLEMENT_LEDGER_LIST,
    url: '/merchant_ledger/v1/my/ledgers',
    params: { merchant_profile_uuid: merchantId, ...params },
    transform: (res) => (res?.data || []).map(t.transformSettlementLedger),
    onSuccess: (res) => {
      req.set(c.MERCHANT_SETTLEMENT_LEDGER_PAGER, res?.response?.meta || {});
      req.set(c.MERCHANT_SETTLEMENT_LEDGER_FILTER, params);
    },
  });
};

export const getMerchantSettlementRequestList = (merchantId, params) => {
  req.get({
    key: c.MERCHANT_SETTLEMENT_REQUEST_LIST,
    url: '/merchant_settlement/v1/my/settlement_requests',
    params: { merchant_profile_uuid: merchantId, ...params },
    transform: (res) => (res?.data || []).map(t.transformSettlementRequest),
    onSuccess: (res) => {
      req.set(c.MERCHANT_SETTLEMENT_REQUEST_PAGER, res?.response?.meta || {});
      req.set(c.MERCHANT_SETTLEMENT_REQUEST_FILTER, params);
    },
  });
};

export const createMerchantSettlementRequest = (
  merchantId,
  payload,
  onSuccess
) => {
  req.post({
    key: c.MERCHANT_SETTLEMENT_REQUEST,
    url: '/merchant_settlement/v1/my/settlement_requests',
    payload: { merchant_profile_uuid: merchantId, ...payload },
    onSuccess: (res, state) => {
      toastSuccess('Created!');
      if (onSuccess) onSuccess();
      setTimeout(() => {
        getMerchantSettlementRequestList(
          merchantId,
          state[c.MERCHANT_SETTLEMENT_REQUEST_FILTER]
        );
      }, 1000);
    },
  });
};

export const updateMerchantSettlementRequest = (
  merchantId,
  id,
  payload,
  onSuccess
) => {
  req.put({
    key: c.MERCHANT_SETTLEMENT_REQUEST,
    url: `/merchant_settlement/v1/my/settlement_requests/${id}`,
    payload: { merchant_profile_uuid: merchantId, ...payload },
    onSuccess: (res, state) => {
      toastSuccess('Updated!');
      if (onSuccess) onSuccess();
      setTimeout(() => {
        getMerchantSettlementRequestList(
          merchantId,
          state[c.MERCHANT_SETTLEMENT_REQUEST_FILTER]
        );
      }, 1000);
    },
  });
};

export const deleteMerchantSettlementRequest = (merchantId, id, onSuccess) => {
  req.remove({
    key: c.MERCHANT_SETTLEMENT_REQUEST,
    url: `/merchant_settlement/v1/my/settlement_requests/${id}`,
    onSuccess: (res, state) => {
      toastSuccess('Deleted!');
      if (onSuccess) onSuccess();
      setTimeout(() => {
        getMerchantSettlementRequestList(
          merchantId,
          state[c.MERCHANT_SETTLEMENT_REQUEST_FILTER]
        );
      }, 1000);
    },
  });
};

export const getSettlementLedgerBalance = (merchantId) => {
  req.get({
    key: `${c.MERCHANT_SETTLEMENT_LEDGER_LIST}/balance`,
    url: '/merchant_ledger/v1/my/ledger_balance',
    params: { merchant_profile_uuid: merchantId, per_page: 1000 },
    transform: (res) =>
      (res?.data || []).map((row) => ({
        id: row.uuid,
        ...row,
      })),
  });
};
