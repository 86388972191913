import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const SIZE = {
  xxs: 'px-1.5 py-0.5 text-xs rounded-none',
  xs: 'px-2 py-1 text-xs rounded-none',
  sm: 'px-2.5 py-1.5 text-sm leading-4 rounded-sm',
  default: 'px-4 py-2 text-sm rounded-lg',
  lg: 'px-5 py-3 text-base rounded-lg',
  xl: 'px-6 py-3 text-base rounded-xl',
};

const ICON_SIZE = {
  xxs: 'h-4 w-4',
  xs: 'h-4 w-4',
  sm: ' h-5 w-5',
  default: 'h-5 w-5',
  lg: ' h-5 w-5',
  xl: 'h-5 w-5',
};

const COLOR = {
  primary:
    'transition shadow-sm border-transparent text-white bg-primary-500 hover:bg-primary-400 focus:ring-primary-500 shadow-primary-lg',
  secondary:
    'transition shadow-sm border-transparent text-white bg-secondary-500 focus:ring-secondary-600',
  link: 'transition text-primary-500 bg-white focus:ring-primary-500 hover:text-primary-500 hover:bg-gray-50 border-none shadow-none',
  success:
    'transition shadow-sm border-transparent text-white bg-green-500 focus:ring-green-500',
  danger:
    'transition shadow-sm border-transparent text-white bg-danger-500 hover:bg-danger-600 focus:ring-danger-500 shadow-danger-lg',
  dark: 'transition shadow-sm border-transparent text-white bg-[#2D2D2D] hover:bg-000 focus:ring-[#2D2D2D] shadow-gray-lg',
  transparent:
    'transition shadow-none text-primary-500 bg-transparent hover:underline focus:underline border-transparent font-normal',
  outline:
    'transition shadow-sm border-primary-500 text-primary-500 hover:border-primary-700 focus:ring-primary-500 hover:text-primary-700',
  default:
    'transition shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:ring-primary-500',
  defaultShow:
    'border-white hover:border-gray-200 transition shadow-none hover:shadow-md text-gray-700 bg-white focus:ring-primary-500',
};

const Button = React.forwardRef(
  (
    {
      size,
      primary,
      secondary,
      success,
      danger,
      dark,
      link,
      outline,
      transparent,
      defaultShow,
      className,
      children,
      icon: Icon,
      ...props
    },
    ref
  ) => {
    const colorClass = React.useMemo(() => {
      if (primary) return COLOR.primary;
      if (secondary) return COLOR.secondary;
      if (success) return COLOR.success;
      if (danger) return COLOR.danger;
      if (dark) return COLOR.dark;
      if (outline) return COLOR.outline;
      if (link) return COLOR.link;
      if (transparent) return COLOR.transparent;
      if (defaultShow) return COLOR.defaultShow;
      return COLOR.default;
    }, [
      primary,
      secondary,
      success,
      danger,
      dark,
      outline,
      link,
      transparent,
      defaultShow,
    ]);
    return (
      <button
        ref={ref}
        className={cn(
          'items-center border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 space-x-1 flex justify-center',
          'focus:z-10 relative disabled:cursor-not-allowed disabled:bg-opacity-50',
          className,
          SIZE[size],
          colorClass
        )}
        type="button"
        {...props}
      >
        {Icon && (
          <Icon className={cn('', ICON_SIZE[size])} aria-hidden="true" />
        )}
        {children && <span>{children}</span>}
      </button>
    );
  }
);

Button.defaultProps = {
  className: '',
  size: 'default',
  primary: false,
  secondary: false,
  success: false,
  danger: false,
  dark: false,
  link: false,
  outline: false,
  transparent: false,
  defaultShow: false,
  icon: null,
  children: null,
};

Button.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'default', 'lg', 'xl']),
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  success: PropTypes.bool,
  danger: PropTypes.bool,
  dark: PropTypes.bool,
  link: PropTypes.bool,
  outline: PropTypes.bool,
  transparent: PropTypes.bool,
  defaultShow: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default Button;
