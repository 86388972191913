import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import * as assets from 'assets/images';
import { useHistory } from 'react-router-dom';
import Button from 'partial/components/Button';
// import backgroundLogo from 'assets/images/backgrounds/bg-banner-right.png';
import Version from 'partial/components/Version';
import { HiArrowLeft } from 'react-icons/hi';
import LoginForm from '../forms/LoginForm';
import MultiFactorAuthForm from '../forms/MultiFactorAuthForm';

function SignIn() {
  const websiteUrl = '/';
  const history = useHistory();
  const [isMFAEnabled, setIsMFAEnabled] = useState(false);

  const handleRedirectToWebsite = () => {
    history.push(websiteUrl);
  };

  const handleOnLogin = useCallback(
    (res) => {
      const { is_mfa_enabled = false } = res?.response?.data || {};
      setIsMFAEnabled(is_mfa_enabled);
    },
    [setIsMFAEnabled]
  );

  const handleOnSessionExpires = useCallback(() => setIsMFAEnabled(false), [
    setIsMFAEnabled,
  ]);

  return (
    <div className="flex-grow flex bg-gradient-to-b from-[#C6D6FE] to-[#EEFAFF]">
      <div className="w-full max-w-xl p-5 flex">
        <div className="bg-white rounded-2xl flex-grow w-full p-8 lg:p-4">
          {!isEmpty(websiteUrl) && (
            <Button
              className="mb-16"
              icon={HiArrowLeft}
              onClick={handleRedirectToWebsite}
            >
              Go back to Homepage
            </Button>
          )}
          <div className="max-w-sm mx-auto">
            <div className="mb-12 lg:mb-24">
              <img className="h-10" src={assets.logoDark} alt="Brand" />
            </div>
            <div className="mb-10 font-bold text-xl">Sign In</div>
            {!isMFAEnabled ? (
              <LoginForm onLogin={handleOnLogin} />
            ) : (
              <MultiFactorAuthForm onSessionExpires={handleOnSessionExpires} />
            )}
          </div>
          <p className="text-slate-400 text-xs text-center mt-10">
            &copy; 2024 eGovPay <Version />
          </p>
        </div>
      </div>
      <div className="flex-1 lg:flex flex-col hidden">
        <div className="mt-auto mx-auto mb-24">
          <img src={assets.signInBanner} alt="banner" />
        </div>
        <div className="mb-auto mx-auto text-center max-w-md">
          <div className="text-xl font-bold mb-2">Welcome to eGovPay</div>
          <div className="text-[#475569] font-semibold">
            Your secured government payment gateway
            <br />
            Make it easy for your customers with our one-stop epayment
            integration
          </div>
        </div>
      </div>
    </div>
  );
}

SignIn.propTypes = {};

export default SignIn;
