import React from 'react';
import Banner from './Banner';
import Header from './Header';
import Services from './Services';
import HowItWorks from './HowItWorks';
import GetStartedBanner from './GetStartedBanner';
import Footer from './Footer';

function Landing() {
  return (
    <div className="flex-shrink-0">
      <div className="bg-gradient-to-b from-primary-700 to-primary-600 pt-20 overflow-hidden lg:overflow-visible">
        <Header />
        <Banner />
      </div>
      <Services />
      <HowItWorks />
      <GetStartedBanner />
      <Footer />
    </div>
  );
}

Landing.propTypes = {};

export default Landing;
