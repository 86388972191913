import React from 'react';
import PropTypes from 'prop-types';

import Button from 'partial/components/Button';
import { ModalCard } from 'partial/components/Modal';

import Privacy from '../components/Privacy';

function PrivacyContainer({ onClose }) {
  return (
    <ModalCard size="lg">
      <div className="flex flex-col space-y-4 p-10 h-screen overflow-y-auto">
        <Privacy />
        <div className="flex justify-end border-t pt-4">
          <Button size="lg" type="button" primary onClick={onClose}>
            Done
          </Button>
        </div>
      </div>
    </ModalCard>
  );
}

PrivacyContainer.propTypes = {
  onClose: PropTypes.instanceOf(Function).isRequired,
};

export default PrivacyContainer;
