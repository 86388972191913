import React from 'react';
// import PropTypes from 'prop-types'

function AttachmentGuidelines() {
  return (
    <div className="bg-blue-50 border border-blue-100 rounded-md p-4">
      <div className="space-y-1">
        {/* <div className="flex items-center space-x-2">
          <HiCheckCircle className="w-4 h-4 flex-shrink-0 text-green-500" />
          <p className="text-gray-500">
            The documents must be in clear copies and in color.
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <HiCheckCircle className="w-4 h-4 flex-shrink-0 text-green-500" />
          <p className="text-gray-500">
            Make sure the documents have complete pages.
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <HiCheckCircle className="w-4 h-4 flex-shrink-0 text-green-500" />
          <p className="text-gray-500">
            The documents must not be redacted (all information is visible and
            data is legible).
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <HiCheckCircle className="w-4 h-4 flex-shrink-0 text-green-500" />
          <p className="text-gray-500">
            All corners of the documents are visible against the backdrop.
          </p>
        </div> */}
        <div className="text-gray-500 font-semibold">Instructions:</div>
        <ul className="list list-decimal pl-4 text-gray-500">
          <li>
            Download the{' '}
            <a
              className="text-primary-500 hover:underline"
              href="/files/eGovPay_Bank_Details_Form.pdf"
              download
              target="_blank"
            >
              eGovPay bank details form
            </a>
            .
          </li>
          <li>Fill in the required information accurately and completely.</li>
          <li>Visit your chosen bank branch.</li>
          <li>Present the completed eGovPay form to a bank representative.</li>
          <li>
            Request the bank to stamp or sign the form as required. This usually
            indicates the bank&apos;s verification of your account details.
          </li>
          <li>
            <p>Once the form is signed and stamped, create a digital copy.</p>
            <ul className="list list-disc pl-4">
              <li>
                Scan: Use a scanner to create a high-quality PDF or image file.
              </li>
              <li>
                Take a Photo: Ensure the photo is clear, well-lit, and captures
                the entire form without cropping important information.
              </li>
            </ul>
          </li>
          <li>
            Return to the eGovPay system website and click the Attach file.
          </li>
          <li>Select the scanned or photographed file of the signed form</li>
          <li>After uploading the signed form, submit it for processing</li>
          <li>
            The egovpay system will likely generate a confirmation email
            notification. Keep a copy of this for your records.
          </li>
        </ul>
      </div>
    </div>
  );
}

AttachmentGuidelines.propTypes = {};

export default AttachmentGuidelines;
