/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { HiChevronLeft, HiChevronRight, HiPencil, HiX } from 'react-icons/hi';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import Button from 'partial/components/Button';
import { RiUploadCloud2Line } from 'react-icons/ri';
import { BiImages, BiCloudUpload } from 'react-icons/bi';
import { showAlert, showLightbox } from 'partial/components/Modal';
import {
  uploadPhotoOrPdf,
  uploadPhotosAndPdfs,
} from 'partial/form/FormPhotoDnd';

function LoaderIcon() {
  return (
    <svg
      className="animate-spin h-4 w-4 text-primary-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx={12}
        cy={12}
        r={10}
        stroke="currentColor"
        strokeWidth={4}
      />
      <path
        className="opacity-50 sm:opacity-0"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
}

function ImagePreview({ name, onChange, values, label, readOnly }) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const selected = React.useMemo(
    () => values[selectedIndex] || values[0] || '',
    [selectedIndex, values]
  );
  const handleUpload = async ({ target }) => {
    const filteredFiles = [];
    for (let i = 0; i < target.files.length; i += 1) {
      const file = target.files[i];
      const type = file.type || '';
      if (type.indexOf('image/') > -1 || type.indexOf('application/pdf') > -1) {
        filteredFiles.push(file);
      }
    }
    const prevValue = values.filter((x) => !isEmpty(x));
    onChange((state) => ({
      ...state,
      [name]: `${prevValue
        .concat(filteredFiles)
        .map(() => 'uploading')
        .join(',')}`,
    }));
    const files = await uploadPhotosAndPdfs(filteredFiles);
    onChange((state) => ({
      ...state,
      [name]: `${prevValue.concat(files).join(',')}`,
    }));
  };
  const isMultiple = values.length > 1;
  const handlePrev = () => {
    const newIndex = selectedIndex - 1;
    setSelectedIndex(newIndex < 0 ? values.length - 1 : newIndex);
  };
  const handleNext = () => {
    const newIndex = selectedIndex + 1;
    setSelectedIndex(newIndex >= values.length ? 0 : newIndex);
  };
  const handleUpdate = (index) => async () => {
    const tempValues = [...values];
    onChange((state) => ({
      ...state,
      [name]: values.map((x, i) => (index === i ? 'uploading' : x)).join(','),
    }));
    const newPhoto = await uploadPhotoOrPdf();
    if (!newPhoto) {
      onChange((state) => ({
        ...state,
        [name]: tempValues.join(','),
      }));
      return;
    }
    onChange((state) => ({
      ...state,
      [name]: values.map((x, i) => (index === i ? newPhoto : x)).join(','),
    }));
  };
  const handleDelete = (index) => async () => {
    showAlert({
      title: 'Are you sure you want to delete this attachment?',
      yesLabel: 'Delete',
      onYes: (closeAlert) => {
        onChange((state) => ({
          ...state,
          [name]: values.filter((x, i) => index !== i).join(','),
        }));
        if (selectedIndex !== 0) {
          setSelectedIndex(selectedIndex - 1);
        }
        closeAlert();
      },
    });
  };

  const [imageLoaded, setImageLoaded] = React.useState(false);
  React.useEffect(() => setImageLoaded(false), [setImageLoaded, selected]);

  React.useEffect(() => {
    if (!values?.length) return;
    if (selectedIndex >= values?.length) {
      setSelectedIndex(0);
    }
  }, [setSelectedIndex, selectedIndex, values]);
  return (
    <div className="bg-white flex items-center max-h-[500px] h-full w-full overflow-hidden relative group rounded-lg">
      {isEmpty(selected) ? (
        <div className="flex-1 flex h-full">
          <div className="m-auto flex flex-col items-center justify-center">
            <BiCloudUpload className="h-16 w-16 text-gray-300" />
            <div className="m-auto text-gray-400 text-xs font-light mb-3">
              No attachment.
            </div>
            {!readOnly ? (
              <div>
                {selected === 'uploading' ? (
                  <div className="text-center loader-wrapper">
                    <div className="text-gray-500 text-xs uppercase tracking-wide mb-1">
                      Uploading...
                    </div>
                    <div className="loaderBar w-32">
                      <div className="loaderBarInner" />
                    </div>
                  </div>
                ) : (
                  <label className="relative z-10 bg-white border rounded px-2 py-1 hover:bg-gray-50 cursor-pointer flex items-center">
                    <RiUploadCloud2Line className="inline-block mr-1" />
                    <span className="text-xs font-medium text-gray-700">
                      Attach File
                    </span>
                    <input
                      className="absolute h-0 w-0 opacity-0"
                      type="file"
                      onChange={handleUpload}
                      accept="image/*, application/pdf"
                      multiple
                    />
                  </label>
                )}
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="absolute inset-0 h-full w-full">
          {selected === 'uploading' ? (
            <div className="h-full w-full flex">
              <div className="m-auto">
                <div className="text-center loader-wrapper">
                  <div className="text-gray-500 text-xs uppercase tracking-wide mb-1">
                    Uploading...
                  </div>
                  <div className="loaderBar w-32">
                    <div className="loaderBarInner" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <button
                className="absolute h-full w-full z-10 inset-0 focus:ring-1 focus:ring-primary-500"
                type="button"
                onClick={() =>
                  showLightbox({
                    title: `${label || 'Attachment'}${
                      values?.length > 1
                        ? ` (${selectedIndex + 1} of ${values?.length})`
                        : ''
                    }`,
                    content: selected,
                  })
                }
              />
              {!imageLoaded ? (
                <div className="absolute inset-0 top-0 left-0 flex">
                  <div className="m-auto">
                    <LoaderIcon />
                  </div>
                </div>
              ) : null}
              {queryString.parseUrl(selected)?.query?.type === 'pdf' ? (
                <iframe
                  src={selected}
                  frameBorder="0"
                  title="File Preview"
                  allowtransparency="true"
                  style={{
                    overflow: 'hidden',
                    height: '100%',
                    width: '100%',
                  }}
                  height="100%"
                  width="100%"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                  onLoad={() => setImageLoaded(true)}
                />
              ) : (
                <img
                  key={selected}
                  src={selected}
                  onLoad={() => setImageLoaded(true)}
                  alt=""
                  className="object-contain h-full w-full m-auto"
                />
              )}
            </>
          )}
        </div>
      )}

      {/* <img className="object-fill h-full w-full" src={placeholder} alt="" /> */}
      {isMultiple ? (
        <>
          <button
            className="w-12 h-12 bg-gray-900/75 hover:bg-primary-500 opacity-50 sm:opacity-0 group-hover:opacity-100 rounded-l-full shadow-2xl absolute right-0 top-1/3 z-10 transform hover:scale-125 translate-x-0 scale-100 transition ease-in-out duration-100"
            type="button"
            onClick={handleNext}
          >
            <div className="flex justify-center">
              <HiChevronRight className="w-7 h-7 text-white flex-shrink-0" />
            </div>
          </button>
          <button
            className="w-12 h-12 bg-gray-900/75 hover:bg-primary-500 opacity-50 sm:opacity-0 group-hover:opacity-100 rounded-r-full shadow-2xl absolute left-0 top-1/3 z-10 transform hover:scale-125 -translate-x-0 scale-100 transition ease-in-out duration-100"
            type="button"
            onClick={handlePrev}
          >
            <div className="flex justify-center">
              <HiChevronLeft className="w-7 h-7 text-white flex-shrink-0" />
            </div>
          </button>
        </>
      ) : null}
      {!isEmpty(selected) ? (
        <>
          <div className="absolute bottom-2 right-2 bg-gray-800 opacity-50 sm:opacity-0 group-hover:opacity-100 rounded-md px-2 py-1.5 text-white text-xs flex items-center space-x-2">
            <BiImages className="w-4 h-4 flex-shrink-0" />
            <span>
              {selectedIndex + 1} of {values.length}
            </span>
          </div>
          {!readOnly ? (
            <div className="z-20 absolute top-2 right-2 opacity-50 sm:opacity-0 group-hover:opacity-100 transition">
              <div className="btn-group">
                <Button
                  onClick={handleUpdate(selectedIndex)}
                  icon={HiPencil}
                  size="sm"
                  dark
                >
                  Re-upload
                </Button>
                <Button
                  onClick={handleDelete(selectedIndex)}
                  icon={HiX}
                  size="sm"
                  dark
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

ImagePreview.defaultProps = {
  readOnly: false,
};

ImagePreview.propTypes = {
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.string.isRequired,
};

export default ImagePreview;
