import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useInputId } from './hooks';

function FormMobileNumber({
  id: defaultId,
  icon: Icon,
  label,
  name,
  onChange,
  onSetFieldValue,
  value,
  error,
  required,
  disabled,
  readOnly,
}) {
  const [countryCode, setCountryCode] = useState('ph');

  const [id] = useInputId(defaultId);
  const handleChange = (v, data) => {
    setCountryCode(data?.countryCode);
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: `+${v}`,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, `+${v}` ?? '');
    }
  };
  const showIcon = Icon || error;
  const isError = !!error;

  const isPhoneValid = useMemo(
    () => !value || isValidPhoneNumber(value, countryCode?.toUpperCase()),
    [value, countryCode]
  );

  return (
    <div
      className={cn(
        'relative border rounded-md px-3 py-2 focus-within:ring-1 z-[2]',
        isError || !isPhoneValid
          ? 'border-red-300 focus-within:ring-red-500 focus-within:border-red-500'
          : 'border-gray-200 focus-within:ring-primary-600 focus-within:border-primary-600',
        disabled || readOnly ? 'bg-gray-100' : 'bg-white'
      )}
    >
      <label
        htmlFor={id}
        className="w-full text-xs font-medium text-primary-500 flex justify-between"
      >
        <span>{label}</span>
        {!required && (
          <span className="tracking-wider px-2 text-[8px] py-0.5 text-gray-500 rounded-full bg-gray-100 uppercase ml-1">
            optional
          </span>
        )}
      </label>
      <PhoneInput
        id={id}
        inputClass={cn(
          'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base',
          'focus:outline-none disabled:bg-gray-100',
          readOnly ? 'bg-gray-100' : 'disabled:text-gray-500'
        )}
        buttonStyle={{
          border: 0,
        }}
        country={countryCode}
        countryCodeEditable={false}
        value={value || ''}
        onChange={handleChange}
        disabled={disabled || readOnly}
        readOnly={readOnly}
        autoFormat={false}
        disableCountryGuess
        inputStyle={{
          border: 0,
          height: '1.5rem',
          paddingLeft: '35px',
          background: 'transparent',
        }}
      />
      {showIcon && (
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          title={error || ''}
        >
          {error ? (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          ) : (
            <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
        </div>
      )}
    </div>
  );
}

FormMobileNumber.defaultProps = {
  id: '',
  icon: false,
  error: null,
  required: false,
  onChange: false,
  onSetFieldValue: false,
  disabled: false,
  readOnly: false,
};

FormMobileNumber.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default React.memo(FormMobileNumber);
