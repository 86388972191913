import React from 'react';
import * as yup from 'yup';
import { isEmpty, omit } from 'lodash';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { toastError } from 'react-geek-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import { HiOutlineHome, HiOutlineMail } from 'react-icons/hi';
import { isValidPhoneNumber } from 'react-phone-number-input';

import Form, { ErrorLabel, setError422 } from 'partial/form/Form';
import FormInput from 'partial/form/FormInput';
import Button from 'partial/components/Button';
import { setIsLoading } from 'utils/reqq-helper';
import FormPassword from 'partial/form/FormPassword';
import { useCreateModal } from 'partial/components/Modal';
import FormMobileNumber from 'partial/form/FormMobileNumber';
import { useMakeInputNotAcceptSpecialCharacters } from 'partial/hooks';

import { useRegister } from '../hooks';
import { REGISTER } from '../constants';
import PrivacyContainer from '../containers/PrivacyContainer';

const initialValues = {
  email_address: '',
  company_name: '',
  password: '',
  password_confirmation: '',
  terms: false,
  contact_name: '',
  contact_number: '',
};

const validationSchema = yup.object().shape({
  contact_first_name: yup.string().required('Required'),
  // .min(4, 'Must have atleast 4 characters'),
  contact_last_name: yup.string().required('Required'),
  // .min(4, 'Must have atleast 4 characters'),
  contact_number: yup
    .string()
    .required('Required')
    .test({
      name: 'must be valid',
      test: (value) => isValidPhoneNumber(value || ''),
      message: 'Invalid',
    }),
  email_address: yup.string().email('Invalid').required('Required'),
  company_name: yup.string().required('Required'),
  password: yup.string().required('Required'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Does not match')
    .required('Required'),
  terms: yup
    .bool()
    .oneOf([true], 'You must agree and accept the terms and privacy policy'),
});

const ENABLE_CAPTCHA = true;

function SignUpForm({ onSuccess }) {
  const recaptchaRef = React.useRef();
  const [isLoading, register] = useRegister();

  const [validateOnChange, setValidateOnChange] = React.useState(false);
  const { registerOnSetFieldValue } = useMakeInputNotAcceptSpecialCharacters();

  const {
    values,
    setFieldValue,
    setFieldError,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (form) => {
      try {
        setIsLoading('post', REGISTER, true);
        const payload = omit(form, ['password_confirmation', 'terms']);
        if (ENABLE_CAPTCHA) {
          payload.captcha = await recaptchaRef.current.executeAsync();
        }
        register(
          payload,
          () => {
            onSuccess({ email_address: payload.email_address });
          },
          (err) => {
            setError422(err, setFieldError);
            recaptchaRef.current.reset();
          }
        );
      } catch (err) {
        toastError('An error occurred! Please try again');
        setIsLoading('post', REGISTER, false);
        recaptchaRef.current.reset();
      }
    },
    validateOnChange,
  });
  const createModal = useCreateModal();

  const openPrivacyModal = React.useCallback(
    (e) => {
      e.preventDefault();
      createModal({
        content: (close) => <PrivacyContainer onClose={close} />,
      });
    },
    [createModal]
  );
  const websiteUrl = '/';

  const handleRedirectToWebsite = () => {
    window.location.assign(websiteUrl);
  };
  return (
    <>
      <Form
        error={errors}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className="space-y-3"
      >
        <div>
          <FormInput
            icon={HiOutlineMail}
            name="company_name"
            label="Agency Name"
            type="text"
            required
            onSetFieldValue={setFieldValue}
            value={values?.company_name}
            error={errors?.company_name}
          />
          <ErrorLabel name="company_name" />
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div>
            <div className="relative z-10">
              <FormInput
                name="contact_first_name"
                label="Rep. First Name"
                onSetFieldValue={registerOnSetFieldValue(
                  'contact_first_name',
                  setFieldValue
                )}
                value={values?.contact_first_name}
                error={errors?.contact_first_name}
                required
              />
            </div>
            <ErrorLabel name="contact_first_name" />
          </div>
          <div>
            <div className="relative z-10">
              <FormInput
                name="contact_last_name"
                label="Rep. Last Name"
                onSetFieldValue={registerOnSetFieldValue(
                  'contact_last_name',
                  setFieldValue
                )}
                value={values?.contact_last_name}
                error={errors?.contact_last_name}
                required
              />
            </div>
            <ErrorLabel name="contact_last_name" />
          </div>
        </div>
        <div>
          <div className="relative z-10">
            <FormMobileNumber
              name="contact_number"
              label="Representative's Mobile Number"
              onSetFieldValue={setFieldValue}
              value={values?.contact_number}
              error={errors?.contact_number}
              required
            />
          </div>
          <ErrorLabel name="contact_number" />
        </div>
        <div className="relative py-2">
          <hr />
          <span className="absolute bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-2 text-slate-500 font-semibold">
            Login Access
          </span>
        </div>
        <div>
          <div className="relative z-[1]">
            <FormInput
              icon={HiOutlineMail}
              name="email_address"
              label="Email Address"
              type="email"
              required
              onSetFieldValue={setFieldValue}
              value={values?.email_address}
              error={errors?.email_address}
            />
          </div>
          <ErrorLabel name="email_address" />
        </div>
        <div>
          <FormPassword
            name="password"
            label="Create Password"
            required
            onSetFieldValue={setFieldValue}
            value={values?.password}
            error={errors?.password}
          />
          <ErrorLabel name="password" />
        </div>
        {!errors?.password ? (
          <div className="bg-primary-100 text-primary-500 rounded p-2 w-full">
            <ul className="list-disc pl-6 text-xs">
              <li>The password must be at least 10 characters</li>
              <li>at least one uppercase character, </li>
              <li>one number</li>
              <li>and one special character.</li>
            </ul>
          </div>
        ) : null}
        <div>
          <FormPassword
            name="password_confirmation"
            label="Confirm Password"
            required
            onSetFieldValue={setFieldValue}
            value={values?.password_confirmation}
            error={errors?.password_confirmation}
          />
          <ErrorLabel name="password_confirmation" />
        </div>
        <div>
          <div className="">
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="agreement"
                  aria-describedby="agreement-description"
                  name="accept-terms"
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded mt-1"
                  onChange={({ target }) =>
                    setFieldValue('terms', target.checked)
                  }
                  checked={values?.terms}
                />
              </div>
              <div className="ml-3 text-gray-600">
                <label htmlFor="agreement">
                  By selecting this checkbox, agree and accept the{' '}
                  <a href="/" onClick={openPrivacyModal}>
                    eGovPay Terms of Service
                  </a>{' '}
                  and{' '}
                  <a href="/" onClick={openPrivacyModal}>
                    Privacy Policy
                  </a>{' '}
                  on behalf of my agency.
                </label>
              </div>
            </div>
          </div>
        </div>
        <ErrorLabel name="terms" />
        {ENABLE_CAPTCHA ? (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6Lfg6XcmAAAAAFrfHdO6JqNzPQn4WtvgfTVZivSC" // TEMPORARY
            // sitekey={
            //   process.env.REACT_APP_RECAPTCHA ||
            //   '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
            // }
          />
        ) : null}
        <Button
          size="xl"
          primary
          type="submit"
          className="w-full"
          disabled={isLoading}
          onClick={() => setValidateOnChange(true)}
        >
          Create an eGovPay Account
        </Button>
        <div>
          <div className="text-center space-x-1 mt-6">
            <span>Already have an account?</span>
            <Link className="inline-block" to="/signin">
              Sign In
            </Link>
          </div>
          <div className="sm:hidden block">
            {!isEmpty(websiteUrl) && (
              <Button
                className="mx-auto mt-5 mb-3"
                defaultShow
                icon={HiOutlineHome}
                onClick={handleRedirectToWebsite}
              >
                Go back to website
              </Button>
            )}
          </div>
        </div>
      </Form>
    </>
  );
}

SignUpForm.defaultProps = {
  onSuccess: null,
};

SignUpForm.propTypes = {
  onSuccess: PropTypes.instanceOf(Object),
};

export default SignUpForm;
