import React, { useState, useEffect, useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { BiHome } from 'react-icons/bi';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import Button from 'partial/components/Button';
import Version from 'partial/components/Version';
import { logoDark } from 'assets/images';
import { ModalCard, useCreateModal } from 'partial/components/Modal';
import IconAccountVerified from 'assets/images/icons/icon-account-verified.svg';
import IconInvalidActivation from 'assets/images/icons/icon-invalid-activation.svg';

import { useForgotPassword, useSetPassword } from '../hooks';
import NewPasswordForm from '../forms/NewPasswordForm';

const useOpenSetPasswordSuccessModal = () => {
  const history = useHistory();
  const createModal = useCreateModal();

  const openSetPasswordSuccessModal = useCallback(
    ({ type = 'forgot' }) => {
      const isTypeForgot = type === 'forgot';
      createModal({
        content: (close) => (
          <ModalCard size="sm">
            <div className="flex flex-col items-center p-6">
              <img src={IconAccountVerified} alt="verified" className="w-36" />
              <h1 className="font-semibold text-lg text-gray-800 tracking-wide mt-5">
                Password {isTypeForgot ? 'Change' : 'Set'} Successfully
              </h1>
              <p className="mt-2 text-sm text-gray-600">
                Please login to your account using the new password you&apos;ve
                just entered.
              </p>
              <Button
                default
                className="mt-10 uppercase"
                onClick={() => {
                  close();
                  history.push('/');
                }}
              >
                Login to your account now
              </Button>
            </div>
          </ModalCard>
        ),
      });
    },
    [createModal, history]
  );

  return openSetPasswordSuccessModal;
};

function SetPassword() {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const isForgot = useMemo(() => match?.path.includes('/forgot_password'), [
    match,
  ]);

  const openSetPasswordSuccessModal = useOpenSetPasswordSuccessModal();

  const [isValidUuid, setIsValidUuid] = useState(true);
  const [additionalPayload, setAdditionalPayload] = useState({});

  const {
    confirmForgotPassword,
    isLoadingConfirmation: isLoadingForgotConfirmation,
    setNewPassword: setForgottenPassword,
    isLoadingSetForgottenPassword,
  } = useForgotPassword({
    onConfirmation: ({ response: { data } }) => {
      setAdditionalPayload(data);
    },
    onConfirmationError: () => {
      setIsValidUuid(false);
    },
    onSetNewPassword: () => openSetPasswordSuccessModal({ type: 'forgot' }),
  });

  const {
    setPassword,
    confirmSetPassword,
    isLoadingSetPassword,
    isLoadingConfirmation,
  } = useSetPassword({
    onConfirmation: ({ response: { data } }) => {
      setAdditionalPayload(data);
    },
    onConfirmationError: () => {
      setIsValidUuid(false);
    },
    onSetPassword: () => openSetPasswordSuccessModal({ type: 'new' }),
  });

  const handleSetPasswordOnSubmit = useMemo(
    () => (isForgot ? setForgottenPassword : setPassword),
    [isForgot, setForgottenPassword, setPassword]
  );

  const handleBackToHomePage = () => history.push('/');

  useEffect(() => {
    const uuid = queryString.parse(location?.search)?.uuid || '';
    if (isForgot) {
      confirmForgotPassword({ uuid });
      return;
    }
    confirmSetPassword({ uuid });
  }, [location, history, confirmForgotPassword, confirmSetPassword, isForgot]);

  return (
    <div className="h-full flex flex-col bg-center bg-primary-600 bg-login">
      <div className="absolute top-5 left-5 uppercase">
        <Button icon={BiHome} primary onClick={handleBackToHomePage}>
          Back to login page
        </Button>
      </div>
      <div className="w-full max-w-lg m-auto">
        <div className="flex justify-center mb-6">
          <img className="h-8" src={logoDark} alt="logo" />
        </div>
        <div className="m-auto w-full px-14 py-10 rounded-none sm:rounded-3xl bg-white">
          {isLoadingForgotConfirmation || isLoadingConfirmation ? (
            <>
              <h1 className="text-center text-lg mb-6">
                Confirming {isForgot ? 'Password Reset' : 'Session'}
              </h1>
              <div className="loaderBar w-full">
                <div className="loaderBarInner" />
              </div>
            </>
          ) : (
            <>
              {isValidUuid ? (
                <>
                  <h1 className="text-center text-2xl mb-6">
                    {isForgot ? 'Reset' : 'Set'} Password
                  </h1>
                  <NewPasswordForm
                    isForgot={isForgot}
                    isLoading={
                      isLoadingSetForgottenPassword || isLoadingSetPassword
                    }
                    onSubmit={(form) => {
                      handleSetPasswordOnSubmit(
                        { ...form, ...additionalPayload },
                        null
                        // (err) => setError422(err, setFieldError)
                      );
                    }}
                  />
                </>
              ) : (
                <div className="flex flex-col items-center">
                  <img
                    src={IconInvalidActivation}
                    alt="invalid"
                    className="w-36"
                  />
                  <h1 className="font-semibold text-lg text-gray-800 tracking-wide mt-5">
                    {isForgot ? 'Invalid Forgot' : 'Invalid Set'} Password Link
                  </h1>
                  <p className="mt-2 text-sm text-gray-600">
                    The link you followed is no longer valid. This is probably
                    the link expired.
                  </p>
                  {isForgot && (
                    <p className="mt-2 text-sm text-gray-600">
                      You can request a new forgot password link in the login
                      page.
                    </p>
                  )}
                  <Button
                    default
                    className="mt-10 mb-5 uppercase"
                    onClick={() => history.push('/')}
                  >
                    back to login page
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        <p className="text-white/60 text-xs text-center mt-6">
          &copy; 2024 eGovPay <Version />
        </p>
      </div>
    </div>
  );
}

export default SetPassword;
