import { createContext } from 'react';

export const AUTHENTICATION = 'AUTH/authentication';
export const LOGIN = 'AUTH/login';
export const LOGIN_SSO = 'AUTH/login_sso';
export const LOGOUT = 'AUTH/logout';
export const FORGOT_PASSWORD_SEND = 'AUTH/forgot_password_send_instruction';
export const FORGOT_PASSWORD_CONFIRM = 'AUTH/forgot_password_confirm';
export const FORGOT_PASSWORD_SET = 'AUTH/forgot_password_set_new';
export const USER_PASSWORD_CONFIRM = 'AUTH/user_password_confirm';
export const USER_PASSWORD_SET = 'AUTH/user_password_set';

export const MFA_ENABLE = 'MFA/enable_mfa';
export const MFA_DISABLE = 'MFA/disable_mfa';
export const MFA_VERIFY = 'MFA/verify_mfa';
export const MFA_QR = 'MFA/get_qr';

export const REGISTER = 'AUTH/register';
export const PROFILE = 'AUTH/profile';
export const CHANGE_PASSWORD = 'AUTH/change_password';
export const CREATE_ACCOUNT = 'AUTH/create_account';
export const CURRENT_ACCOUNT = 'AUTH/current_account';
export const CURRENT_ACCOUNT_BANK = 'AUTH/current_account_bank';
export const TOGGLE_MODE = 'ACCOUNT/toggle_mode';
export const CREATE_ONBOARDING = 'AUTH/create_onboarding';

export const MyAccountContext = createContext();
export const ProfileContext = createContext();

export const MAP_SWITCH_CODE = {
  TEST: 'LIVE',
  LIVE: 'TEST',
};
