import React from 'react';

function Privacy() {
  return (
    <div className="flex flex-col space-y-6 leading-relaxed text-lg text-gray-700 m-auto max-w-3xl px-5 sm:px-0">
      <div className="text-2xl text-center font-bold">
        EGOVPAY PRIVACY NOTICE
      </div>
      <p>
        eGovPay is a platform developed by COMPANY_NAME that is designed for
        entrepreneurs who need payment facilities for online selling services,
        inventory, and sales. It is an end-to-end solution for start-up
        businesses to large scale enterprises. The system includes Payment
        Facility, Product Management, Delivery Service Integration, Pricing,
        Content Management, Customer Support, Sales Forecasting Metrics,
        Marketing Tools, Procurement Management, and Inventory Tracking, all in
        one integrated web-application software.
      </p>
      <p>
        At COMPANY_NAME, we recognize the importance of data privacy and are
        committed to protecting your personal data. In line with this, we ensure
        that we comply with the Data Privacy Act of 2012 (RA 10173), along with
        its Implementing Rules and Regulations as well as any other relevant
        data privacy related laws and issuances by the National Privacy
        Commission. Furthermore, we only collect and process information that is
        necessary to fulfill the purpose of our platform. This Privacy Notice
        explains what information we collect, process, and share. It also
        informs you why we do so, and your data privacy rights with regard to
        your information
      </p>
      <div className="flex flex-col">
        <div className="text-xl font-bold">
          HOW YOUR PERSONAL DATA IS COLLECTED
        </div>
        <p>
          No information from subscribers will be collected until they have
          given their express consent. During the initial inquiry process,
          clicking the checkbox will signify that the user has read and agrees
          to the General Terms and Privacy Conditions.
        </p>
      </div>
      <div className="flex flex-col">
        <p>
          The information we collect for Registration Inquiry for Projects and
          Channels are as follow:
        </p>
        <ul className="list-disc ml-11">
          <li>First Name</li>
          <li>Last Name</li>
          <li>Email Address</li>
          <li>Mobile Phone Number</li>
          <li>Subject</li>
          <li>Message</li>
        </ul>
      </div>
      <p>
        This process starts when a project accesses the eGovPay website. The
        project will be able to contact the eGovPay support by filling up the
        contact us form. Once submitted, the support team will ask the project
        to submit the following requirements before the actual integration Said
        requirements will be collected off-system after the initial exploratory
        meeting:
      </p>
      <div className="flex flex-col">
        <div className="font-bold">Initial Documentary Requirements</div>
        <ul className="list-disc ml-11">
          <li>Company Profile</li>
          <li>Current collection channel</li>
          <li>Product Services</li>
          <li>Customer Base/No. of Customer Base</li>
          <li>Frequency of payments(Monthly Average)</li>
          <li>
            Transaction Amount (Monthly Average, Minimum and Maximum Amount per
            transaction)
          </li>
          <li>Web Service(Y/N)</li>
          <li>Payment Channels to be outsourced</li>
          <li>
            Technology Partnership Agreement &#38; Project Biller Contracts
            (Align with Finance &#38; Operations for SLA&#39;s of support and
            remittance)
          </li>
          <li>
            Commercial Proposal for service fee of each payment channel (OTC,
            Bank OTC, Online Banking, eWallets &#38; Card Payments)
          </li>
        </ul>
      </div>
      <div className="flex flex-col">
        <div className="font-bold">Due Diligence</div>
        <ul className="list-disc ml-11">
          <li>SEC</li>
          <li>Articles of Incorporation and By-Laws</li>
          <li>Mayor&#39;s Permit</li>
          <li>BIR COR 2303</li>
          <li>For signatory/ies</li>
          <li>
            Secretary&#39;s Certificate authorizing the signatory to sign the
            contract
          </li>
          <li>At least two (2) government-issued IDs of the signatory</li>
          <li>Contact information and reconciliation account</li>
          <li>
            Latest Audited Financial Statements or Annual Income Tax Return
          </li>
          <li>Latest General Information Sheet (GIS)</li>
        </ul>
      </div>
      <p>
        Furthermore, the following transaction information will be collected
        from projects and their end-customers should they users transact using
        eGovPay:
      </p>
      <div className="flex flex-col">
        <div className="font-bold">End-Customer Transaction Information</div>
        <ul className="list-disc ml-11">
          <li>Due Date</li>
          <li>
            Account Number (Mobile number for some ewallet, account number for
            banks)
          </li>
          <li>Mobile Number or Email Address (Optional for notifications)</li>
          <li>Amount</li>
        </ul>
      </div>
      <div className="flex flex-col">
        <div className="font-bold">
          Payment Transaction Information (Project)
        </div>
        <ul className="list-disc ml-11">
          <li>Due Date</li>
          <li>Transaction Reference Number</li>
          <li>
            Account Number (Mobile number for some ewallet, account number for
            banks)
          </li>
          <li>Mobile Number</li>
          <li>Email Address (Optional for notifications)</li>
        </ul>
      </div>
      <p>
        This process starts when the project’s end customers transact with the
        project application or website. If the customer needs to pay for the
        transaction, the project system will redirect the customer to the
        eGovPay payment gateway and forward payment transaction information. The
        customer will select the payment method and amount of payment. The
        eGovPay system will send the payment request information to the selected
        channel. If the channel is an over the counter channel, the customer
        needs to go to the nearest branch and accomplish the payment using the
        reference number provided by the channel. On the other hand, if the
        channel is an e-wallet, the channel will provide the payment url and the
        payment gateway will redirect the customer to it. The customer can
        provide the information required by the channel and accomplish payment
        right away. Once the channel receives the payment, the payment
        information will be forwarded to the eGovPay system and the same goes to
        the project system. The project system will display the payment
        information or continue the transaction if applicable.
      </p>
      <div className="flex flex-col">
        <div className="text-xl font-bold">HOW WE USE YOUR PERSONAL DATA</div>
        <div className="flex flex-col space-y-6">
          <p>
            We will only use your Personal Data if we have valid reasons for
            doing so. We may not process or use your information without your
            express consent.
          </p>
          <p>
            Data will be used for the purpose of registration inquiry, so that
            potential projects may contact us to express that they want to
            integrate eGovPay into their respective websites. Data will also be
            used to process transactions from projects and their end-customers
            through the respective payment channels.
          </p>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-xl font-bold">WHO WE SHARE YOUR DATA WITH</div>
        <p>
          The personal data you provide will only be shared with our partnered
          payment channels in order to process financial transactions and will
          not be shared or disclosed to any other third parties or advertisers.
          All information shall be kept by COMPANY_NAME and held with the utmost
          confidentiality.
        </p>
      </div>
      <div className="flex flex-col">
        <div className="text-xl font-bold">
          HOW LONG YOUR INFORMATION WILL BE KEPT
        </div>
        <div className="flex flex-col space-y-6">
          <p>
            Your data will be retained until 6 months of inactivity. Once your
            account has been inactive for more than 6 months, you will receive a
            notice that after 30 days, all of your information will be moved
            into the archives unless you wish to continue the usage of our
            system.
          </p>
          <p>
            Your archived data will be kept for a period of 1 year and if there
            will be no request from the data owner to restore them or reactivate
            the account, then your data will be completely deleted.
          </p>
          <p>
            We place great importance on the security of the Personal Data that
            we hold, including the use of physical, technological and
            organizational measures to ensure your information is protected from
            unauthorized access and against unlawful processing, accidental
            loss, alteration, disclosure, destruction and damage. Once the
            retention period is over or if the data is no longer needed, we will
            automatically delete your relevant personal information.
          </p>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-xl font-bold">YOUR RIGHTS AS A DATA SUBJECT</div>
        <p>
          As a project/end-customer of eGovPay, you are entitled to the
          following data privacy rights:
        </p>
        <ol className="list-decimal ml-11 my-6">
          <li>
            To be informed whether your Personal Information shall be, are
            being, or have been processed;
          </li>
          <li>
            To reasonable access any Personal Information collected and
            processed in duration of employment;
          </li>
          <li>
            To object or withhold consent with regards to the collection and
            processing of your Personal Data.
          </li>
          <li>
            To suspend, withdraw, or order the blocking, removal, or destruction
            of Personal Data from the relevant company’s filing system;
          </li>
          <li>
            To dispute the inaccuracy or error in Personal Data, and the
            relevant company shall correct it immediately and accordingly, upon
            the request unless the request is vexatious or otherwise
            unreasonable;
          </li>
          <li>
            To obtain a copy of the data in an electronic or structured format
            that is commonly used and allows for further use by the Data
            Subject;
          </li>
          <li>
            To be indemnified for any damages sustained pursuant to the
            provisions of the Data Privacy Act or Other Privacy Laws
          </li>
        </ol>
        <p>
          If you wish to exercise any of the rights set out above or require
          further information, you may contact us.
        </p>
      </div>
      <div className="flex flex-col">
        <div className="text-xl font-bold">CONTACT US</div>
        <p>
          COMPANY_NAME is fully committed to protecting and maintaining your
          privacy. For any questions, concerns or clarifications regarding this
          privacy policy and how to exercise your rights as a data subject, you
          may contact our Data Protection Officer at dpo@company_name.com or
          send a text at <a href="tel:XXXX XXX XXXX">XXXX XXX XXXX</a>. You may
          also get in touch with us at{' '}
          <a href="mailto:inquiry@company_name.com">inquiry@company_name.com</a>
          , or reach us at our main office at{' '}
          <a href="tel:(02) XXXX XXXX">(02) XXXX XXXX</a>.
        </p>
      </div>
    </div>
  );
}

export default Privacy;
