import { useCallback, useEffect } from 'react';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';
import { useMerchantSelected } from 'core-modules/merchants/hooks';
import * as actions from './actions';
import * as c from './constants';

export const useFormGeneralInfo = (cb) => {
  const [, data] = useMerchantSelected();
  const fetch = useCallback(() => {
    if (!data?.id) return;
    actions.promiseGetGeneralInfo(data.id).then(cb);
  }, [data, cb]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  return [fetch];
};

export const useGeneralInfo = () => {
  const isLoading = useApiLoading(c.GENERAL_INFO, 'get');
  const data = useApiGet(c.GENERAL_INFO, {});
  return [isLoading, data];
};

export const useSaveGeneralInfo = () => {
  const isLoading = useApiLoading(c.GENERAL_INFO, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveGeneralInfo(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useSaveBankDetails = () => {
  const isLoading = useApiLoading(c.BANK_DETAILS, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveMerchantBank(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useEReceipt = () => {
  const isLoading = useApiLoading(c.ERECEIPT, 'get');
  const data = useApiGet(c.ERECEIPT, {});
  return [isLoading, data];
};

export const useSaveEReceipt = () => {
  const isLoading = useApiLoading(c.ERECEIPT, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveEReceipt(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useFormBusinessCorporation = (cb) => {
  const [, data] = useMerchantSelected();
  const fetch = useCallback(() => {
    actions.promiseGetBusinessCorporation(data.id).then(cb);
  }, [data, cb]);
  useEffect(() => {
    fetch();
    return () => {
      req.set(c.BUSINESS_INFO, {});
    };
  }, [fetch]);
  return [fetch];
};

export const useBusinessCorporation = () => {
  const isLoading = useApiLoading(c.BUSINESS_INFO, 'get');
  const data = useApiGet(c.BUSINESS_INFO, {});
  return [isLoading, data];
};

export const useSaveBusinessCorporation = () => {
  const isLoading = useApiLoading(c.BUSINESS_INFO, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveBusinessCorporation(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useFormBusinessSole = (cb) => {
  const [, data] = useMerchantSelected();
  const fetch = useCallback(() => {
    actions.promiseGetBusinessSole(data.id).then(cb);
  }, [data, cb]);
  useEffect(() => {
    fetch();
    return () => {
      req.set(c.BUSINESS_INFO, {});
    };
  }, [fetch]);
  return [fetch];
};

export const useBusinessSole = () => {
  const isLoading = useApiLoading(c.BUSINESS_INFO, 'get');
  const data = useApiGet(c.BUSINESS_INFO, {});
  return [isLoading, data];
};

export const useSaveBusinessSole = () => {
  const isLoading = useApiLoading(c.BUSINESS_INFO, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveBusinessSole(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useFormBusinessIndividual = (cb) => {
  const [, data] = useMerchantSelected();
  const fetch = useCallback(() => {
    actions.promiseGetBusinessIndividual(data.id).then(cb);
  }, [data, cb]);
  useEffect(() => {
    fetch();
    return () => {
      req.set(c.BUSINESS_INFO, {});
    };
  }, [fetch]);
  return [fetch];
};

export const useBusinessIndividual = () => {
  const isLoading = useApiLoading(c.BUSINESS_INFO, 'get');
  const data = useApiGet(c.BUSINESS_INFO, {});
  return [isLoading, data];
};

export const useSaveBusinessIndividual = () => {
  const isLoading = useApiLoading(c.BUSINESS_INFO, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveBusinessIndividual(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useFormBusinessPartner = (cb) => {
  const [, data] = useMerchantSelected();
  const fetch = useCallback(() => {
    actions.promiseGetBusinessPartner(data.id).then(cb);
  }, [data, cb]);
  useEffect(() => {
    fetch();
    return () => {
      req.set(c.BUSINESS_INFO, {});
    };
  }, [fetch]);
  return [fetch];
};

export const useBusinessPartner = () => {
  const isLoading = useApiLoading(c.BUSINESS_INFO, 'get');
  const data = useApiGet(c.BUSINESS_INFO, {});
  return [isLoading, data];
};

export const useSaveBusinessPartner = () => {
  const isLoading = useApiLoading(c.BUSINESS_INFO, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveBusinessPartner(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useFormAttachments = (cb) => {
  const [, data] = useMerchantSelected();
  const fetch = useCallback(() => {
    if (!data?.id) return;
    actions.promiseGetAttachments(data.id).then(cb);
  }, [data, cb]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  return [fetch];
};

export const useAttachments = () => {
  const isLoading = useApiLoading(c.ATTACHMENTS, 'get');
  const data = useApiGet(c.ATTACHMENTS, {});
  return [isLoading, data];
};

export const useSaveAttachments = () => {
  const isLoading = useApiLoading(c.ATTACHMENTS, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveAttachments(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useSaveRequirements = () => {
  const isLoading = useApiLoading(c.ATTACHMENTS, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.saveRequirements(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};

export const useSubmitApplication = () => {
  const isLoading = useApiLoading(c.APPLICATION, 'put');
  const [, data] = useMerchantSelected();
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.submitApplication(data.id, payload, onSuccess, onError);
    },
    [data]
  );
  return [isLoading, submit];
};
