import React from 'react';
import PropTypes from 'prop-types';

import { useMakeInputOnlyAcceptRegexPassed } from 'partial/hooks';
import { zipCode } from 'partial/regex';

import { ErrorLabel } from './Form';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormInputNumber from './FormInputNumber';

import countries from './countries.json';
import provinces from './provinces.json';
import cities from './cities.json';

const countryOptions = countries.map((item) => ({
  label: item.name,
  value: item.iso2,
}));

const provinceOptions = provinces.map((item) => ({
  label: item.name,
  value: item.id,
}));

const transformCity = (arr) =>
  arr.map((item) => ({
    label: item.name,
    value: item.id,
    zip_code: item.zip_code,
  }));

const parser = (raw, defaultValue = {}) => {
  try {
    const newObj = { ...raw };
    if (newObj.business_country === 'PH') {
      const state =
        provinces.find((x) => `${x.name}` === `${newObj.business_state}`) || {};
      newObj.business_state = state.id || '';
      const city =
        (cities[state.id] || []).find(
          (x) => `${x.name}` === `${newObj.business_city}`
        ) || {};
      newObj.business_city = city.id || '';
    }
    return newObj;
  } catch (err) {
    return defaultValue;
  }
};

function FormAddress({ onSetFieldValue, value, required, readOnly, disabled }) {
  const form = React.useMemo(() => parser(value), [value]);
  const handleChange = (k, v) => {
    onSetFieldValue(k, v);
  };

  const { registerOnSetFieldValue } = useMakeInputOnlyAcceptRegexPassed(
    zipCode
  );

  const handleChangeCountry = (k, v) => {
    onSetFieldValue(k, v);
    onSetFieldValue('business_state', '');
    onSetFieldValue('business_city', '');
    onSetFieldValue('business_zip_code', '');
  };
  const handleChangeState = (k, v) => {
    onSetFieldValue(
      k,
      form?.business_country === 'PH'
        ? (provinces.find((x) => `${x.id}` === `${v}`) || {}).name || ''
        : v
    );
    onSetFieldValue('business_city', '');
    onSetFieldValue('business_zip_code', '');
  };
  const handleChangeCity = (k, v) => {
    const selected =
      (cities[form?.business_state] || []).find((x) => `${x.id}` === `${v}`) ||
      {};
    onSetFieldValue(k, selected.name || '');
    onSetFieldValue('business_zip_code', selected?.zip_code || '');
  };

  const cityOptions = React.useMemo(
    () => transformCity(cities[form?.business_state] || []),
    [form?.business_state]
  );

  return (
    <>
      <div className="grid grid-cols-6 gap-3">
        <div
          className={
            form?.business_country === 'PH' ? 'col-span-3' : 'col-span-6'
          }
        >
          <FormSelect
            name="business_country"
            onSetFieldValue={handleChangeCountry}
            value={form?.business_country}
            label="Country"
            options={countryOptions}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
          />
          <ErrorLabel name="business_country" />
        </div>
        {form?.business_country === 'PH' ? (
          <>
            <div className="col-span-3">
              <FormSelect
                name="business_state"
                onSetFieldValue={handleChangeState}
                value={form?.business_state}
                label="Province"
                options={provinceOptions}
                required={required}
                disabled={disabled}
                readOnly={readOnly}
              />
              <ErrorLabel name="business_state" />
            </div>
            <div className="col-span-4">
              <FormSelect
                name="business_city"
                onSetFieldValue={handleChangeCity}
                value={form?.business_city}
                label="City"
                options={cityOptions}
                required={required}
                disabled={disabled}
                readOnly={readOnly}
              />
              <ErrorLabel name="business_city" />
            </div>
            <div className="col-span-2">
              <FormInputNumber
                name="business_zip_code"
                onSetFieldValue={registerOnSetFieldValue(
                  'business_zip_code',
                  handleChange
                )}
                value={form?.business_zip_code}
                label="Zip Code"
                required={required}
                disabled={disabled}
                readOnly={readOnly}
              />
              <ErrorLabel name="business_zip_code" />
            </div>
            <div className="col-span-6">
              <FormInput
                name="business_address"
                onSetFieldValue={handleChange}
                value={form?.business_address}
                label="Street/Home/Building No./Barangay"
                required={required}
                disabled={disabled}
                readOnly={readOnly}
              />
              <ErrorLabel name="business_address" />
            </div>
          </>
        ) : (
          <div className="col-span-6">
            <FormInput
              name="business_address"
              onSetFieldValue={handleChange}
              value={form?.business_address}
              label="Street/Home/Building No, City, State/Province, Country Zip Code"
              required={required}
              disabled={disabled}
              readOnly={readOnly}
            />
            <ErrorLabel name="business_address" />
          </div>
        )}
        {/* ) : (
          <>
            <div className="col-span-3">
              <FormInput
                name="business_state"
                onSetFieldValue={handleChange}
                value={form?.business_state}
                label="State/Province"
                required={required}
              />
              <ErrorLabel name="business_state" />
            </div>
            <div className="col-span-4">
              <FormInput
                name="business_city"
                onSetFieldValue={handleChange}
                value={form?.business_city}
                label="City"
                required={required}
              />
              <ErrorLabel name="business_city" />
            </div>
          </>
        )} */}
      </div>
    </>
  );
}

FormAddress.defaultProps = {
  required: false,
  readOnly: false,
  disabled: false,
};

FormAddress.propTypes = {
  required: PropTypes.bool,
  onSetFieldValue: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FormAddress;
