import { pick } from 'lodash';
import AuthStore from 'auth-store';
import history from 'setup/history';
import { req } from 'react-reqq-lite';
import queryString from 'query-string';
import { persistStorage, storage } from 'helper';

import { closeModal, showAlert } from 'partial/components/Modal';

import * as c from './constants';
import * as t from './transformers';

export const register = (payload, onSuccess, onError) => {
  req.post({
    key: c.REGISTER,
    url: '/auth/v1/register/initiate',
    payload,
    onSuccess,
    onError,
  });
};

export const login = (payload, onSuccess, onError) => {
  req.post({
    key: c.LOGIN,
    url: '/auth/v1/login',
    payload,
    onSuccess: (res) => {
      const isMFAEnabled = !!res?.response?.data?.is_mfa_enabled;
      if (isMFAEnabled) {
        const email = res?.response?.data?.email_address;
        const environment_type = res?.response?.data?.environment_type;
        AuthStore.set('_email', email);
        AuthStore.set('_env_type', environment_type);
        if (typeof onSuccess === 'function') onSuccess(res);
        return;
      }
      const token = res?.response?.token;
      AuthStore.set('_token', token);
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
      });
      if (typeof onSuccess === 'function') onSuccess(res);
      history.push('/dashboard');
    },
    onError,
  });
};

export const logout = () => {
  const onErrorSuccess = () => {
    AuthStore.remove('_token');
    AuthStore.remove('_profile');
    AuthStore.remove('_email');
    AuthStore.remove('_env_type');
    AuthStore.remove('LIVE_session');
    AuthStore.remove('TEST_session');
    AuthStore.remove('_skip_onboarding');
    const activeAccount = persistStorage.get('selected-account');
    if (activeAccount?.id) storage.set('prev-auth-id', activeAccount?.id);
    persistStorage.remove('selected-account');
    storage.remove('onboarding-step');
    closeModal();
    req.reset();
    req.set(c.AUTHENTICATION, {
      isAuthenticated: false,
    });
  };
  req.remove({
    key: c.LOGOUT,
    url: '/auth/v1/logout',
    onSuccess: onErrorSuccess,
    onError: onErrorSuccess,
  });
};

export const getProfile = (setter) => {
  req.get({
    key: c.PROFILE,
    url: '/auth/v1/my/account',
    transform: (res) => t.transformProfile(res?.data),
    onSuccess: (res) => {
      const profile = t.transformProfile(res?.response?.data);
      setter(profile);
    },
  });
};

export const changePassword = (payload, onSuccess, onError) => {
  req.put({
    key: c.CHANGE_PASSWORD,
    url: '/auth/v1/my/account/update_password',
    payload,
    onSuccess,
    onError,
  });
};

export const switchProfile = (payload, onSuccess, onError) => {
  req.post({
    key: c.PROFILE,
    url: '/auth/v1/my/account/switch_environment',
    payload,
    onSuccess: async (res) => {
      if (typeof onSuccess === 'function') await onSuccess(res);
      const token = res?.response?.token;
      const prevToken = AuthStore.get('_token');
      const prevCode = `${
        c.MAP_SWITCH_CODE[payload?.environment_type]
      }_session`;
      const nextCode = `${payload?.environment_type}_session`;
      AuthStore.set(`${prevCode}`, prevToken);
      AuthStore.set(`${nextCode}`, token);
      AuthStore.set('_token', token);
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
      });
      // if (+res?.response?.user?.is_first_time_login === 1) {
      //   openChangePassword(
      //     { old_password: payload.password },
      //     () => {
      //       window.location.reload();
      //     },
      //     () => {
      //       AuthStore.set('_token', prevToken);
      //       window.location.reload();
      //     }
      //   );
      //   return;
      // }
      window.location.reload();
    },
    onError,
  });
};

export const getCurrentAccount = () => {
  req.get({
    key: c.CURRENT_ACCOUNT,
    url: '/organization/v1/my/organization/current',
    transform: (res) => t.transformAccount(res?.data),
  });
};

export const createAccount = (payload, onSuccess, onError) => {
  req.post({
    key: c.CREATE_ACCOUNT,
    url: '/profile/v1/my/merchants',
    payload,
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') {
        window.location.href = '/';
        persistStorage.set(
          'selected-account',
          t.transformAccount(res?.response?.data)
        );
        onSuccess(res);
      }
    },
    onError,
  });
};

export const updateAccount = (payload, onSuccess, onError) => {
  req.put({
    key: c.CURRENT_ACCOUNT,
    url: '/organization/v1/my/organization/current',
    payload: t.payloadAccount(payload),
    onSuccess: (res) => {
      getCurrentAccount();
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError,
  });
};

export const updateOrgAccountBank = (payload, onSuccess, onError) => {
  req.put({
    key: c.CURRENT_ACCOUNT_BANK,
    url: '/organization/v1/my/organization/current/bank',
    payload: t.payloadAccountBank(payload),
    onSuccess: (res) => {
      getCurrentAccount();
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError,
  });
};

export const forgotPasswordSendInstruction = (onSuccess, onError) => (
  payload,
  onInlineSuccess,
  onInlineError
) => {
  req.post({
    key: c.FORGOT_PASSWORD_SEND,
    url: '/auth/v1/password_reset/initiate',
    payload,
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
      if (typeof onInlineSuccess === 'function') onInlineSuccess(res);
    },
    onError: (err) => {
      if (typeof onError === 'function') onError(err);
      if (typeof onInlineError === 'function') onInlineError(err);
    },
  });
};

export const forgotPasswordConfirm = (onSuccess, onError) => (
  payload,
  onInlineSuccess,
  onInlineError
) => {
  req.put({
    key: c.FORGOT_PASSWORD_CONFIRM,
    url: '/auth/v1/password_reset/confirm',
    payload,
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
      if (typeof onInlineSuccess === 'function') onInlineSuccess(res);
    },
    onError: (err) => {
      if (typeof onError === 'function') onError(err);
      if (typeof onInlineError === 'function') onInlineError(err);
    },
  });
};

export const forgotPasswordSetNewPassword = (onSuccess, onError) => (
  payload,
  onInlineSuccess,
  onInlineError
) => {
  req.put({
    key: c.FORGOT_PASSWORD_SET,
    url: '/auth/v1/password_reset/update_password',
    payload,
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
      if (typeof onInlineSuccess === 'function') onInlineSuccess(res);
    },
    onError: (err) => {
      if (typeof onError === 'function') onError(err);
      if (typeof onInlineError === 'function') onInlineError(err);
    },
  });
};

// Sub User Stuffs Start
export const setPasswordConfirm = (onSuccess, onError) => (
  payload,
  onInlineSuccess,
  onInlineError
) => {
  req.put({
    key: c.USER_PASSWORD_CONFIRM,
    url: '/user_management/v1/sub_users/set_password/confirm',
    payload,
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
      if (typeof onInlineSuccess === 'function') onInlineSuccess(res);
    },
    onError: (err) => {
      if (typeof onError === 'function') onError(err);
      if (typeof onInlineError === 'function') onInlineError(err);
    },
  });
};

export const setPassword = (onSuccess, onError) => (
  payload,
  onInlineSuccess,
  onInlineError
) => {
  req.put({
    key: c.USER_PASSWORD_SET,
    url: '/user_management/v1/sub_users/set_password',
    payload,
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
      if (typeof onInlineSuccess === 'function') onInlineSuccess(res);
    },
    onError: (err) => {
      if (typeof onError === 'function') onError(err);
      if (typeof onInlineError === 'function') onInlineError(err);
    },
  });
};
// Sub User Stuffs End

export const createOnboarding = (payload, onSuccess, onError) => {
  req.put({
    key: c.CREATE_ONBOARDING,
    payload,
    url: '/organization/v1/my/organization/kyc',
    onSuccess: () => {
      getCurrentAccount();
      if (typeof onSuccess === 'function') onSuccess();
    },
    onError: (err) => {
      if (typeof onError === 'function') onError(err);
    },
  });
};

export const updateMeta = (payload, onSuccess) => {
  req.put({
    key: 'ACCOUNT/meta',
    url: '/organization/v1/my/organization/current/meta',
    payload,
    onSuccess: (res, state) => {
      if (onSuccess) onSuccess();
      req.set(c.CURRENT_ACCOUNT, {
        ...state[c.CURRENT_ACCOUNT],
        meta: payload?.meta_data,
      });
      // getCurrentAccount();
    },
  });
};

export const switchAccount = (token) => {
  const WITH_ALERT = false;
  if (!WITH_ALERT) {
    AuthStore.set('_token', token);
    req.set(c.AUTHENTICATION, {
      isAuthenticated: true,
    });
    window.location.reload();
    return;
  }
  showAlert({
    title: 'Are you sure you want to switch mode?',
    yesLabel: 'Switch',
    onYes: (closeAlert) => {
      AuthStore.set('_token', token);
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
      });
      window.location.reload();
      closeAlert();
    },
  });
};

export const enableMFA = (onSuccess, onError) => {
  req.post({
    key: c.MFA_ENABLE,
    url: '/auth/v1/mfa/enable',
    onSuccess: (res) => {
      if (typeof onSuccess === 'function') onSuccess(res);
    },
    onError: (res) => {
      if (typeof onError === 'function') onError(res);
    },
  });
};

export const disableMFA = (onSuccess, onError) => (payload) => {
  req.post({
    key: c.MFA_DISABLE,
    url: '/auth/v1/mfa/disable',
    payload,
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    onError: () => {
      if (typeof onError === 'function') onError();
    },
  });
};

export const verifyMFA = (onSuccess, onError) => (payload) => {
  req.post({
    key: c.MFA_VERIFY,
    url: '/auth/v1/mfa/verify',
    payload,
    onSuccess: (res) => {
      const token = res?.response?.data?.token;
      AuthStore.set('_token', token);
      if (typeof onSuccess === 'function') onSuccess(res);
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
      });

      // RECEIVE REDIRECT FROM EMAIL
      const redirectTo = queryString.parse(window?.location?.search)?.redirect;
      const [pathname, search] = (redirectTo || '').split('?');
      const query = queryString.parse(`?${search || ''}`);
      if (pathname && query?.id) {
        history.push({ pathname, state: { selected: pick(query, ['id']) } });
        return;
      }

      history.push('/dashboard');
    },
    onError: () => {
      if (typeof onError === 'function') onError();
    },
  });
};

export const getQr = () => {
  req.get({
    key: c.MFA_QR,
    url: '/auth/v1/mfa',
  });
};
