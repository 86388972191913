import React, { lazy, Suspense } from 'react';
import { useAuth } from 'modules/auth/hooks';
import Splash from 'partial/containers/Splash';
import { Route, Switch } from 'react-router-dom';
import { ModalProvider } from 'partial/components/Modal';
import OnboardingContainer from 'core-modules/onboarding';
import ErrorBoundary from 'partial/containers/ErrorBoundary';
// import DevelopersContainer from 'documentation/DevelopersContainer';
import EmailActivationRoutes from 'modules/auth/containers/EmailActivationRoutes';
// import PgiPreviewContainer from 'core-modules/pgi-themes/containers/PgiPreviewContainer';
// import Private from './Private';
import Public from './Public';

const Private = lazy(() => import('./Private'));
const PgiPreviewContainer = lazy(() =>
  import('./core-modules/pgi-themes/ containers/PgiPreviewContainer')
);
const DevelopersContainer = lazy(() =>
  import('./documentation/DevelopersContainer')
);

export const PURGE_IGNORE = () => <input type="tel" />;

const PrivateRoutes = React.memo(() => {
  return (
    <Switch>
      <Route path="/onboarding" component={OnboardingContainer} />
      <Route component={Private} />
    </Switch>
  );
});

function App() {
  const [isAppReady, isAuthenticated] = useAuth();
  if (!isAppReady) return null;
  return (
    <ErrorBoundary>
      <Suspense fallback={<Splash />}>
        <ModalProvider>
          <Switch>
            <Route path="/developers" component={DevelopersContainer} />
            <Route path="/pgi-preview" component={PgiPreviewContainer} />
            {/* <Route path="/p/print-sample/:doc_id" component={PrintContainer} /> */}
            <Route path="/email-activation" component={EmailActivationRoutes} />
            <Route>{isAuthenticated ? <PrivateRoutes /> : <Public />}</Route>
          </Switch>
        </ModalProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
