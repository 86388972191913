import React from 'react';
import { E_RECEIPT_ROOT } from '../constants';

function EreceiptInstruction() {
  return (
    <div className="space-y-2 mb-4 pb-4 border-b">
      <h1 className="title-h1">Add your eReceipt API Credential</h1>
      <p className="text-gray-500 text-base">
        eReceipt is a digital platform that enables businesses to issue, manage
        and store electronic receipts for transactions made with customers.
      </p>
      {/* <div className="text-gray-500 text-base">
        Don&apos;t have an eReceipt account yet?{' '}
        <a
          className="hover:underline"
          href={E_RECEIPT_ROOT}
          target="_blank"
          rel="noreferrer"
        >
          Sign up here
        </a>
      </div> */}
    </div>
  );
}

export function EreceiptInstruction2() {
  return (
    <div className="text-gray-500 text-base mt-8">
      Get your Code and Token at{' '}
      <a
        className="hover:underline"
        href={`${new URL('/developers', E_RECEIPT_ROOT).href}`}
        target="_blank"
        rel="noreferrer"
      >
        {new URL('/developers', E_RECEIPT_ROOT).href}
      </a>
    </div>
  );
}

export default EreceiptInstruction;
