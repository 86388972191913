import React from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { each } from 'lodash';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { BiInfoCircle } from 'react-icons/bi';
import { HiCheckCircle } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import FormDate from 'partial/form/FormDate';
import FormPhoto from 'partial/form/FormPhoto';
import FormInput from 'partial/form/FormInput';
import Button from 'partial/components/Button';
import ToolTip from 'partial/components/ToolTip';
import FormPhotoDnd from 'partial/form/FormPhotoDnd';
import Form, { ErrorLabel } from 'partial/form/Form';
import { useCreateModal } from 'partial/components/Modal';
import {
  useCreateOnboarding,
  useActiveAccount,
  useAccountMeta,
} from 'modules/auth/hooks';
import FormSelect from 'partial/form/FormSelect';
import ValidIDs from 'core-modules/merchant-onboarding/components/ValidIDs';
import { useMakeInputNotAcceptSpecialCharacters } from 'partial/hooks';
import FormDatasetOptions from 'core-modules/dataset/components/FormDatasetOptions';

import { SUFFIXES } from '../constants';

const validationSchema = yup.object().shape({
  owner_first_name: yup.string().required('Required'),
  owner_last_name: yup.string().required('Required'),
  owner_valid_id_type: yup.string().required('Required'),
  owner_valid_id_url: yup.string().required('Required'),
  owner_birth_date: yup
    .string()
    .required('Required')
    .test({
      name: 'date must be in correct format',
      test: (value) => {
        return dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value;
      },
      message: 'Invalid date format',
    })
    .test({
      name: 'date must be in the past',
      test: (value) => {
        return dayjs(value).isBefore(Date.now());
      },
      message: "Birthdate can't be in future",
    }),
  owner_photo_url: yup.string().required('Required'),
});
function ProfilePage({ onNext, readOnly }) {
  const history = useHistory();
  const { meta, update } = useAccountMeta();
  const [activeAccount] = useActiveAccount();
  const [isSubmitting, submit] = useCreateOnboarding();

  const [validateOnChange, setValidateOnchange] = React.useState(false);
  const { registerOnSetFieldValue } = useMakeInputNotAcceptSpecialCharacters();

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues: activeAccount,
    validationSchema,
    onSubmit: async (form, { setFieldError }) => {
      submit(
        form,
        (v) => {
          onNext(v);
          if (!meta?.is_org_profile_submitted)
            update({ is_org_profile_submitted: true });
        },
        (err) => {
          each(err?.response?.errors, (v, k) => {
            setFieldError(k, v);
          });
        }
      );
    },
    validateOnChange,
  });

  // const handleSkipOnboarding = () => {
  //   history.push('/dashboard');
  //   AuthStore.set('_skip_onboarding', true);
  // };
  const modal = useCreateModal();
  const handleOpenValidIDModal = () => {
    modal({
      content: (onClose) => <ValidIDs onClose={onClose} />,
    });
  };

  return (
    <Form onSubmit={handleSubmit} error={errors} noValidate>
      <div className="space-y-4">
        <div className="mb-3 mx-auto">
          <FormPhoto
            className="h-32 w-32 rounded-lg"
            imgClassName="object-cover object-center rounded-full"
            name="owner_photo_url"
            onSetFieldValue={setFieldValue}
            value={values?.owner_photo_url}
            label="Profile Photo (1x1 photo)"
            crop="1:1, free"
            resize="256x"
            widget
            readOnly={readOnly}
          />
          <div className="mt-2 w-32 mx-auto">
            <ErrorLabel name="owner_photo_url" />
          </div>
        </div>
        <div className="w-full">
          <div className="title-h2 mb-4">Personal Information</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <div className="mb-3">
              <FormInput
                name="owner_first_name"
                onSetFieldValue={registerOnSetFieldValue(
                  'owner_first_name',
                  setFieldValue
                )}
                value={values?.owner_first_name || ''}
                label="First Name"
                required
                readOnly={readOnly}
              />
              <ErrorLabel name="owner_first_name" />
            </div>
            <div className="mb-3">
              <FormInput
                name="owner_middle_name"
                onSetFieldValue={registerOnSetFieldValue(
                  'owner_middle_name',
                  setFieldValue
                )}
                value={values?.owner_middle_name || ''}
                label="Middle Name"
                readOnly={readOnly}
              />
              <ErrorLabel name="owner_middle_name" />
            </div>
            <div className="mb-3">
              <FormInput
                name="owner_last_name"
                onSetFieldValue={registerOnSetFieldValue(
                  'owner_last_name',
                  setFieldValue
                )}
                value={values?.owner_last_name || ''}
                label="Last Name"
                required
                readOnly={readOnly}
              />
              <ErrorLabel name="owner_last_name" />
            </div>
            <div className="mb-3">
              <FormSelect
                name="owner_suffix"
                onSetFieldValue={setFieldValue}
                value={values?.owner_suffix || ''}
                options={SUFFIXES.map((suffix) => ({
                  label: suffix,
                  value: suffix,
                }))}
                label="Suffix"
                readOnly={readOnly}
              />
              <ErrorLabel name="owner_Suffix" />
            </div>
          </div>
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-4">
            <div className="mb-3">
              <FormDate
                name="owner_birth_date"
                onSetFieldValue={setFieldValue}
                value={values?.owner_birth_date || ''}
                label="Birth Date"
                required
                maxDate={Date.now()}
                readOnly={readOnly}
              />
              <ErrorLabel name="owner_birth_date" />
            </div>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
          <div className="col-span-2">
            <div className="flex items-center justify-between space-x-2">
              <div className="title-h2">Upload ID</div>
              <button
                type="button"
                className="text-primary-500"
                onClick={handleOpenValidIDModal}
              >
                See all valid ID
              </button>
            </div>
          </div>
          <div className="col-span-2">
            <FormDatasetOptions
              code="VALID_ID"
              name="owner_valid_id_type"
              onSetFieldValue={setFieldValue}
              value={values.owner_valid_id_type}
              error={errors?.owner_valid_id_type}
              label="ID Type"
              placeholder="- Select ID Type -"
              required
              readOnly={readOnly}
            />
            <ErrorLabel name="owner_valid_id_type" />
          </div>
          <div className="col-span-2 sm:col-span-1">
            <div>
              <div className="text-gray-500 font-semibold flex items-center justify-between w-full pb-4">
                <span>Guidelines for uploading ID</span>
              </div>
              <div className="pb-4 space-y-2">
                <div className="flex space-x-2">
                  <HiCheckCircle className="w-4 h-4 flex-shrink-0 text-green-500 mt-0.5" />
                  <p className="text-gray-500">Do not submit expired ID.</p>
                </div>
                <div className="flex space-x-2">
                  <HiCheckCircle className="w-4 h-4 flex-shrink-0 text-green-500 mt-0.5" />
                  <p className="text-gray-500">
                    Make sure all information is legible. Do not censor, black
                    out or redact any data.
                  </p>
                </div>
                <div className="flex space-x-2">
                  <HiCheckCircle className="w-4 h-4 flex-shrink-0 text-green-500 mt-0.5" />
                  <p className="text-gray-500">
                    Do not crop the ID. All corners of the ID should be visible
                    against the backdrop.
                  </p>
                </div>
                <div className="flex space-x-2">
                  <HiCheckCircle className="w-4 h-4 flex-shrink-0 text-green-500 mt-0.5" />
                  <p className="text-gray-500">
                    Upload one (1) Primary ID or three (3) Secondary ID (only if
                    you cannot provide a primary ID).
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 sm:col-span-1">
            <FormPhotoDnd
              className="h-[220px]"
              name="owner_valid_id_url"
              onSetFieldValue={setFieldValue}
              value={values?.owner_valid_id_url}
              label="ID Photo"
              required
              disabled={readOnly}
            />
            <ErrorLabel name="owner_valid_id_url" />
          </div>
        </div>
      </div>
      <div className="flex space-x-0 sm:space-x-3 space-y-3 sm:space-y-0 flex-col sm:flex-row justify-end mt-4">
        {!readOnly ? (
          <>
            <div className="mt-3 sm:mt-0 order-last sm:order-first">
              <Button
                size="lg"
                transparent
                className="w-full sm:w-auto"
                onClick={onNext}
              >
                <ToolTip
                  className="text-left font-normal"
                  tip="You can still skip this and continue to test your account."
                >
                  <div className="flex relative">
                    <div className="flex items-center border-dashed border-b-0 hover:border-gray-300 hover:border-b-1 space-x-2 cursor-pointer">
                      <div className="whitespace-nowrap">
                        Skip, I&apos;ll do this later.
                      </div>
                      <BiInfoCircle className="sm:block hidden" />
                    </div>
                  </div>
                </ToolTip>
              </Button>
              <p className="text-sm text-gray-500 mt-1 text-center sm:hidden flex space-x-1 items-center">
                <BiInfoCircle className="w-4 h-4 flex-shrink-0" />
                <span>
                  You can still skip this and continue to test your account.
                </span>
              </p>
            </div>
            <Button
              primary
              size="lg"
              type="submit"
              disabled={isSubmitting}
              onClick={() => setValidateOnchange(true)}
              className="whitespace-nowrap order-2 sm:order-1"
            >
              Save and Continue
            </Button>
          </>
        ) : (
          <Button
            primary
            size="lg"
            type="button"
            onClick={() => history.push('/dashboard')}
            className="whitespace-nowrap order-2 sm:order-1"
          >
            Continue
          </Button>
        )}
      </div>
    </Form>
  );
}

ProfilePage.defaultProps = {
  readOnly: false,
};

ProfilePage.propTypes = {
  onNext: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default ProfilePage;
