import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { CgSpinner } from 'react-icons/cg';
// import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { useInputId } from './hooks';

function FormSelect({
  id: defaultId,
  icon: Icon,
  label,
  name,
  onChange,
  onSetFieldValue,
  value,
  error,
  required,
  options,
  placeholder,
  isLoading,
  onRetry,
  disabled,
  readOnly,
  ...props
}) {
  const [id] = useInputId(defaultId);
  const handleChange = ({ target }) => {
    if (onRetry && target.value === '__RETRY__') {
      onRetry();
      return;
    }
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: target.value,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, target.value);
    }
  };
  // const showIcon = Icon || error;
  const isError = !!error;
  return (
    <div
      className={cn(
        'relative border rounded-md bg-white px-3 py-2 focus-within:ring-1 z-[1]',
        isError
          ? 'border-red-300 focus-within:ring-red-500 focus-within:border-red-500 animate-wiggle'
          : 'border-gray-200 focus-within:ring-primary-600 focus-within:border-primary-600',
        disabled || readOnly ? 'bg-gray-100' : 'bg-white'
      )}
    >
      {label ? (
        <label
          htmlFor={id}
          className="w-full text-xs font-medium text-primary-500 flex justify-between"
        >
          <span>{label}</span>
          {!required && (
            <span className="tracking-wider px-2 text-[8px] py-0.5 text-gray-500 rounded-full bg-gray-100 uppercase ml-1">
              optional
            </span>
          )}
        </label>
      ) : null}
      <select
        autoComplete="none"
        className={cn(
          'w-full border-none form-select py-0 pl-0',
          'focus:outline-none focus:ring-0 bg-transparent disabled:bg-gray-100',
          'pr-10', // with icon
          readOnly ? 'bg-gray-100' : 'disabled:text-gray-500'
        )}
        style={isLoading ? { backgroundImage: 'none' } : {}}
        onChange={handleChange}
        value={value}
        disabled={disabled || readOnly}
        readOnly={readOnly}
        {...props}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((x) => (
          <option value={x.value} key={x.value}>
            {x.label}
          </option>
        ))}
      </select>
      {isLoading && (
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          title={error || ''}
        >
          {isLoading ? (
            <CgSpinner
              className="h-5 w-5 text-gray-400 animate-spin"
              aria-hidden="true"
            />
          ) : (
            <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
          {/* {error ? (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          ) : (
            <></>
          )} */}
        </div>
      )}
    </div>
  );
}

FormSelect.defaultProps = {
  id: '',
  label: '',
  icon: false,
  type: 'text',
  error: null,
  required: false,
  options: [],
  placeholder: '- Select -',
  onChange: false,
  onSetFieldValue: false,
  isLoading: false,
  onRetry: null,
  disabled: false,
  readOnly: false,
};

FormSelect.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  options: PropTypes.instanceOf(Array),
  isLoading: PropTypes.bool,
  onRetry: () => {},
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default React.memo(FormSelect);
