import { formatNumber } from 'helper';

export const transformMerchantOptions = (raw) => ({
  value: raw?.uuid,
  label: raw?.name,
  photo_url: raw?.logo,
});

const MAP_APPROVAL_STATUS = {
  APPROVED: 'Approved',
  IN_REVIEW: 'In Review',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
};

export const transformMerchant = (raw) => ({
  id: raw?.uuid,
  name: raw?.name || 'Untitled',
  logo: raw?.logo,
  env_mode: raw?.status,
  is_org_merchant: raw?.is_org_merchant === 1,

  approval_status: raw?.approval_status, // TODO: REVERT  'PENDING',
  approval_status_label: MAP_APPROVAL_STATUS[raw?.approval_status], // TODO: REVERT MAP_APPROVAL_STATUS.PENDING,
  approval_remarks: raw?.approval_status_remarks,

  theme: {
    theme: 'theme5', // raw?.theme_code || '', // FIXED THEME CODE
    ...(raw?.theme_meta || {}),
  },
});

export const transformMerchantSettlement = (raw) => ({
  id: raw?.id || '',
  name: raw?.name || '',
});

export const transformMerchantApiKey = (raw) => ({
  id: raw?.id || '',
  name: raw?.name || '',
});

export const transformGeneralInfo = (raw) => ({
  id: raw?.uuid || '',
  logo: raw?.logo || '',
  name: raw?.name || '',
  website_url: raw?.website_url || null,
  industry: raw?.industry || '',
  customer_service_number: raw?.customer_service_number || '',
  business_country: raw?.business_country || '',
  business_state: raw?.business_state || '',
  business_city: raw?.business_city || '',
  business_address: raw?.business_address || '',
  business_zip_code: raw?.business_zip_code || '',
  business_geoloc: raw?.business_geoloc || null,
  business_description: raw?.business_description || '',
  business_number_of_customers: raw?.business_number_of_customers || '',
  business_monthly_sales: raw?.business_monthly_sales || '',
  business_type: raw?.business_type || '',

  contact_first_name: raw?.contact_first_name || '',
  contact_last_name: raw?.contact_last_name || '',
  contact_designation: raw?.contact_designation || '',
  contact_mobile_number: raw?.contact_mobile_number || '',
  contact_email_address: raw?.contact_email_address || '',
  requirements: raw?.requirements || [],
});

export const transformBusinessCorporation = (raw) => ({
  first_name: raw?.first_name || '',
  last_name: raw?.last_name || '',
  home_address: raw?.home_address || '',
  home_geoloc: raw?.home_geoloc || null,
  is_beneficial_owner: raw?.is_beneficial_owner ?? false,
  owner_id_image_url: raw?.owner_id_image_url || null,
  business_legal_name: raw?.business_legal_name || '',
  business_registration_number: raw?.business_registration_number || '',
  business_incorporation_date: raw?.business_incorporation_date || '',
  sec_cert_image_url: raw?.sec_cert_image_url || null,
  by_laws_image_url: raw?.by_laws_image_url || null,
  art_of_incor_image_url: raw?.art_of_incor_image_url || null,
  bank_name: raw?.bank_name || '',
  bank_account_name: raw?.bank_account_name || '',
  bank_account_number: raw?.bank_account_number || '',
});

export const transformBusinessSole = (raw) => ({
  first_name: raw?.first_name || '',
  last_name: raw?.last_name || '',
  home_address: raw?.home_address || '',
  home_geoloc: raw?.home_geoloc || null,
  owner_id_image_url: raw?.owner_id_image_url || null,
  business_legal_name: raw?.business_legal_name || '',
  business_registration_number: raw?.business_registration_number || '',
  business_duration: raw?.business_duration || '',
  business_dti_reg_cert_image_url: raw?.business_dti_reg_cert_image_url || null,
  bank_name: raw?.bank_name || '',
  bank_account_name: raw?.bank_account_name || '',
  bank_account_number: raw?.bank_account_number || '',
});

export const transformBusinessIndividual = (raw) => ({
  first_name: raw?.first_name || '',
  last_name: raw?.last_name || '',
  home_address: raw?.home_address || '',
  home_geoloc: raw?.home_geoloc || null,
  owner_id_image_url: raw?.owner_id_image_url || null,
  business_duration: raw?.business_duration || '',
  bank_name: raw?.bank_name || '',
  bank_account_name: raw?.bank_account_name || '',
  bank_account_number: raw?.bank_account_number || '',
});

export const transformBusinessPartner = (raw) => ({
  first_name: raw?.first_name || '',
  last_name: raw?.last_name || '',
  home_address: raw?.home_address || '',
  home_geoloc: raw?.home_geoloc || null,
  owner_id_image_url: raw?.owner_id_image_url || null,
  business_legal_name: raw?.business_legal_name || '',
  business_registration_number: raw?.business_registration_number || '',
  business_incorporation_date: raw?.business_incorporation_date || '',
  sec_cert_of_partnership_image_url:
    raw?.sec_cert_of_partnership_image_url || null,
  art_of_partnership_image_url: raw?.art_of_partnership_image_url || null,
});

export const transformBusinessInfo = (data, type) => {
  switch (type) {
    case 'CORPORATION':
      return transformBusinessCorporation(data);
    case 'SOLE_PROPRIETORSHIP':
      return transformBusinessSole(data);
    case 'INDIVIDUAL':
      return transformBusinessIndividual(data);
    default:
      return transformBusinessPartner(data);
  }
};

/* MERCHANT CATEGORY & CHANNEL ATTACHMENT START */
export const transformAttachedPaymentCategory = (raw) => ({
  id: raw?.uuid,
  logo: raw?.logo,
  name: raw?.name,
  status: raw?.status,
  code: raw?.code,
  parent_category_status: raw?.parent_category_status,
});

export const transformAttachedPaymentChannel = (raw) => ({
  id: raw?.uuid,
  code: raw?.code,
  logo: raw?.logo,
  name: raw?.name,
  status: raw?.status,
  sequence: raw?.sequence,
  visibility: raw?.visibility,
  payment_channel_fees: raw?.payment_channel_fees,
});

export const transformChannelOptions = (raw) => ({
  id: raw?.uuid,
  name: raw?.name || '',
  logo: raw?.logo || '',
  code: raw?.code || '',
});
/* MERCHANT CATEGORY & CHANNEL ATTACHMENT END */

export const payloadTheme = ({ theme: theme_code, ...theme_meta }) => ({
  theme_code: 'theme5', // fixed theme
  theme_meta,
});

export const transformEReceipt = (raw) => ({
  ereceipt_code: raw.ereceipt_code,
  ereceipt_token: raw.ereceipt_token,
});

export const transformSettlementTemplate = (raw) => ({
  id: raw?.uuid,
  name: raw?.name || '',
  type: raw?.type || '',
  profiles: [],
});

export const transformSettlementRequest = (raw) => ({
  id: raw?.uuid,
  amount: raw?.amount,
  amount_human: formatNumber(raw?.amount),
  created_at: raw?.created_at,
  refno: raw?.refno,
  remarks: raw?.remarks,
  status: raw?.status,
  profile: raw?.settlement_profile,
  merchant_profile_uuid: raw?.merchant_profile_uuid,
  organization_uuid: raw?.organization_uuid,
});

export const transformSettlementLedger = (raw) => ({
  id: raw?.uuid,
  created_at: raw?.created_at,
  type: raw?.type,
  amount: raw?.amount,
  balance: raw?.balance,
  description: raw?.description,
});
