/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { HiCheckCircle } from 'react-icons/hi';
import { BiChevronRight } from 'react-icons/bi';
import { RiUploadCloud2Line } from 'react-icons/ri';

import { uploadPhotos } from 'partial/form/FormPhotoDnd';
import { useDatasetOptions } from 'core-modules/dataset/hooks';
import ImagePreview from 'core-modules/merchant-onboarding/components/ImagePreview';
import { MAP_BUSINESS_TYPE_DATA_SET_CODE } from 'core-modules/onboarding/constants';

import { useGetGeneralInfo } from '../hooks';

function MerchantAttachments() {
  const [, generalInfo] = useGetGeneralInfo();
  const [selected, setSelected] = React.useState('');

  const [, requiredAttachmentFields] = useDatasetOptions(
    MAP_BUSINESS_TYPE_DATA_SET_CODE?.[generalInfo?.business_type] || '',
    true
  );

  const requirements = useMemo(
    () =>
      generalInfo?.requirements
        ?.filter((item) =>
          requiredAttachmentFields?.some(
            (field) => field?.label === item?.label
          )
        )
        ?.sort((a, b) => {
          if (a?.label > b?.label) return 1;
          if (b?.label > a?.label) return -1;
          return 0;
        }),
    [generalInfo, requiredAttachmentFields]
  );

  const selectedValues = React.useMemo(
    () =>
      (requirements?.find((item) => item?.label === selected)?.value || '')
        .split(',')
        .filter((v) => !isEmpty(v)),
    [selected, requirements]
  );

  useEffect(() => {
    setSelected(requirements?.[0]?.label || '');
  }, [requirements, setSelected]);

  return (
    <div className="grid grid-cols-12 min-h-[200px] border rounded-xl">
      <div className="hidden md:block col-span-7 p-2">
        <ImagePreview
          name={selected}
          onChange={() => {}}
          values={selectedValues}
          label={selected}
          readOnly
        />
      </div>
      <div className="col-span-12 md:col-span-5 divide-y divide-gray-200 border-l">
        {/* <div className="text-gray-600 p-3 font-semibold">
          Upload document/s below:
        </div> */}
        {requirements?.map((item) => (
          <RequirementItem
            key={item?.label}
            name={item?.label}
            label={item?.label}
            onChange={() => {}}
            value={item?.value || ''}
            isSelected={selected === item?.label}
            onSelect={() => setSelected(item?.label)}
            readOnly
          />
        ))}
      </div>
    </div>
  );
}

function RequirementItem({
  name,
  onChange,
  value,
  label,
  isSelected,
  onSelect,
  readOnly,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const handleAdd = async ({ target }) => {
    onSelect();
    setIsUploading(true);
    const filteredFiles = [];
    for (let i = 0; i < target.files.length; i += 1) {
      const file = target.files[i];
      if ((file.type || '').indexOf('image/') > -1) filteredFiles.push(file);
    }
    const prevValue = (value || '').split(',').filter((x) => !isEmpty(x));
    onChange((state) => ({
      ...state,
      [name]: `${prevValue
        .concat(filteredFiles)
        .map(() => 'uploading')
        .join(',')}`,
    }));
    const files = await uploadPhotos(filteredFiles);
    setIsUploading(false);
    onChange((state) => ({
      ...state,
      [name]: `${prevValue.concat(files).join(',')}`,
    }));
  };
  const isUploadingAll = isUploading || (value || '').indexOf('uploading') > -1;
  const itemsCount = (value || '').split(',').filter((x) => !isEmpty(x)).length;

  const selectedValues = React.useMemo(
    () => value.split(',').filter((v) => !isEmpty(v)),
    [value]
  );
  return (
    <div>
      <div
        className={cn(
          'relative flex justify-between p-3 space-x-3 transition items-center cursor-pointer',
          isSelected
            ? 'bg-gray-100 md:bg-white hover:bg-white -ml-px'
            : 'bg-gray-100 hover:bg-gray-200'
        )}
      >
        <button
          className="pointer-events-none absolute inset-0 w-full h-full md:pointer-events-auto"
          onClick={onSelect}
          type="button"
        />
        <button
          className="absolute inset-0 w-full h-full md:pointer-events-none"
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        />
        <div className="block text-xs font-medium text-primary-500">
          {label}
        </div>
        <div className="flex-shrink-0 flex items-center space-x-1">
          {!isEmpty(value) && !isUploadingAll ? (
            <HiCheckCircle className="w-6 h-6 flex-shrink-0 text-green-500" />
          ) : null}
          {readOnly ? (
            <div className="text-xs text-gray-500">
              {itemsCount ? <span>{itemsCount} item(s)</span> : null}
            </div>
          ) : (
            <label className="relative z-10 bg-white border rounded px-2 py-1 hover:bg-gray-50 cursor-pointer flex items-center">
              {isUploadingAll ? (
                <div className="inline-block mr-1">
                  <LoaderIcon />
                </div>
              ) : (
                <RiUploadCloud2Line className="inline-block mr-1" />
              )}
              <span className="text-xs font-medium text-gray-700">
                Attach File
              </span>
              <input
                className="absolute h-0 w-0 opacity-0"
                type="file"
                onChange={handleAdd}
                accept="image/*, application/pdf"
                multiple
              />
            </label>
          )}
          {/* Here */}

          <BiChevronRight
            className={cn(
              'w-5 h-5 transition ease-in-out md:hidden',
              isOpen ? 'transform rotate-90' : ''
            )}
          />
        </div>
      </div>
      <div
        className={cn(
          'transition-all overflow-hidden md:hidden',
          isOpen ? 'h-[200px]' : 'h-0'
        )}
      >
        <div className="h-[200px]">
          <ImagePreview
            name={name}
            onChange={() => {}}
            values={selectedValues}
            label={label}
            readOnly
          />
        </div>
      </div>
    </div>
  );
}

RequirementItem.defaultProps = {
  isSelected: false,
  onSelect: () => {},
  readOnly: false,
};

RequirementItem.propTypes = {
  readOnly: PropTypes.bool,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function LoaderIcon() {
  return (
    <svg
      className="animate-spin h-4 w-4 text-primary-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx={12}
        cy={12}
        r={10}
        stroke="currentColor"
        strokeWidth={4}
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
}

export default MerchantAttachments;
