/* eslint-disable */
import React, { useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import AuthStore from 'auth-store';
import PropTypes from 'prop-types';
import { HiCheck } from 'react-icons/hi';
import { logoWhiteIcon } from 'assets/images';
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import Splash from 'partial/containers/Splash';
import * as assets from 'assets/images';
import { useOrgFirstMerchantData } from 'core-modules/merchants/hooks';
import { useAccountMeta, useCurrentAccount } from 'modules/auth/hooks';

import MerchantPage from './containers/MerchantPage';
import ProfilePage from './containers/ProfilePage';
import AttachmentsPage from './containers/AttachmentsPage';
import EReceiptPage from './containers/EReceiptPage';
import ReviewApplication from './containers/ReviewApplication';
import InReviewCard from './components/InReviewCard';
import RejectedCard from './components/RejectedCard';
import TestModaCard from './components/TestModeCard';
import EreceiptInstruction from './components/EreceiptInstruction';

const Check = ({ value }) => (
  <div
    className={cn(
      'h-4 w-4 rounded-full ring-2 text-white flex items-center justify-center',
      value ? 'bg-green-500 ring-green-500' : 'bg-transparent  ring-white/50'
    )}
  >
    {value ? <HiCheck className="h-5 w-5" /> : null}
  </div>
);

Check.propTypes = {
  value: PropTypes.bool.isRequired,
};

function OnboardingContainer() {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const shouldRedirectRef = useRef(location?.state?.from !== 'dashboard');

  const [, orgMerchant] = useOrgFirstMerchantData();

  const [, data] = useCurrentAccount();
  const { meta } = useAccountMeta();

  const isReadOnly = useMemo(() => {
    if (isEmpty(orgMerchant)) return false;
    return (
      orgMerchant?.approval_status !== 'PENDING' &&
      orgMerchant?.approval_status !== 'REJECTED'
    );
  }, [orgMerchant]);

  useEffect(() => {
    if (!data?.id) return;
    if (data?.is_org_kyc_completed || data?.env_mode === 'TEST') {
      history.push('/dashboard');
      return;
    }
    if (!meta?.is_org_profile_submitted) return;
    if (!shouldRedirectRef.current) return;
    if (!match.isExact) {
      shouldRedirectRef.current = false;
      return;
    }
    const uncompletedStepRoutes = [];
    if (!meta?.is_first_merchant_general_submitted)
      uncompletedStepRoutes.push(`${match.path}/merchant`);
    if (!meta?.is_first_merchant_attachment_submitted)
      uncompletedStepRoutes.push(`${match.path}/documents`);
    history.push(uncompletedStepRoutes[0]);
  }, [data, meta, history, match.path, match.isExact]);

  if (!data?.id) return <Splash />;
  return (
    <div
      className={cn(
        'flex-1 flex flex-col w-full bg-gradient-to-br from-gray-50 to-white',
        orgMerchant?.approval_status !== 'IN_REVIEW'
          ? 'sm:flex-row sm:h-screen'
          : ''
      )}
    >
      <div
        className={cn(
          'bg-primary-500 flex w-full justify-center relative',
          orgMerchant?.approval_status !== 'IN_REVIEW'
            ? 'sm:w-80 md:w-96 sm:rounded-tr-[3rem]'
            : ''
        )}
      >
        <div className="space-y-4 sm:p-10 p-5">
          <div className="flex-shrink-0 flex items-center">
            <img className="h-16" src={logoWhiteIcon} alt="logo" />
          </div>

          {orgMerchant?.approval_status !== 'IN_REVIEW' && (
            <>
              <div className="mt-10 space-y-2">
                <h1 className="text-white text-xl font-semibold">
                  You&apos;re almost there!
                </h1>
                <p className="text-white text-base">
                  Just a few more steps and we are good to go!
                </p>
                <p className="text-white/80 text-sm">
                  To start accepting and sending payment in{' '}
                  <b className="text-white">Live mode</b>, we need you to
                  complete account activation process
                </p>
              </div>
              <div className="flex flex-col font-normal space-y-1 text-base">
                <NavLink
                  exact
                  className="text-white px-4 py-2 flex items-center space-x-3 mr-2 font-normal rounded-l-lg"
                  activeClassName="bg-primary-600"
                  to="/onboarding"
                >
                  <Check value={meta?.is_org_profile_submitted} />
                  <span>Profile</span>
                </NavLink>
                <NavLink
                  className="text-white px-4 py-2 flex items-center space-x-3 mr-2 font-normal rounded-l-lg"
                  activeClassName="bg-primary-600"
                  to="/onboarding/merchant"
                >
                  <Check value={meta?.is_first_merchant_general_submitted} />
                  <span>Project Setup</span>
                </NavLink>
                <NavLink
                  className="text-white px-4 py-2 flex items-center space-x-3 mr-2 font-normal rounded-l-lg"
                  activeClassName="bg-primary-600"
                  to="/onboarding/documents"
                >
                  <Check value={meta?.is_first_merchant_attachment_submitted} />
                  <span>Requirements</span>
                </NavLink>
                <NavLink
                  className="text-white px-4 py-2 flex items-center space-x-3 mr-2 font-normal rounded-l-lg"
                  activeClassName="bg-primary-600"
                  to="/onboarding/ereceipt"
                >
                  <Check value={meta?.is_ereceipt_submitted} />
                  <span>eReceipt</span>
                </NavLink>
                <NavLink
                  className="text-white px-4 py-2 flex items-center space-x-3 mr-2 font-normal rounded-l-lg"
                  activeClassName="bg-primary-600"
                  to="/onboarding/review"
                >
                  <Check
                    value={
                      !isEmpty(orgMerchant) &&
                      orgMerchant?.approval_status !== 'PENDING' &&
                      orgMerchant?.approval_status !== 'REJECTED'
                    }
                  />
                  <span>Review and Submit</span>
                </NavLink>
              </div>
            </>
          )}
        </div>
        <img
          src={assets.logoXl}
          className="absolute bottom-0 right-0 opacity-20"
          alt=""
        />
      </div>
      <div className="flex-grow sm:h-full overflow-auto">
        <div
          className={cn(
            'w-full p-5 sm:p-10',
            orgMerchant?.approval_status === 'IN_REVIEW'
              ? 'mx-auto'
              : 'max-w-5xl'
          )}
        >
          <div
            className={cn(
              'space-y-5',
              orgMerchant?.approval_status === 'IN_REVIEW'
                ? 'flex flex-col items-center'
                : ''
            )}
          >
            <div className="max-w-3xl w-full space-y-5">
              {orgMerchant?.approval_status === 'REJECTED' && (
                <RejectedCard merchantData={orgMerchant} />
              )}
              {orgMerchant?.approval_status === 'IN_REVIEW' && <InReviewCard />}
              <TestModaCard />
            </div>

            <Switch>
              <Route exact path="/onboarding">
                <div className="max-w-3xl w-full">
                  <div className="space-y-2 mb-4 pb-4 border-b">
                    <h1 className="title-h1">Representative Details</h1>
                    <p className="text-gray-500 text-base">
                      eGovPay collects this data in order to validate your
                      identity and secure your account.
                    </p>
                  </div>
                  <ProfilePage
                    onNext={() => history.push('/onboarding/merchant')}
                    readOnly={isReadOnly}
                  />
                </div>
              </Route>
              <Route path="/onboarding/merchant">
                <div className="max-w-3xl w-full">
                  <div className="space-y-2 mb-4 pb-4 border-b">
                    <h1 className="title-h1">Setup your first project</h1>
                    <p className="text-gray-500 text-base">
                      Fill up the basic information about your project. You can
                      add more projects later.
                    </p>
                  </div>
                  <MerchantPage
                    onNext={() => history.push('/onboarding/documents')}
                    readOnly={isReadOnly}
                  />
                </div>
              </Route>
              <Route path="/onboarding/documents">
                <div className="max-w-3xl w-full">
                  <div className="space-y-2 mb-4 pb-4 border-b">
                    <h1 className="title-h1">Requirements</h1>
                    <p className="text-gray-500 text-base">
                      In order for us to validate that you are a legit agency,
                      please submit the following documents.
                    </p>
                  </div>
                  <AttachmentsPage
                    onBack={() => history.push('/onboarding/merchant')}
                    onNext={() => history.push('/onboarding/ereceipt')}
                    readOnly={isReadOnly}
                  />
                </div>
              </Route>
              <Route path="/onboarding/ereceipt">
                <div className="max-w-3xl w-full">
                  <EreceiptInstruction />
                  <EReceiptPage
                    onNext={() => {
                      history.push('/onboarding/review');
                    }}
                    readOnly={isReadOnly}
                  />
                </div>
              </Route>
              <Route path="/onboarding/review">
                <div className="max-w-3xl w-full">
                  <div className="space-y-2">
                    <h1 className="title-h1">Review your information</h1>
                    {/* <p className="text-gray-500 text-base">
                      A payout is when eGovPay sends money to your bank
                      account. Connect your accounts to accept payments more
                      quickly and to help us better understand your business.
                    </p> */}
                  </div>
                  <ReviewApplication
                    onNext={() => {
                      history.push('/dashboard');
                    }}
                    onSkipped={() => {
                      AuthStore.set('_skip_onboarding', '1');
                      history.push('/dashboard');
                    }}
                    readOnly={isReadOnly}
                  />
                </div>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

OnboardingContainer.propTypes = {};

export default OnboardingContainer;
