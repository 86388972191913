import React from 'react';
import PropTypes from 'prop-types';
import * as assets from 'assets/images';

const Group = ({ label, description, ...rest }) => {
  return (
    <div className="flex py-4 sm:px-10 px-5" data-aos="fade-up" {...rest}>
      <div className="flex-shrink-0 pr-4">
        <img
          className="h-[18px] w-[18px]"
          src={assets.checkCircle}
          alt="check"
        />
      </div>
      <div className="flex-1">
        <div className="font-bold text-lg -mt-1 text-slate-800 mb-1">
          {label}
        </div>
        <div className="text-base text-slate-500">{description}</div>
      </div>
    </div>
  );
};

Group.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

function HowItWorks() {
  return (
    <div className="container mx-auto mt-8 md:mt-14 mb-10">
      <div
        className="text-slate-900 text-3xl sm:text-5xl font-bold text-center mb-12"
        data-aos="fade-up"
      >
        How does it work?
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 sm:gap-12 gap-5 mb-10 sm:p-10 p-5 bg-blue-50 rounded-3xl overflow-hidden">
        <div className="flex items-center flex-row">
          <img
            className="w-full max-w-[320px] md:max-w-[488px] mx-auto mt-10 sm:mt-0"
            src={assets.bannerPgiApi}
            alt="banner"
            data-aos="zoom-in"
          />
        </div>
        <div>
          <div
            className="font-semibold sm:text-3xl text-xl text-slate-900 leading-9 mb-4"
            data-aos="fade-right"
            data-aos-delay="0"
          >
            Payment Gateway API
          </div>
          <div
            className="text-lg text-slate-500 mb-8"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            Allows for greater flexibility and customization of the payment
            process, seamless integration with existing systems, and the ability
            to handle large volumes of transactions.
          </div>
          <div className="rounded-2xl bg-white py-4 divide-y overflow-hidden">
            <Group
              label="Customization"
              description="Allows for greater customization of the payment process to match your business needs."
              data-aos-delay="0"
            />
            <Group
              label="Integration"
              description="Integrated with your existing systems, streamlining your payment process."
              data-aos-delay="50"
            />
            <Group
              label="Scalability"
              description="Handle large volumes of transactions, making it ideal for businesses that are looking to grow."
              data-aos-delay="100"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-10 sm:p-10 p-5 bg-blue-50 rounded-3xl overflow-hidden">
        <div>
          <div
            className="font-semibold sm:text-3xl text-xl text-slate-900 leading-9 mb-4"
            data-aos="fade-left"
            data-aos-delay="0"
          >
            Payment Links
          </div>
          <div
            className="text-lg text-slate-500 mb-8"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            A simple and secure way for customers to make payments. They allow
            customers to pay for products or services with just a click of a
            link, without going through a complicated checkout process.
          </div>
          <div className="rounded-2xl bg-white py-4 divide-y overflow-hidden">
            <Group
              label="Flexibility"
              description="Making it easier for customers to pay using their preferred method"
              data-aos-delay="0"
            />
            <Group
              label="Seamless Experience"
              description="Can be sent via chat, email or online enquiry/RFP forms"
              data-aos-delay="50"
            />
            <Group
              label="Efficiency"
              description="Can be used for recurring payments, saving time and effort"
              data-aos-delay="100"
            />
          </div>
        </div>
        <div className="order-first md:order-last items-center flex flex-row">
          <img
            className="ml-auto mr-auto md:mr-0 w-full max-w-[320px] md:max-w-[488px] mt-10 sm:mt-0"
            src={assets.bannerPaymentLink}
            alt="banner"
            data-aos="zoom-in"
          />
        </div>
      </div>
    </div>
  );
}

HowItWorks.propTypes = {};

export default HowItWorks;
