import React from 'react';
import PropTypes from 'prop-types';
import FormPassword from 'partial/form/FormPassword';
import Button from 'partial/components/Button';
import { ModalCard, useCreateModal } from 'partial/components/Modal';
import { useActiveAccount, useSwitchProfile } from '../hooks';

const MAP_CHANGE_TO_LABEL = {
  TEST: 'Production Account',
  LIVE: 'Test Account',
};

const MAP_CHANGE_TO = {
  TEST: 'LIVE',
  LIVE: 'TEST',
};

const SwitchAccount = ({ onClose }) => {
  const [, submit] = useSwitchProfile();
  const [account] = useActiveAccount();
  const [isSuccess, setIsSuccess] = React.useState(false);
  React.useEffect(() => {
    const payload = {
      environment_type: MAP_CHANGE_TO[account.env_mode],
    };
    submit(payload, () => setIsSuccess(true), onClose);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="p-5 text-center shadow-primary-2xl rounded-2xl">
      <div className="text-gray-600 mb-3">
        {!isSuccess ? 'Switching account...' : 'Please wait...'}
      </div>
      <div className="loaderBar">
        <div className="loaderBarInner" />
      </div>
    </div>
  );
};

SwitchAccount.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export const useSwitchProfileModal = () => {
  const createModal = useCreateModal('SWITCH_PROFILE_MODAL');
  const openWithPasswordModal = React.useCallback(() => {
    createModal({
      content: (onClose) => (
        <ModalCard size="sm">
          <SwitchProfileModal onClose={onClose} />
        </ModalCard>
      ),
    });
  }, [createModal]);
  const swithAccount = React.useCallback(() => {
    createModal({
      content: (onClose) => (
        <ModalCard size="sm">
          <SwitchAccount onClose={onClose} />
        </ModalCard>
      ),
    });
  }, [createModal]);
  return [
    swithAccount, // SWITCH WITHOUT PASSWORD INPUT
    openWithPasswordModal, // THIS PROMPTS USER FOR PASSWORD WHEN SWITCHING ACCOUNT
  ];
};

function SwitchProfileModal({ onClose }) {
  const [isLoading, submit] = useSwitchProfile();
  const [account] = useActiveAccount();
  const [errors] = React.useState({});
  const [isSwitching, setIsSwitching] = React.useState(false);
  const [form, setForm] = React.useState({
    password: '',
    environment_type: MAP_CHANGE_TO[account.env_mode],
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    submit(
      form,
      () => setIsSwitching(true),
      () => {
        setForm((state) => ({ ...state, password: '' }));
      }
    );
  };
  if (isSwitching) {
    return (
      <div className="p-5 text-center">
        <div className="text-gray-600 mb-3">Switching account...</div>
        <div className="loaderBar">
          <div className="loaderBarInner" />
        </div>
      </div>
    );
  }
  return (
    <div className="p-5">
      <form className="space-y-3" onSubmit={handleSubmit}>
        <div className="text-center">
          You are switching to <b>{MAP_CHANGE_TO_LABEL[account.env_mode]}</b>
        </div>
        <div>
          <FormPassword
            autoFocus
            name="password"
            label="Enter Password"
            type="password"
            required
            onChange={setForm}
            value={form?.password}
            error={errors?.password}
          />
        </div>
        <div className="flex justify-center space-x-3 items-center">
          <Button primary disabled={isLoading} type="submit">
            Switch Account
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </div>
      </form>
    </div>
  );
}

SwitchProfileModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SwitchProfileModal;
