import dayjs from 'dayjs';
import { HmacSHA256 } from 'crypto-js';
import { req } from 'react-reqq-lite';
import { toastError } from 'react-geek-toast';
import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import { each, isEmpty, omitBy, forOwn, get } from 'lodash';

export const reqLoader = (constant, value, key = 'get') => {
  req.set(`LOADING/${key}/${constant}`, value);
};

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ''));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== 'boolean' ? default_value : str;
  return v;
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const formatCurrency = (number, currency = 'PHP') =>
  Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  }).format(number);

export const formatDate = (date, format = 'MM/DD/YYYY', defaultValue = '-') => {
  if (!date) return defaultValue;
  const d = new Date(date);
  if (d.toString() === 'Invalid Date') return defaultValue;
  return dayjs(d).format(format);
};

export const removeEmpty = (obj) => omitBy(obj, (x) => isEmpty(`${x}`));

export const transformIncluded = (x, included) => {
  if (!included || isEmpty(included)) return x;
  const rowIncluded = {};
  forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === get(z, 'type') && y.id === get(z, 'id')
            ) || {}
        )
      : included.find(
          (y) => y.type === get(v, 'data.type') && y.id === get(v, 'data.id')
        ) || {};
  });
  const { links, relationships, type, ...rest } = x;
  return { ...rest, included: rowIncluded };
};

export const getInitials = (str) => {
  const arr = (str || 'SAFIFY').split(' ');
  if (arr.length > 1) {
    return `${arr[0][0]}${arr[1][0]}`.toUpperCase();
  }
  return `${arr[0][0]}${arr[0][1]}`.toUpperCase();
};

export const getInitialsThumb = (str) => {
  return `https://via.placeholder.com/64/ffe1b5/e6a13f?text=${getInitials(
    str
  )}`;
};

export const generate = (length = 10) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; i += 1) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const persistStorage = {
  get: (key, defaultValue = false) => {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (err) {
      return defaultValue;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    localStorage.setItem(key, newValue);
  },
  remove: (key) => {
    localStorage.removeItem(key);
  },
};

export const storage = {
  get: (key, defaultValue = false) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return defaultValue;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    sessionStorage.setItem(key, newValue);
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

const objectStorage = {
  get: (key, defaultValue) => {
    try {
      const obj = JSON.parse(sessionStorage.getItem(key));
      if (obj.type === 'object') return JSON.parse(obj.data);
      return obj.data;
    } catch (err) {
      return defaultValue;
    }
  },
  set: (key, newValue) => {
    const type = typeof newValue;
    const data = type === 'object' ? JSON.stringify(newValue) : newValue;
    sessionStorage.setItem(key, JSON.stringify({ type, data }));
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

export const usePersistState = (
  key,
  defaultValue,
  config = { keepOnUnmount: true }
) => {
  const [state, setState] = useState(objectStorage.get(key, defaultValue));
  const stateRef = useRef(state);
  const set = useCallback(
    (v) => {
      const value = typeof v === 'function' ? v(stateRef.current) : v;
      setState(value);
      objectStorage.set(key, value);
    },
    [key, setState]
  );
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  useEffect(() => {
    return () => {
      if (!config?.keepOnUnmount) {
        objectStorage.remove(key);
      }
    };
  }, [key, config?.keepOnUnmount]);
  return [state, set];
};

export const formatMobileNumber = (num) => {
  if (num.substring(0, 2) === '09') {
    return `639${num.substring(2, 11)}`;
  }
  return num;
};

export const payloadMobileNumber = (num) => {
  if (num.substring(0, 3) === '639') {
    return `09${num.substring(3, 12)}`;
  }
  return num;
};

export const formatSort = ({ sort, ...rest }) => {
  const sortKey = {};
  const [by, order] = (sort || '').split(':');
  if (by && order) {
    sortKey[`sort[${by}]`] = order;
  }
  return {
    ...rest,
    ...sortKey,
  };
};

export const downloadArrayToCsv = (data, filename) => {
  try {
    const rows = [];
    const headers = Object.keys(data[0]);
    rows.push(headers);
    each(data, (v) => {
      const rowArr = [];
      each(v, (r) => {
        rowArr.push(`"${r}"`);
      });
      rows.push(rowArr);
    });
    const csvContent = `data:text/csv;charset=utf-8,${rows
      .map((e) => e.join(','))
      .join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    toastError('Unable to generate download.');
  }
};

export const useOnClickOutside = (ref, onClick, byClassName) => {
  useEffect(() => {
    function handleClickOutside(event) {
      const isInside = () => {
        if (!byClassName) return false;
        const elms = document.getElementsByClassName(byClassName);
        let l = 0;
        for (let i = 0; i < elms.length; i += 1) {
          const el = elms[i];
          if (el && el.contains(event.target)) l += 1;
        }
        return l > 0;
      };
      if (ref.current && !ref.current.contains(event.target) && !isInside()) {
        onClick(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClick, byClassName]);
};

const cachedScripts = [];

export const useScript = (id, src) => {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    // Create script
    const fjs = document.getElementsByTagName('script')[0];
    const script = document.createElement('script');

    // Script event listener callbacks for load and error
    const onScriptLoad = () => {
      setState({
        loaded: true,
        error: false,
      });
    };

    const onScriptError = () => {
      // Remove from cachedScripts we can try loading again
      const i = cachedScripts.indexOf(src);
      if (i >= 0) cachedScripts.splice(i, 1);
      script.remove();

      setState({
        loaded: true,
        error: true,
      });
    };

    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false,
      });
    } else {
      cachedScripts.push(src);
      script.src = src;
      script.id = id;
      script.async = true;
      fjs.parentNode.insertBefore(script, fjs);

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      // Add script to document body
      document.body.appendChild(script);
    }
    // Remove event listeners on cleanup
    return () => {
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
  }, [src, id]);

  return [state.loaded, state.error];
};

const defaultOptions = {
  id: '',
  filter: {},
  payload: {},
  autoFetch: true,
  onSuccess: () => {},
  onError: () => {},
};

export const withReqHookDefaultOptions = (reqHookFunction) => (
  options = defaultOptions
) =>
  reqHookFunction({
    ...defaultOptions,
    ...options,
  });

export const createDigest = (amount, refno, token) => {
  return HmacSHA256(`${amount}|${refno}`, token);
};
