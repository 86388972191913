import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useInputId } from './hooks';

function FormTextArea({
  id: defaultId,
  icon: Icon,
  label,
  name,
  onChange,
  onSetFieldValue,
  value,
  error,
  required,
  disabled,
  readOnly,
  ...props
}) {
  const [id] = useInputId(defaultId);
  const handleChange = ({ target }) => {
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: target.value,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, target.value);
    }
  };
  const showIcon = Icon || error;
  const isError = !!error;
  return (
    <div
      className={cn(
        'relative border rounded-md px-3 py-2 focus-within:ring-1 z-10',
        isError
          ? 'border-red-300 focus-within:ring-red-500 focus-within:border-red-500'
          : 'border-gray-200 focus-within:ring-primary-600 focus-within:border-primary-600',
        disabled || readOnly ? 'bg-gray-100' : 'bg-white'
      )}
    >
      <label
        htmlFor={id}
        className="w-full text-xs font-medium text-primary-500 flex justify-between"
      >
        <span>{label}</span>
        {!required && (
          <span className="tracking-wider px-2 text-[8px] py-0.5 text-gray-500 rounded-full bg-gray-100 uppercase ml-1">
            optional
          </span>
        )}
      </label>
      <textarea
        id={id}
        type="text"
        name="name"
        className={cn(
          'block w-full border-0 p-0 placeholder-gray-500 focus:ring-0 sm:text-base',
          'focus:outline-none bg-transparent disabled:bg-gray-100 disabled:text-gray-500 read-only:bg-gray-100',
          'pr-10' // with icon
        )}
        rows="5"
        onChange={handleChange}
        value={value || ''}
        disabled={disabled}
        readOnly={readOnly}
        {...props}
      />
      {showIcon && (
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          title={error || ''}
        >
          {!error ? (
            <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          ) : null}
        </div>
      )}
    </div>
  );
}

FormTextArea.defaultProps = {
  id: '',
  icon: false,
  type: 'text',
  error: null,
  required: false,
  onChange: false,
  onSetFieldValue: false,
  disabled: false,
  readOnly: false,
};

FormTextArea.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default React.memo(FormTextArea);
