import React, { useState, forwardRef, useImperativeHandle } from 'react';
import * as yup from 'yup';
import { each } from 'lodash';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

// import FormInput from 'partial/form/FormInput';
import Form /* ErrorLabel */ from 'partial/form/Form';
import { useEReceipt } from 'core-modules/merchant-onboarding/hooks';
import Button from 'partial/components/Button';
import { LinkIcon } from '@heroicons/react/outline';
import { connectWithEReceipt } from 'lib/e-receipt/widget';
import GenericInfo, { InfoItem } from 'partial/components/GenericInfo';

import eReceiptLogo from '../../../lib/e-receipt/logo.png';

const validationSchema = yup.object().shape({
  ereceipt_code: yup.string().required('Required'),
  ereceipt_token: yup.string().required('Required'),
});

const EReceiptForm = forwardRef(
  (
    {
      // readOnly,
      onSubmit,
      onSuccess,
    },
    ref
  ) => {
    const [, data] = useEReceipt();

    const [validateOnChange, setValidateOnChange] = useState(false);

    const { values, setFieldValue, handleSubmit, errors } = useFormik({
      initialValues: data,
      validationSchema,

      onSubmit: async (form, { setFieldError }) => {
        onSubmit(form, onSuccess, (err) => {
          each(err?.response?.errors, (v, k) => {
            setFieldError(k, v);
          });
        });
      },
      validateOnChange,
    });

    useImperativeHandle(
      ref,
      () => ({
        submit: handleSubmit,
        setValidateOnChange,
      }),
      [handleSubmit, setValidateOnChange]
    );

    return (
      <Form onSubmit={handleSubmit} error={errors} noValidate>
        {values.ereceipt_code && values.ereceipt_token ? (
          <div className="mb-12 bg-white rounded-lg border">
            <div className="flex justify-center pt-6 border-b pb-6">
              <img className="w-36" src={eReceiptLogo} alt="eReceipt" />
            </div>
            <div className="-my-4">
              <GenericInfo data={values}>
                <InfoItem value="ereceipt_code" label="Code" />
                <InfoItem value="ereceipt_token" label="Token" />
              </GenericInfo>
            </div>
            <div className="flex justify-end pb-6 px-6 border-t pt-4">
              <Button
                icon={LinkIcon}
                onClick={() => {
                  connectWithEReceipt().then((res) => {
                    if (res.code && res.token) {
                      setFieldValue('ereceipt_code', res.code);
                      setFieldValue('ereceipt_token', res.token);
                    }
                  });
                }}
              >
                Change eReceipt Account
              </Button>
            </div>
          </div>
        ) : (
          <div className="mb-12 bg-white rounded-lg border">
            <div className="flex justify-center pt-6 border-b pb-6">
              <img className="w-36" src={eReceiptLogo} alt="eReceipt" />
            </div>
            <div className="flex justify-center pb-6 px-6 pt-4">
              <Button
                icon={LinkIcon}
                dark
                onClick={() => {
                  connectWithEReceipt().then((res) => {
                    if (res.code && res.token) {
                      setFieldValue('ereceipt_code', res.code);
                      setFieldValue('ereceipt_token', res.token);
                    }
                  });
                }}
              >
                Connect with eReceipt
              </Button>
            </div>
            {Boolean(errors.ereceipt_code || errors.ereceipt_token) && (
              <div className="text-danger-500 pb-4 text-center">
                Please connect your eReceipt before proceeding!
              </div>
            )}
          </div>
        )}
        {/* <div className="space-y-4">
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-3">
          <div>
            <FormInput
              name="ereceipt_code"
              onSetFieldValue={setFieldValue}
              value={values.ereceipt_code}
              error={errors?.ereceipt_code}
              label="Code"
              required
              readOnly={readOnly}
            />
            <ErrorLabel name="ereceipt_code" />
          </div>
          <div>
            <FormInput
              name="ereceipt_token"
              onSetFieldValue={setFieldValue}
              value={values.ereceipt_token}
              error={errors?.ereceipt_token}
              label="Token"
              required
              readOnly={readOnly}
            />
            <ErrorLabel name="ereceipt_token" />
          </div>
        </div>
      </div> */}
      </Form>
    );
  }
);

EReceiptForm.defaultProps = {
  // readOnly: false,
};

EReceiptForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  // readOnly: PropTypes.bool,
};

export default EReceiptForm;
