export const transformGeneralInfo = (raw) => ({
  logo: raw?.logo || '',
  name: raw?.name || '',
  website_url: raw?.website_url || '',
  industry: raw?.industry || '',
  customer_service_number: raw?.customer_service_number || '',
  business_country: raw?.business_country || 'PH',
  business_state: raw?.business_state || '',
  business_city: raw?.business_city || '',
  business_zip_code: raw?.business_zip_code || '',
  business_address: raw?.business_address || '',
  business_geoloc: raw?.business_geoloc || '',
  business_description: raw?.business_description || '',
  business_number_of_customers: raw?.business_number_of_customers || '',
  business_monthly_sales: raw?.business_monthly_sales || '',
  business_type: raw?.business_type || '', // TODO: make this fixed => 'GOVERNMENT',
  contact_first_name: raw?.contact_first_name || '',
  contact_last_name: raw?.contact_last_name || '',
  contact_designation: raw?.contact_designation || '',
  contact_mobile_number: raw?.contact_mobile_number || '',
  contact_email_address: raw?.contact_email_address || '',
  requirements: raw?.requirements || [],
});

export const transformBusinessCorporation = (raw) => ({
  first_name: raw?.first_name || '',
  last_name: raw?.last_name || '',
  home_address: raw?.home_address || '',
  home_geoloc: raw?.home_geoloc || '',
  is_beneficial_owner: raw?.is_beneficial_owner ?? false,
  owner_id_image_url: raw?.owner_id_image_url || '',
  business_legal_name: raw?.business_legal_name || '',
  business_registration_number: raw?.business_registration_number || '',
  business_incorporation_date: raw?.business_incorporation_date || '',
  sec_cert_image_url: raw?.sec_cert_image_url || '',
  by_laws_image_url: raw?.by_laws_image_url || '',
  art_of_incor_image_url: raw?.art_of_incor_image_url || '',
  bank_name: raw?.bank_name || '',
  bank_account_name: raw?.bank_account_name || '',
  bank_account_number: raw?.bank_account_number || '',
});

export const transformBusinessSole = (raw) => ({
  first_name: raw?.first_name || '',
  last_name: raw?.last_name || '',
  home_address: raw?.home_address || '',
  home_geoloc: raw?.home_geoloc || '',
  owner_id_image_url: raw?.owner_id_image_url || '',
  business_legal_name: raw?.business_legal_name || '',
  business_registration_number: raw?.business_registration_number || '',
  business_duration: raw?.business_duration || '',
  business_dti_reg_cert_image_url: raw?.business_dti_reg_cert_image_url || '',
  bank_name: raw?.bank_name || '',
  bank_account_name: raw?.bank_account_name || '',
  bank_account_number: raw?.bank_account_number || '',
});

export const transformBusinessIndividual = (raw) => ({
  first_name: raw?.first_name || '',
  last_name: raw?.last_name || '',
  home_address: raw?.home_address || '',
  home_geoloc: raw?.home_geoloc || '',
  owner_id_image_url: raw?.owner_id_image_url || '',
  business_duration: raw?.business_duration || '',
  bank_name: raw?.bank_name || '',
  bank_account_name: raw?.bank_account_name || '',
  bank_account_number: raw?.bank_account_number || '',
});

export const transformBusinessPartner = (raw) => ({
  first_name: raw?.first_name || '',
  last_name: raw?.last_name || '',
  home_address: raw?.home_address || '',
  home_geoloc: raw?.home_geoloc || '',
  owner_id_image_url: raw?.owner_id_image_url || '',
  business_legal_name: raw?.business_legal_name || '',
  business_registration_number: raw?.business_registration_number || '',
  business_incorporation_date: raw?.business_incorporation_date || '',
  sec_cert_of_partnership_image_url:
    raw?.sec_cert_of_partnership_image_url || '',
  art_of_partnership_image_url: raw?.art_of_partnership_image_url || '',
});

export const transformEReceipt = (raw) => ({
  ereceipt_code: raw.ereceipt_code,
  ereceipt_token: raw.ereceipt_token,
});

const parseAttachment = (v) => (v === '-' || !v ? '' : v);

export const transformAttachments = (raw) => ({
  dti_registration: parseAttachment(raw?.dti_registration || ''),
  sec_certificate: parseAttachment(raw?.sec_certificate || ''),
  by_laws: parseAttachment(raw?.by_laws || ''),
  articles_of_incorporation: parseAttachment(
    raw?.articles_of_incorporation || ''
  ),
  mayors_permit: parseAttachment(raw?.mayors_permit || ''),
  bir: parseAttachment(raw?.bir || ''),
  secretarys_certificate: parseAttachment(raw?.secretarys_certificate || ''),
  audit_tax_return: parseAttachment(raw?.audit_tax_return || ''),
  gis: parseAttachment(raw?.gis || ''),
});

export const payloadBusinessCorporation = (payload) => ({
  ...payload,
  is_beneficial_owner: payload?.is_beneficial_owner ? 1 : 0,
});

export const payloadEReceipt = (payload) => ({
  ereceipt_code: payload.ereceipt_code,
  ereceipt_token: payload.ereceipt_token,
});
