import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BUSINESS_TYPES } from '../constants';

const TypeItem = ({
  icon,
  active,
  label,
  description,
  onClick,
  disabled,
  readOnly,
}) => {
  return (
    <div
      className={cn(
        'border-2 border-gray-100 rounded-lg p-3 relative transition',
        active && !disabled
          ? 'border-green-500 hover:border-green-500 ring-2 ring-opacity-50 ring-green-500 bg-slate-100 hover:bg-slate-100'
          : '',
        !active && (disabled || readOnly)
          ? 'bg-gray-100'
          : 'hover:border-primary-500 hover:bg-gray-50',
        readOnly ? 'border-primary-500' : ''
      )}
    >
      <div className="sm:space-y-3 space-y-0 flex items-center sm:flex-col flex-row space-x-2 sm:space-x-0">
        <div className="self-start">
          <div className="bg-gradient-to-br from-secondary-100 to-secondary-400 p-[2px] inline-block rounded-2xl">
            <div className="h-12 w-12 rounded-xl flex justify-center items-center bg-gradient-to-br from-white to-primary-50">
              {icon ? <img src={icon} className="h-10 w-10" alt="" /> : null}
            </div>
          </div>
        </div>
        <div className="space-y-1">
          <div className="text-base font-bold whitespace-nowrap">{label}</div>
          <div className="text-gray-500 text-sm">{description}</div>
        </div>
      </div>
      {active ? (
        <div className="absolute top-0 right-0 p-2">
          <AiFillCheckCircle className="text-green-500 h-8 w-8" />
        </div>
      ) : null}
      <button
        className="absolute inset-0 z-10 h-full w-full"
        type="button"
        onClick={onClick}
      >
        <span className="sr-only">Choose {label}</span>
      </button>
    </div>
  );
};

TypeItem.defaultProps = {
  active: false,
  icon: '',
  disabled: false,
  readOnly: false,
};

TypeItem.propTypes = {
  icon: PropTypes.string,
  active: PropTypes.bool,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

function BusinessTypeOptions({ disabled, readOnly, name, onChange, value }) {
  const handleSelect = (newValue) => () => {
    if (disabled || readOnly) return;
    onChange((state) => ({
      ...state,
      [name]: newValue,
    }));
  };
  return (
    <div className="grid grid-cols-1 sm:grid-cols-4 gap-3">
      {BUSINESS_TYPES.map((item) => (
        <TypeItem
          key={item.code}
          label={item.name}
          description={item.description}
          icon={item.icon}
          onClick={handleSelect(item.code)}
          active={value === item.code}
          disabled={disabled}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
}

BusinessTypeOptions.defaultProps = {
  disabled: false,
  readOnly: false,
};

BusinessTypeOptions.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default BusinessTypeOptions;
