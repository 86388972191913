import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import AuthStore from 'auth-store';
import { req } from 'react-reqq-lite';
import { useHistory } from 'react-router-dom';
import { toastError, toastInfo } from 'react-geek-toast';

import FormInputPin from 'partial/form/FormInputPin';
import Button from 'partial/components/Button';

import * as c from '../constants';
import { useVerifyMFA } from '../hooks';

function MultiFactorAuthForm({ onSessionExpires }) {
  const history = useHistory();

  const [form, setForm] = useState({
    email: AuthStore.get('_email'),
    environment_type: AuthStore.get('_env_type'),
  });

  const [isVerifying, verifyMFA] = useVerifyMFA({
    onSuccess: (res) => {
      const token = res?.response?.token;
      AuthStore.set('_token', token);
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
      });
      history.push('/dashboard');
    },
    onError: () => {
      setForm((prev) => ({ ...prev, code: '' }));
    },
  });

  const handleVerifyMFA = useCallback(() => {
    if (isEmpty(form?.code)) {
      toastError('Please enter the code and try again.');
      return;
    }
    verifyMFA(form);
  }, [form, verifyMFA]);

  useEffect(() => {
    if (!form?.email || !form?.environment_type) {
      toastInfo('Please re-login to continue.');
      onSessionExpires();
    }
  }, [form, onSessionExpires]);

  return (
    <div className="flex flex-col space-y-4 items-center">
      <h1 className="text-center text-xl font-semibold">
        Two-Factor Authentication Required
      </h1>
      <p className="text-center">
        Enter the 6-digit code from your Code Generator or 3rd party app below.
      </p>
      <FormInputPin name="code" value={form?.code || ''} onChange={setForm} />
      <Button primary onClick={handleVerifyMFA} disabled={isVerifying}>
        Continue
      </Button>
    </div>
  );
}

MultiFactorAuthForm.propTypes = {
  onSessionExpires: PropTypes.func.isRequired,
};

export default MultiFactorAuthForm;
