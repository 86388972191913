import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { HiXCircle } from 'react-icons/hi';
import uploadcare from 'uploadcare-widget';
import Button from 'partial/components/Button';
import { BiCloudUpload } from 'react-icons/bi';
import DropZone from '../components/DropZone';

export const uploadPhoto = (options) =>
  new Promise((resolve) => {
    const opt = {
      tabs: ['file', 'url', 'camera'],
      ...options,
    };
    uploadcare
      .openDialog(null, {
        publicKey: process.env.REACT_APP_UPLOADCARE_KEY || 'demopublickey',
        ...opt,
        imagesOnly: true,
      })
      .done((file) => {
        file.done((x) => resolve(x.cdnUrl));
      })
      .fail(() => {
        resolve(null);
      });
  });

export const uploadPhotos = (files, options) =>
  new Promise((resolve) => {
    const opt = {
      publicKey: process.env.REACT_APP_UPLOADCARE_KEY || 'demopublickey',
      // tabs: ['file', 'url', 'camera'],
      // crop: '1:1',
      ...options,
    };
    Promise.all(
      files.map(
        (file) =>
          new Promise((r) => {
            uploadcare.fileFrom('object', file, opt).then(r);
          })
      )
    ).then((out) => {
      resolve(out.map((x) => x.cdnUrl));
    });
  });

export const uploadPhotoOrPdf = (options) =>
  new Promise((resolve) => {
    const opt = {
      tabs: ['file', 'url', 'camera'],
      ...options,
    };
    uploadcare
      .openDialog(null, {
        publicKey: process.env.REACT_APP_UPLOADCARE_KEY || 'demopublickey',
        ...opt,
        inputAcceptTypes: 'image/*, application/pdf',
      })
      .done((file) => {
        file.done((x) => {
          resolve(
            queryString.stringifyUrl({
              url: x.cdnUrl,
              query: {
                type: x?.isImage ? 'image' : 'pdf',
              },
            })
          );
        });
      })
      .fail(() => {
        resolve(null);
      });
  });

export const uploadPhotosAndPdfs = (files, options) =>
  new Promise((resolve) => {
    const opt = {
      publicKey: process.env.REACT_APP_UPLOADCARE_KEY || 'demopublickey',
      ...options,
    };
    Promise.all(
      files.map(
        (file) =>
          new Promise((r) => {
            uploadcare.fileFrom('object', file, opt).then(r);
          })
      )
    ).then((out) => {
      resolve(
        out.map((x, i) => {
          const fileType = files[i].type || '';
          return queryString.stringifyUrl({
            url: x.cdnUrl,
            query: {
              type: fileType.indexOf('application/pdf') > -1 ? 'pdf' : 'image',
            },
          });
        })
      );
    });
  });

function FormPhotoDnd({
  id,
  label,
  required,
  value,
  name,
  onChange,
  onSetFieldValue,
  className,
  disableRemove,
  disabled,
  options,
}) {
  const [isUploading, setIsUploading] = React.useState(false);
  const handleDrop = async (files, onIsUploading) => {
    onIsUploading(true);
    setIsUploading(true);
    const [output] = await uploadPhotos(
      files.filter((x, i) => i < 1),
      options
    );
    onIsUploading(false);
    setIsUploading(false);
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: output,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, output);
    }
  };
  const handleUpload = async () => {
    setIsUploading(true);
    const output = await uploadPhoto(options);
    setIsUploading(false);
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: output,
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, output);
    }
  };
  const handleRemove = () => {
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: '',
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, '');
    }
  };
  return (
    <DropZone onDrop={handleDrop} disabled={disabled}>
      <div
        className={cn(
          'w-full rounded-lg bg-gray-100 border-2 border-dashed relative z-[1]',
          className
        )}
      >
        <div className="h-full w-full px-3 pt-2 pb-3 flex flex-col">
          <label
            htmlFor={id}
            className="w-full text-xs font-medium text-primary-500 flex justify-between mb-2"
          >
            <span>{label}</span>
            {!required && (
              <span className="tracking-wider px-2 text-[8px] py-0.5 text-gray-500 rounded-full bg-gray-100 uppercase ml-1">
                optional
              </span>
            )}
          </label>
          {!value ? (
            <div className="flex-1 flex">
              <div className="m-auto flex flex-col items-center justify-center">
                <BiCloudUpload className="h-16 w-16 text-gray-300" />
                <div className="m-auto text-gray-400 text-xs font-light mb-3">
                  Drag and Drop image here
                </div>
                {isUploading ? (
                  <div className="text-center loader-wrapper">
                    <div className="text-gray-500 text-xs uppercase tracking-wide mb-1">
                      Uploading...
                    </div>
                    <div className="loaderBar w-32">
                      <div className="loaderBarInner" />
                    </div>
                  </div>
                ) : (
                  <Button
                    size="sm"
                    onClick={handleUpload}
                    disabled={isUploading || disabled}
                  >
                    {isUploading ? 'Uploading...' : 'Choose File'}
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className="flex-1 bg-gray-100 rounded-lg flex relative">
              {!disableRemove && !disabled && (
                <div className="absolute z-10 -top-2 -right-1">
                  <button
                    className="text-red-500 hover:text-red-600 transition shadow-danger-lg rounded-full"
                    type="button"
                    onClick={handleRemove}
                  >
                    <HiXCircle className="h-8 w-8 bg-white p-px rounded-full" />
                  </button>
                </div>
              )}
              <img
                className="absolute h-full w-full object-contain rounded-xl"
                src={value}
                alt={label}
              />
            </div>
          )}
        </div>
      </div>
    </DropZone>
  );
}

FormPhotoDnd.defaultProps = {
  id: '',
  value: '',
  required: false,
  className: 'h-48 w-full',
  onChange: false,
  onSetFieldValue: false,
  disableRemove: false,
  options: {},
  disabled: false,
};

FormPhotoDnd.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disableRemove: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FormPhotoDnd;
