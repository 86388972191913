import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import * as assets from 'assets/images';
import Button from 'partial/components/Button';
import { useHistory } from 'react-router-dom';
import Version from 'partial/components/Version';
import { HiArrowLeft } from 'react-icons/hi';
import SignUpForm from '../forms/SignUpForm';

function SignUpSuccess({ data }) {
  const history = useHistory();
  const handleLogin = () => {
    history.push({
      pathname: '/',
      state: { email_address: data?.email_address || '' },
    });
  };
  return (
    <div>
      <div className="space-y-2">
        <img
          src={assets.logoDark}
          className="h-12 sm:h-12 mx-auto mb-5"
          alt="App"
        />
        <div className="text-lg font-semibold">Verify your email</div>
        <div className="font-light">
          Please click the link that was sent to{' '}
          <span className="font-semibold text-gray-600">
            {data?.email_address || 'you email'}
          </span>{' '}
          to verify your account.
        </div>
      </div>
      <Button className="my-5 uppercase" default onClick={handleLogin}>
        Proceed to Login
      </Button>
    </div>
  );
}

SignUpSuccess.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

function SignUp() {
  const websiteUrl = '/';
  const history = useHistory();
  const handleRedirectToWebsite = () => {
    history.push(websiteUrl);
  };
  const [isSuccess, setIsSuccess] = React.useState(null);
  return (
    <div className="flex-grow flex bg-gradient-to-br from-[#0040E7] to-[#38BDF8]">
      <div className="w-full max-w-xl p-5 flex">
        <div className="bg-white rounded-2xl flex-grow w-full p-8 lg:p-4">
          {!isEmpty(websiteUrl) && (
            <Button
              className="mb-8"
              icon={HiArrowLeft}
              onClick={handleRedirectToWebsite}
            >
              Go back to Homepage
            </Button>
          )}
          <div className="max-w-sm mx-auto">
            <div className="mb-8">
              <img className="h-10" src={assets.logoDark} alt="Brand" />
            </div>
            {isSuccess ? (
              <SignUpSuccess data={isSuccess} />
            ) : (
              <>
                <div className="mb-6 font-bold text-xl">Sign Up</div>
                <SignUpForm onSuccess={(res) => setIsSuccess(res)} />
              </>
            )}
          </div>
          <p className="text-slate-400 text-xs text-center mt-10">
            &copy; 2024 eGovPay <Version />
          </p>
        </div>
      </div>
      <div className="flex-1 lg:flex flex-col hidden">
        <div className="mt-auto mx-auto mb-24">
          <img src={assets.signUpBanner} alt="banner" />
        </div>
        <div className="mb-auto mx-auto text-center max-w-md text-white">
          <div className="text-xl font-bold mb-2">Welcome to eGovPay</div>
          <div className="font-semibold">
            Your secured government payment gateway
            <br />
            Make it easy for your customers with our one-stop epayment
            integration
          </div>
        </div>
      </div>
    </div>
  );
}

SignUp.propTypes = {};

export default SignUp;
