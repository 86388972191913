/* eslint-disable */
import React, { useState, useCallback, useRef } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { req } from 'react-reqq-lite';
import { BiInfoCircle } from 'react-icons/bi';
import { toastError } from 'react-geek-toast';
import { useHistory } from 'react-router-dom';

import {
  useGeneralInfo,
  useSaveGeneralInfo,
  useSaveRequirements,
} from 'core-modules/merchant-onboarding/hooks';
import { setError422 } from 'partial/form/Form';
import Button from 'partial/components/Button';
import Splash from 'partial/containers/Splash';
import ToolTip from 'partial/components/ToolTip';
import { useAccountMeta } from 'modules/auth/hooks';
import AttachmentsForm from '../forms/AttachmentsForm';
import { useMerchantList } from 'core-modules/merchants/hooks';
import { MERCHANT_ITEM } from 'core-modules/merchants/constants';
import { promiseGetGeneralInfo } from 'core-modules/merchant-onboarding/actions';

const LIST_FILTER = { per_page: 1 };

const AttachmentsPage = (props) => {
  const [isMerchantReady, setIsMerchantReady] = useState(null);
  const formRef = useRef();

  const history = useHistory();
  const { meta, update } = useAccountMeta();
  const [, submitGeneralInfo] = useSaveGeneralInfo();
  // const [isSubmitting, submitAttachments] = useSaveAttachments();
  const [isSubmitting, submitRequirements] = useSaveRequirements();

  useMerchantList(LIST_FILTER, async (list) => {
    const firstId = list?.[0]?.id;
    if (!firstId) return setIsMerchantReady(false);
    req.set(MERCHANT_ITEM, list?.[0]); // SET MERCHANT AS TARGET FOR useSaveGeneralInfo
    await promiseGetGeneralInfo(firstId);
    setIsMerchantReady(true);
  });
  const [, data] = useGeneralInfo();
  const handleBack = (e) => {
    e.preventDefault();
    const { onBack } = props;
    if (onBack) onBack();
  };

  const handleSubmit = useCallback(
    async (form, onSuccess, onError) => {
      try {
        await new Promise((res, rej) => {
          submitGeneralInfo(
            { business_type: form.business_type || '' },
            res,
            (err) => {
              rej(err);
              setError422(err, setFieldError);
            }
          );
        });
        const saveBusinessAttachments = await new Promise((res, rej) => {
          submitRequirements(form, res, (err) => {
            rej(err);
            setError422(err, setFieldError);
          });
        });
        onSuccess(saveBusinessAttachments);
      } catch (err) {
        onError(err);
        toastError('Something went wrong...');
      }
    },
    [submitGeneralInfo, submitRequirements]
  );

  if (isMerchantReady === false)
    return (
      <div>
        <div className="space-y-4">
          <div>You have not completed your project setup.</div>
          <div>
            Please{' '}
            <a href="/" onClick={handleBack}>
              complete project setup
            </a>{' '}
            to continue
          </div>
        </div>
      </div>
    );

  if (isEmpty(data) || isMerchantReady === null) return <Splash />;
  return (
    <div>
      <AttachmentsForm
        {...props}
        ref={formRef}
        onSubmit={handleSubmit}
        onSuccess={(value) => {
          props?.onNext(value);
          if (!meta?.is_first_merchant_attachment_submitted)
            update({ is_first_merchant_attachment_submitted: true });
        }}
      />
      <div className="flex space-x-0 sm:space-x-3 space-y-3 sm:space-y-0 flex-col sm:flex-row justify-end mt-4">
        {!props?.readOnly ? (
          <>
            <div className="mt-3 sm:mt-0 order-last sm:order-first">
              <Button
                size="lg"
                transparent
                className="w-full sm:w-auto"
                onClick={props?.onNext}
              >
                <ToolTip
                  className="text-left font-normal"
                  tip="Documents are part of the verification process and needed to be verified before approval."
                >
                  <div className="flex relative">
                    <div className="flex items-center border-dashed border-b-0 hover:border-gray-300 hover:border-b-1 space-x-2 cursor-pointer">
                      <div className="whitespace-nowrap">
                        Skip, I&apos;ll do this later.
                      </div>
                      <BiInfoCircle className="sm:block hidden" />
                    </div>
                  </div>
                </ToolTip>
              </Button>
              <p className="text-sm text-gray-500 mt-1 text-center sm:hidden flex space-x-1 items-center">
                <BiInfoCircle className="w-4 h-4 flex-shrink-0" />
                <span>
                  Documents are part of the verification process and needed to
                  be verified before approval.
                </span>
              </p>
            </div>
            <Button
              primary
              size="lg"
              disabled={isSubmitting}
              className="whitespace-nowrap order-2 sm:order-1"
              onClick={() => {
                formRef?.current?.submit();
                formRef?.current?.setValidateOnChange(true);
              }}
            >
              Save and Continue
            </Button>
          </>
        ) : (
          <Button
            primary
            size="lg"
            type="button"
            onClick={() => history.push('/dashboard')}
            className="whitespace-nowrap order-2 sm:order-1"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

AttachmentsPage.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default AttachmentsPage;
