import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { each } from 'lodash';
import { useFormik } from 'formik';
import { HiOutlineMail } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';

import {
  ModalCard,
  showDrawer,
  useCreateModal,
} from 'partial/components/Modal';
import FormInput from 'partial/form/FormInput';
import Button from 'partial/components/Button';
import FormPassword from 'partial/form/FormPassword';
import Form, { ErrorLabel } from 'partial/form/Form';
import * as assets from 'assets/images';

import ForgotPasswordModal from '../modals/ForgotPasswordModal';
import { useLogin } from '../hooks';

const initialValues = (location) => ({
  email_address: location?.state?.email_address || '',
  password: '',
});

const validationSchema = yup.object().shape({
  email_address: yup.string().email('Invalid').required('Required'),
  password: yup.string().required('Required'),
});

function LoginForm({ onLogin }) {
  const location = useLocation();
  const [isLoading, login] = useLogin();
  const [validateOnChange, setValidateOnChange] = React.useState(false);
  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues: initialValues(location),
    validationSchema,
    onSubmit: async (form, { setFieldError }) => {
      setValidateOnChange(true);
      login(
        form,
        onLogin, // onSuccess
        (err) => {
          each(err, (v, k) => {
            setFieldError(k, v);
          });
        }
      );
    },
    validateOnChange,
  });

  const createModal = useCreateModal();

  const handleForgotPasswordEmailSent = useCallback(
    (email_address) => {
      createModal({
        content: (close) => (
          <ModalCard size="sm">
            <div className="p-6">
              <div className="space-y-2">
                <img
                  src={assets.logoDark}
                  alt="verified"
                  className="w-36 mb-8"
                />
                <div className="text-lg font-semibold">Check your email</div>
                <div className="font-light">
                  Please click the link that was sent to{' '}
                  <span className="font-semibold text-gray-600">
                    {email_address || 'you email'}
                  </span>{' '}
                  to continue resetting your password.
                </div>
              </div>
              <Button className="my-5 uppercase" default onClick={close}>
                Close
              </Button>
            </div>
          </ModalCard>
        ),
      });
    },
    [createModal]
  );

  const handleForgotPassword = useCallback(() => {
    showDrawer({
      title: 'Forgot Password',
      align: 'justify-center',
      size: 'sm',
      content: (close) => (
        <ForgotPasswordModal
          onClose={close}
          onSuccess={(email_address) => {
            close();
            handleForgotPasswordEmailSent(email_address);
          }}
        />
      ),
    });
  }, [handleForgotPasswordEmailSent]);

  return (
    <Form
      onSubmit={handleSubmit}
      error={errors}
      noValidate
      className="space-y-3"
    >
      <div>
        <FormInput
          icon={HiOutlineMail}
          name="email_address"
          onSetFieldValue={setFieldValue}
          value={values.email_address}
          label="Email Address"
          // placeholder="user@email.com"
          type="emai"
          error={errors.email_address}
          required
        />
        <ErrorLabel name="email_address" />
      </div>
      <div>
        <FormPassword
          name="password"
          label="Password"
          onSetFieldValue={setFieldValue}
          value={values.password}
          error={errors.password}
          noAutoFill
          required
        />
        <ErrorLabel name="password" />
      </div>
      <Button
        size="xl"
        primary
        type="submit"
        className="w-full"
        disabled={isLoading}
      >
        Login
      </Button>
      <div>
        <div className="mt-6 text-center">
          <button
            type="button"
            className="font-normal border-b border-dashed border-gray-400 text-gray-600"
            onClick={handleForgotPassword}
          >
            Forgot Password
          </button>
        </div>
        <div className="text-center space-x-1 mt-6">
          <span>Don&apos;t have an account?</span>
          <Link className="inline-block" to="/signup">
            Sign Up Now
          </Link>
        </div>
      </div>
    </Form>
  );
}

LoginForm.defaultProps = {
  onLogin: () => {},
};

LoginForm.propTypes = {
  onLogin: PropTypes.func,
};

export default LoginForm;
