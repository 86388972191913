import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const columnClassName = (count) => {
  switch (count) {
    case 1:
      return 'grid-cols-1';
    case 2:
      return 'grid-cols-2';
    case 3:
      return 'grid-cols-3';
    case 4:
      return 'grid-cols-4';
    case 5:
      return 'grid-cols-5';
    case 6:
      return 'grid-cols-6';
    default:
      return null;
  }
};

const colSpanClassName = (count) => {
  switch (count) {
    case 1:
      return 'col-span-1';
    case 2:
      return 'col-span-2';
    case 3:
      return 'col-span-3';
    case 4:
      return 'col-span-4';
    case 5:
      return 'col-span-5';
    case 6:
      return 'col-span-6';
    default:
      return null;
  }
};

function GenericDetails({ data, format, numberOfCols, isLoading }) {
  return (
    <div className={`grid gap-x-4 gap-y-4 ${columnClassName(numberOfCols)}`}>
      {format.map((item, i) => (
        <div
          key={typeof item.key === 'string' ? item?.key : i}
          className={`flex-1 flex flex-col gap-y-1 ${colSpanClassName(
            item.span
          )}`}
        >
          <label className="label">{item.label}</label>
          {isLoading ? (
            <div className="h-6 w-4/5 rounded-md bg-gray-200 animate-pulse" />
          ) : (
            <span className="flex-grow text-base text-gray-900 font-medium">
              {typeof item.key === 'string'
                ? get(data, item.key) || '--'
                : item.key(data) || '--'}
            </span>
          )}
        </div>
      ))}
    </div>
  );
}

GenericDetails.defaultProps = {
  numberOfCols: 3,
  isLoading: false,
};

GenericDetails.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  format: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      span: PropTypes.number,
    })
  ).isRequired,
  numberOfCols: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  isLoading: PropTypes.bool,
};

export default GenericDetails;
