import React from 'react';
import * as assets from 'assets/images';
import Button from 'partial/components/Button';
import history from 'setup/history';

function Banner() {
  return (
    // <div className="bg-gradient-to-br from-[#C6D6FE] to-[#EEFAFF]">
    <div>
      <div className="container mx-auto items-center h-[564px] md:h-[684px] relative pt-0 sm:pt-5">
        <div className="pt-16 sm:pt-60 pb-10 md:pb-48 relative z-10 text-center md:text-left max-w-2xl">
          <div
            className="text-4xl sm:text-7xl font-semibold mb-4 text-white"
            data-aos="fade-up"
            data-aos-offset="0"
          >
            Secured Government Payment Gateway
          </div>
          <div
            className="text-lg sm:text-2xl text-white"
            data-aos="fade-up"
            data-aos-offset="0"
            data-aos-delay="150"
          >
            Reach more filipino customers and grow your agency with our payment
            gateway
          </div>
        </div>
        <div className="absolute top-4 right-0 w-1/2 h-full hidden lg:block">
          <div className="absolute inset-0 flex items-center justify-end isolate">
            <img
              className="relative z-10 w-[63%]"
              src={assets.bannerMain}
              alt=""
              data-aos="zoom-in"
              data-aos-offset="0"
            />
            <img
              className="absolute top-[4%] right-0"
              src={assets.bannerCebuana}
              alt=""
              data-aos="fade-left"
              data-aos-offset="0"
            />
            <img
              className="absolute top-[4%] left-[36%]"
              src={assets.bannerVisa}
              alt=""
              data-aos="fade-right"
              data-aos-offset="0"
            />
            <img
              className="absolute top-[15%] left-[17%]"
              src={assets.bannerMaster}
              alt=""
              data-aos="fade-right"
              data-aos-offset="0"
              data-aos-delay="50"
            />
            <img
              className="absolute top-[27%] left-0"
              src={assets.bannerMaya}
              alt=""
              data-aos="fade-right"
              data-aos-offset="0"
              data-aos-delay="100"
            />
            <img
              className="absolute top-[39%] left-[22%]"
              src={assets.bannerGcash}
              alt=""
              data-aos="fade-right"
              data-aos-offset="0"
            />
            <img
              className="absolute top-[54%] left-[8%]"
              src={assets.bannerLandbank}
              alt=""
              data-aos="fade-right"
              data-aos-offset="0"
              data-aos-delay="100"
            />
            <img
              className="absolute top-[70%] left-[22%]"
              src={assets.bannerSeveneleven}
              alt=""
              data-aos="fade-right"
              data-aos-offset="0"
              data-aos-delay="50"
            />
            <img
              className="absolute top-[88%] left-[38%]"
              src={assets.bannerBdo}
              alt=""
              data-aos="fade-right"
              data-aos-offset="0"
              data-aos-delay="100"
            />
            <img
              className="absolute top-[79%] right-[2%]"
              src={assets.bannerUnionbank}
              alt=""
              data-aos="fade-left"
              data-aos-offset="0"
            />
          </div>
        </div>
        <div className="lg:hidden sm:hidden block mx-auto max-w-[362px]">
          <div className="flex items-end pt-20 justify-center isolate relative">
            <img
              className="relative z-10 h-[240px]"
              src={assets.bannerMain}
              alt=""
              data-aos="zoom-in"
              data-aos-offset="0"
            />
            <div className="absolute bottom-[270px] left-[50%] translate-x-[-50%]">
              <img
                className="h-16"
                src={assets.bannerVisa}
                alt=""
                data-aos="fade-up"
                data-aos-offset="0"
              />
            </div>
            <img
              className="h-16 absolute bottom-[230px] right-[13%]"
              src={assets.bannerCebuana}
              alt=""
              data-aos="fade-right"
              data-aos-offset="0"
              data-aos-delay="100"
            />
            <img
              className="h-16 absolute bottom-[230px] left-[13%]"
              src={assets.bannerMaster}
              alt=""
              data-aos="fade-left"
              data-aos-offset="0"
              data-aos-delay="100"
            />
            <img
              className="h-16 absolute bottom-[150px] right-[0%]"
              src={assets.bannerGcash}
              alt=""
              data-aos="fade-right"
              data-aos-offset="0"
              data-aos-delay="150"
            />
            <img
              className="h-16 absolute bottom-[150px] left-[0%]"
              src={assets.bannerMaya}
              alt=""
              data-aos="fade-left"
              data-aos-offset="0"
              data-aos-delay="150"
            />
            <img
              className="h-16 absolute bottom-[70px] right-[-6%]"
              src={assets.bannerLandbank}
              alt=""
              data-aos="fade-right"
              data-aos-offset="0"
              data-aos-delay="200"
            />
            <img
              className="h-16 absolute bottom-[70px] left-[-6%]"
              src={assets.bannerSeveneleven}
              alt=""
              data-aos="fade-left"
              data-aos-offset="0"
              data-aos-delay="200"
            />
            <img
              className="h-16 absolute bottom-[-10px] right-[5%]"
              src={assets.bannerBdo}
              alt=""
              data-aos="fade-right"
              data-aos-offset="0"
              data-aos-delay="150"
            />
            <img
              className="h-16 absolute bottom-[-10px] left-[5%]"
              src={assets.bannerUnionbank}
              alt=""
              data-aos="fade-left"
              data-aos-offset="0"
              data-aos-delay="150"
            />
          </div>
        </div>
        {/* <div className="sm:hidden block">
          <img className="w-full" src={assets.bannerMobile} alt="banner" />
        </div>
        <div className="absolute top-4 sm:top-16 right-0 w-1/2 sm:block hidden">
          <img src={assets.banner} alt="banner" />
        </div> */}
      </div>
      <div className="container mx-auto block md:hidden pb-12 space-y-4">
        <Button
          primary
          className="w-full"
          size="lg"
          onClick={() => history.push('/signup')}
        >
          Sign Up
        </Button>
        <Button
          className="w-full"
          size="lg"
          onClick={() => history.push('/signin')}
        >
          Login
        </Button>
      </div>
    </div>
  );
}

Banner.propTypes = {};

export default Banner;
