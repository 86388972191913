import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { formatNumber } from 'helper';
import uploadcare from 'uploadcare-widget';
import { HiPhotograph } from 'react-icons/hi';
import Button from 'partial/components/Button';
import ThumbIcon from 'partial/components/ThumbIcon';

function FormPhoto({
  className,
  imgClassName,
  label,
  name,
  onChange,
  onSetFieldValue,
  value,
  note,
  crop,
  resize,
  widget,
  disabled,
  readOnly,
}) {
  const inputRef = React.useRef();
  const [progress, setProgress] = React.useState(null);
  const upload = (files) => {
    uploadcare
      .openDialog(
        uploadcare.filesFrom('object', files, {
          publicKey: process.env.REACT_APP_UPLOADCARE_KEY || 'demopublickey',
          crop,
          resize,
          imagesOnly: true,
        }),
        {
          publicKey: process.env.REACT_APP_UPLOADCARE_KEY || 'demopublickey',
          tabs: 'file',
          crop,
          resize,
          imagesOnly: true,
        }
      )
      .done((file) => {
        setProgress(0);
        file
          .progress((e) => {
            setProgress(e.progress);
          })
          .done(({ cdnUrl }) => {
            const resizeConfig = resize ? `-/resize/${resize}/` : '';
            if (typeof onChange === 'function') {
              onChange((state) => ({
                ...state,
                [name]: `${cdnUrl}${resizeConfig}`,
              }));
            }
            if (typeof onSetFieldValue === 'function') {
              onSetFieldValue(name, `${cdnUrl}${resizeConfig}`);
            }
            setProgress(null);
          });
      })
      .fail(() => {
        setProgress(null);
      });
  };
  const handleChange = (e) => {
    upload(e.target.files);
  };
  const handleOpen = () => {
    if (widget) {
      uploadcare
        .openDialog(null, {
          publicKey: process.env.REACT_APP_UPLOADCARE_KEY || 'demopublickey',
          crop,
          resize,
          tabs: ['file', 'url', 'camera'],
          imagesOnly: true,
        })
        .done((file) => {
          file.done(({ cdnUrl }) => {
            const resizeConfig = resize ? `-/resize/${resize}/` : '';
            if (typeof onChange === 'function') {
              onChange((state) => ({
                ...state,
                [name]: `${cdnUrl}${resizeConfig}`,
              }));
            }
            if (typeof onSetFieldValue === 'function') {
              onSetFieldValue(name, `${cdnUrl}${resizeConfig}`);
            }
            setProgress(null);
          });
        })
        .fail(() => {
          setProgress(null);
        });
      return;
    }
    inputRef.current.click();
  };
  return (
    <div className=" flex flex-col h-full">
      <label
        htmlFor="photo"
        className="text-sm font-medium text-gray-700 flex justify-center"
      >
        <span>{label}</span>
        {note && <span className="ml-1 font-light">{note}</span>}
      </label>
      <div className="mt-1 flex flex-col justify-center space-y-2 h-full">
        <span className={cn('overflow-hidden bg-gray-50 m-auto', className)}>
          <ThumbIcon
            className="h-full w-full"
            imgClassName={imgClassName}
            iconClassName="w-6 h-6"
            url={value}
            icon={HiPhotograph}
            alt=""
          />
          <input
            ref={inputRef}
            type="file"
            onChange={handleChange}
            className="opacity-0 absolute h-0 w-0 pointer-events-none"
            value=""
          />
        </span>
        {!readOnly && (
          <>
            {typeof progress === 'number' ? (
              <div className="relative pt-2 mx-4">
                <div
                  className="transition-transform ease-out duration-500 origin-left w-full bg-gradient-to-l from-primary-300 to-primary-600 shadow-primary-lg h-1 rounded-full absolute left-0 top-0 z-10"
                  style={{ transform: `scaleX(${progress + 0.09})` }}
                />
                <div className="w-full bg-gray-50 h-1 rounded-full absolute left-0 top-0" />
                <div className="text-gray-500 text-xs flex items-center justify-between space-x-2">
                  <span className="truncate">&nbsp;</span>
                  <span className="font-bold">
                    {formatNumber((progress + 0.09) * 100, 1)}%
                  </span>
                </div>
              </div>
            ) : (
              <Button
                className="mx-auto"
                size="sm"
                onClick={handleOpen}
                disabled={disabled}
              >
                {value ? 'Change' : 'Upload'}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

FormPhoto.defaultProps = {
  className: 'h-12 w-12 rounded-lg',
  value: null,
  note: '',
  crop: false,
  resize: false,
  imgClassName: 'object-cover',
  onChange: false,
  onSetFieldValue: false,
  widget: false,
  disabled: false,
  readOnly: false,
};

FormPhoto.propTypes = {
  widget: PropTypes.bool,
  note: PropTypes.string,
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  crop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resize: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default FormPhoto;
