import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as assets from 'assets/images';
import { NavLink } from 'react-router-dom';

const Group = ({ icon, label }) => {
  return (
    <div className="flex">
      <div className="flex-shrink-0 pr-2">{icon}</div>
      <div className="flex-1 min-w-0">
        <div className="font-semibold text-sm leading-5 text-slate-400 whitespace-pre-wrap">
          {label}
        </div>
      </div>
    </div>
  );
};

Group.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

function Footer() {
  return (
    <div className="bg-slate-900">
      <div className="container mx-auto px-8 py-10 md:py-16 text-white text-sm relative">
        <div className="flex flex-col md:flex-row flex-wrap space-y-6 md:space-y-0">
          <div className="mr-8 md:mr-20 absolute md:relative right-0">
            <img className="w-40" src={assets.coatOfArms} alt="Coat of Arms" />
          </div>
          <div className="mr-auto">
            <img
              className="h-8 mb-2 md:mb-6"
              src={assets.logoLight}
              alt="Coat of Arms"
            />
            <div>Copyright © 2024 eGovPay</div>
            <div>All Rights Reserved</div>
          </div>
          <div className="flex flex-col space-y-4 mr-auto">
            <div className="uppercase font-bold">About Us</div>
            <NavLink
              className={cn('text-white/75 hover:text-white transition')}
              to="/terms-and-conditions"
            >
              Terms & Conditions
            </NavLink>
            <NavLink
              className={cn('text-white/75 hover:text-white transition')}
              to="/privacy-policy"
            >
              Privacy Policy
            </NavLink>
            {/* <NavLink
              className={cn('text-white/75 hover:text-white transition')}
              to="/"
            >
              FAQs
            </NavLink> */}
          </div>
          <div /> {/* social links here */}
          <div className="flex space-x-0 sm:space-x-10 space-y-8 sm:space-y-0 flex-col sm:flex-row">
            <div>
              <div className="text-sm font-semibold mb-2">DEVELOPED BY</div>
              <img className="h-16" src={assets.make} alt="" />
            </div>
            <div>
              <div className="text-sm font-semibold mb-2">
                IN COLLABORATION WITH
              </div>
              <div className="flex items-center space-x-2">
                <img className="h-16 w-16" src={assets.dof} alt="" />
                <img className="h-16 w-16" src={assets.btr} alt="" />
                <img className="h-16 w-16" src={assets.blgf} alt="" />
                <img className="h-16 w-16" src={assets.lbp} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Footer.propTypes = {};

export default Footer;
