/* eslint-disable */
import React, {
  useMemo,
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { HiPhotograph } from 'react-icons/hi';
import { toastError } from 'react-geek-toast';

import {
  useGeneralInfo,
  useFormAttachments,
  useEReceipt,
} from 'core-modules/merchant-onboarding/hooks';
import countries from 'partial/form/countries.json';
import { showAlert } from 'partial/components/Modal';
import ThumbIcon from 'partial/components/ThumbIcon';
import { useCurrentAccount } from 'modules/auth/hooks';
import GenericDetails from 'partial/components/GenericDetails';
import { imagePreview } from 'core-modules/merchants/constants';
import MerchantAttachments from 'core-modules/merchants/components/MerchantAttachments';

const countryOptions = countries.map((item) => ({
  label: item.name,
  value: item.iso2,
}));

const KYC_DATA_FORMAT = [
  {
    label: 'Photo',
    key: (data) => (
      <ThumbIcon
        className="w-32 h-32"
        imgClassName="object-cover object-center rounded-full"
        iconClassName="w-6 h-6"
        url={data?.owner_photo_url}
        icon={HiPhotograph}
        alt=""
      />
    ),
  },
  {
    label: 'First Name',
    key: 'owner_first_name',
  },
  {
    label: 'Middle Name',
    key: 'owner_middle_name',
  },
  {
    label: 'Last Name',
    key: 'owner_last_name',
  },
  {
    label: 'Suffix',
    key: 'owner_suffix',
  },
  {
    label: 'Birth Date',
    key: 'owner_birth_date',
  },
  {
    label: 'ID Type',
    key: 'owner_valid_id_type',
  },
  imagePreview({
    label: 'ID Photo',
    key: 'owner_valid_id_url',
  }),
  {
    label: 'Logo',
    key: (data) => (
      <ThumbIcon
        className="w-32 h-32"
        imgClassName="object-cover object-center"
        iconClassName="w-6 h-6"
        url={data?.logo}
        icon={HiPhotograph}
        alt=""
        square
      />
    ),
  },
  {
    label: 'Type',
    key: (data) => (
      <span className="capitalize">
        {data?.business_type?.replace(/_/g, ' ')?.toLowerCase() ?? '--'}
      </span>
    ),
  },
  {
    label: 'Project Name',
    key: 'name',
  },
  {
    label: 'Customer Service Number',
    key: (data) => (
      <span className="break-all">{data?.customer_service_number}</span>
    ),
  },
  {
    label: 'Sector',
    key: 'industry',
  },
  {
    label: 'Address',
    key: (data) =>
      data?.business_country === 'PH'
        ? `Philippines, ${data?.business_state}, ${data?.business_city}, ${data?.business_zip_code}, ${data?.business_address}`
        : `${
            countryOptions?.find(
              (country) => country?.value === data?.business_country
            )?.label
          }, ${data?.business_address}`,
  },
  {
    label: 'Description',
    key: 'business_description',
  },
  {
    label: 'Code',
    key: 'ereceipt_code',
  },
  {
    label: 'Token',
    key: 'ereceipt_token',
  },
];

export const ReviewForm = forwardRef(
  ({ noProfile, noBank, onSubmit, onSuccess }, ref) => {
    const [, orgProfileData] = useCurrentAccount();
    const [, orgMerchantGeneralInfo] = useGeneralInfo();
    const [, merchantEReceipt] = useEReceipt();
    const [orgMerchantAttachment, setOrgMerchantAttachment] = useState({});

    const onboardingKycData = useMemo(
      () => ({
        ...orgProfileData,
        ...orgMerchantGeneralInfo,
        ...orgMerchantAttachment,
        ...merchantEReceipt,
      }),
      [orgProfileData, orgMerchantGeneralInfo, orgMerchantAttachment]
    );

    const showConfirmationAlert = useCallback(() => {
      showAlert({
        title: 'You are about to submit your application.',
        subTitle:
          'Once you submit your application, you will no longer be able to edit your entered details. Do you want to proceed?',
        yesLabel: 'Yes',
        noLabel: 'Cancel',
        onNo: (closeAlert) => closeAlert(),
        onYes: (closeAlert) => {
          closeAlert();
          onSubmit({}, onSuccess, () => toastError('Something went wrong.'));
        },
        color: 'primary',
      });
    }, [onSubmit, onSuccess]);

    useFormAttachments(setOrgMerchantAttachment);

    useImperativeHandle(
      ref,
      () => ({
        submit: showConfirmationAlert,
      }),
      [showConfirmationAlert]
    );

    return (
      <div>
        {!noProfile && (
          <>
            <div className="relative mt-8 mb-5">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex">
                <span className="pr-2 bg-white text-sm font-bold text-primary-500 py-1 leading-none">
                  Representative Details
                </span>
              </div>
            </div>
            <div className="space-y-2">
              <GenericDetails
                data={onboardingKycData || {}}
                format={KYC_DATA_FORMAT.slice(0, 1)}
                numberOfCols={1}
              />
              <GenericDetails
                data={onboardingKycData || {}}
                format={KYC_DATA_FORMAT.slice(1, 8)}
                numberOfCols={3}
              />
            </div>
          </>
        )}
        <div className="relative mt-8 mb-5">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex">
            <span className="pr-2 bg-white text-sm font-bold text-primary-500 py-1 leading-none">
              Project Information
            </span>
          </div>
        </div>
        <div className="space-y-2">
          <GenericDetails
            data={onboardingKycData || {}}
            format={KYC_DATA_FORMAT.slice(8, 10)}
            numberOfCols={3}
          />
          <GenericDetails
            data={onboardingKycData || {}}
            format={KYC_DATA_FORMAT.slice(10, 15)}
            numberOfCols={3}
          />
        </div>
        <div className="relative mt-8 mb-5">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex">
            <span className="pr-2 bg-white text-sm font-bold text-primary-500 py-1 leading-none">
              Requirements{' '}
              {onboardingKycData?.business_type &&
                `(${onboardingKycData?.business_type?.replace(/_/g, ' ')})`}
            </span>
          </div>
        </div>
        <MerchantAttachments />
        <div className="relative mt-8 mb-5">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex">
            <span className="pr-2 bg-white text-sm font-bold text-primary-500 py-1 leading-none">
              eReceipt
            </span>
          </div>
        </div>
        <div className="space-y-2">
          <GenericDetails
            data={onboardingKycData || {}}
            format={KYC_DATA_FORMAT.slice(15)}
            numberOfCols={2}
          />
        </div>
      </div>
    );
  }
);

ReviewForm.defaultProps = {
  noProfile: false,
  noBank: false,
};

ReviewForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  noProfile: PropTypes.bool,
  noBank: PropTypes.bool,
};

export default ReviewForm;
