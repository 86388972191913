/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { uploadPhotosAndPdfs } from 'partial/form/FormPhotoDnd';
import { HiCheckCircle } from 'react-icons/hi';
import { RiUploadCloud2Line } from 'react-icons/ri';

function RequirementItem({
  name,
  onChange,
  value,
  label,
  isSelected,
  onSelect,
  readOnly,
}) {
  const inputFileRef = useRef();

  const [isUploading, setIsUploading] = useState(false);
  const handleAdd = async ({ target }) => {
    onSelect();
    setIsUploading(true);
    const filteredFiles = [];
    for (let i = 0; i < target.files.length; i += 1) {
      const file = target.files[i];
      const type = file.type || '';
      if (type.indexOf('image/') > -1 || type.indexOf('application/pdf') > -1) {
        filteredFiles.push(file);
      }
    }
    const prevValue = (value || '').split(',').filter((x) => !isEmpty(x));
    onChange((state) => ({
      ...state,
      [name]: `${prevValue
        .concat(filteredFiles)
        .map(() => 'uploading')
        .join(',')}`,
    }));
    const files = await uploadPhotosAndPdfs(filteredFiles);
    inputFileRef.current.value = '';
    setIsUploading(false);
    onChange((state) => ({
      ...state,
      [name]: `${prevValue.concat(files).join(',')}`,
    }));
  };
  const isUploadingAll = isUploading || (value || '').indexOf('uploading') > -1;
  const itemsCount = (value || '').split(',').filter((x) => !isEmpty(x)).length;
  return (
    <div
      className={cn(
        'relative flex justify-between p-2 space-x-3 transition items-center cursor-pointer',
        isSelected
          ? 'bg-white hover:bg-white -ml-px'
          : 'bg-gray-100 hover:bg-gray-200'
      )}
    >
      <button
        className="absolute inset-0 w-full h-full"
        onClick={onSelect}
        type="button"
      />
      <div className="w-full text-xs font-medium text-primary-500 flex justify-between">
        {label}
      </div>
      <div className="flex-shrink-0 flex items-center space-x-1">
        {!isEmpty(value) && !isUploadingAll ? (
          <HiCheckCircle className="w-6 h-6 flex-shrink-0 text-green-500" />
        ) : null}
        {readOnly ? (
          <div className="text-xs text-gray-500">
            {itemsCount ? <span>{itemsCount} item(s)</span> : null}
          </div>
        ) : (
          <label className="relative z-10 bg-white border rounded px-2 py-1 hover:bg-gray-50 cursor-pointer flex items-center">
            {isUploadingAll ? (
              <div className="inline-block mr-1">
                <LoaderIcon />
              </div>
            ) : (
              <RiUploadCloud2Line className="inline-block mr-1" />
            )}
            <span className="text-xs font-medium text-gray-700">
              Attach File
            </span>
            <input
              ref={inputFileRef}
              className="absolute h-0 w-0 opacity-0"
              type="file"
              onChange={handleAdd}
              accept="image/*, application/pdf"
              multiple
            />
          </label>
        )}
      </div>
    </div>
  );
}

RequirementItem.defaultProps = {
  isSelected: false,
  onSelect: () => {},
  readOnly: false,
};

RequirementItem.propTypes = {
  readOnly: PropTypes.bool,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function LoaderIcon() {
  return (
    <svg
      className="animate-spin h-4 w-4 text-primary-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx={12}
        cy={12}
        r={10}
        stroke="currentColor"
        strokeWidth={4}
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
}

export default RequirementItem;
