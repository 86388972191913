import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { useInputId } from './hooks';

const FormInput = React.forwardRef(
  (
    {
      id: defaultId,
      icon: Icon,
      label,
      name,
      onChange,
      onSetFieldValue,
      value,
      error,
      required,
      disabled,
      readOnly,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    const [id] = useInputId(defaultId);
    const handleChange = ({ target }) => {
      if (typeof onChange === 'function') {
        onChange((state) => ({
          ...state,
          [name]: target.value,
        }));
      }
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, target.value);
      }
    };
    const showIcon = Icon || error;
    const isError = !!error;
    return (
      <div
        className={cn(
          'relative border rounded-md px-3 py-2 focus-within:ring-1 z-[1]',
          wrapperClassName,
          isError
            ? 'border-red-300 focus-within:ring-red-500 focus-within:border-red-500 animate-wiggle'
            : 'border-gray-200 focus-within:ring-primary-600 focus-within:border-primary-600',
          disabled || readOnly ? 'bg-gray-100' : 'bg-white'
        )}
      >
        <label
          htmlFor={id}
          className="w-full text-xs font-medium text-primary-500 flex justify-between relative"
        >
          <span>{label}</span>
          {!required && (
            <span className="absolute right-0 tracking-wider px-2 text-[8px] py-0.5 text-gray-500 rounded-full bg-gray-100 uppercase ml-1">
              optional
            </span>
          )}
        </label>
        <input
          id={id}
          ref={ref}
          type="text"
          name="name"
          className={cn(
            'block w-full border-0 p-0 placeholder-gray-500 focus:ring-0 text-base bg-white text-gray-900',
            'focus:outline-none disabled:bg-gray-100 disabled:text-gray-500 read-only:bg-gray-100',
            '',
            showIcon ? 'pr-10' : ''
          )}
          onChange={handleChange}
          value={value || ''}
          disabled={disabled}
          readOnly={readOnly}
          {...props}
        />
        {showIcon && (
          <div
            className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            title={error || ''}
          >
            {error ? (
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            ) : (
              <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </div>
        )}
      </div>
    );
  }
);

FormInput.defaultProps = {
  id: '',
  icon: false,
  type: 'text',
  error: null,
  readOnly: false,
  disabled: false,
  required: false,
  onChange: false,
  onSetFieldValue: false,
  wrapperClassName: '',
};

FormInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  wrapperClassName: PropTypes.string,
};

export default React.memo(FormInput);
