import React from 'react';
import { HiArrowRight } from 'react-icons/hi';

import Button from 'partial/components/Button';
import { useSwitchProfileModal } from 'modules/auth/modals/SwitchProfileModal';

function TestModaCard() {
  const [promptSwitchProfile] = useSwitchProfileModal();
  return (
    <div className="relative group">
      <div className="absolute -inset-1 bg-gradient-to-r from-primary-500 via-yellow-500 to-secondary-500 rounded-xl opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt" />
      <div className="relative p-5 bg-white rounded-lg flex items-center divide-x divide-slate-300 my-1">
        <div className="flex items-center space-x-5 w-full">
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 space-x-0 sm:space-x-4 sm:divide-x divide-x-0 sm:items-center items-start">
            <div>
              <div className="text-slate-900 text-lg font-bold">
                Try Test Environment
              </div>
              <div className="text-slate-700">
                Try out our API right away and apply our solution. While waiting
                the approval, you may use our test environment.
              </div>
            </div>
            <span className="flex-shrink-0 group-hover:text-gray-100 transition duration-200 sm:pl-4 pl-0">
              <Button secondary onClick={promptSwitchProfile}>
                <div className="flex space-x-1 items-center">
                  <span>Test Mode</span>
                  <HiArrowRight className="w-4 h-4 flex-shrink-0" />
                </div>
              </Button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestModaCard;
