import React from 'react';
import { BiHome } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import Button from 'partial/components/Button';
import Privacy from 'modules/auth/components/Privacy';
import { logoDark } from 'assets/images';

function PrivacyContainer() {
  const history = useHistory();

  return (
    <div className="relative">
      <div className="absolute flex items-center justify-between w-full px-5 py-4 z-[1] border-b shadow">
        <div>
          <Button primary icon={BiHome} onClick={() => history.push('/')}>
            Back to login page
          </Button>
        </div>
        <img className="h-8" src={logoDark} alt="logo" />
      </div>
      <div className="mx-5 mt-28 mb-10">
        <Privacy />
      </div>
    </div>
  );
}

export default PrivacyContainer;
