import React from 'react';
import * as assets from 'assets/images';
import { useHistory } from 'react-router-dom';
import Button from 'partial/components/Button';

function GetStartedBanner() {
  const history = useHistory();
  return (
    <div className="container mx-auto mb-0 sm:mb-32">
      <div className="bg-primary-500 -mx-4 md:mx-0 rounded-none md:rounded-3xl px-8 md:px-16 py-10 relative flex items-center">
        <div className="w-full max-w-[100%] md:max-w-[50%]">
          <div
            className="font-semibold text-white text-2xl sm:text-4xl mb-4"
            data-aos="fade-up"
            data-aos-delay="0"
          >
            Ready to start accepting payments from your Filipino customers?
          </div>
          <div
            className="text-white text-base mb-6"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Sign up for eGovPay today and start accepting payments online with
            ease and security. Our payment gateway is the easiest and most
            secure way to accept payments online in the Philippines.
          </div>
          <Button
            className="px-8"
            size="lg"
            dark
            onClick={() => history.push('/signup')}
            data-aos="fade-in"
            data-aos-delay="0"
          >
            Sign Up Now
          </Button>
        </div>
        <div className="ml-auto hidden md:block">
          <img
            className="h-80 w-80"
            src={assets.logoXl}
            alt="eGovPay"
            data-aos="zoom-in"
            data-aos-delay="0"
          />
        </div>
      </div>
    </div>
  );
}

GetStartedBanner.propTypes = {};

export default GetStartedBanner;
