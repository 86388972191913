import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
// import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import { useOnClickOutside } from 'helper';
import { BiCalendarAlt } from 'react-icons/bi';
import { Popover, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { useInputId } from './hooks';

const formatDate = (value, defaultFormat = 'YYYY-MM-DD') => {
  const date = dayjs(new Date(value));
  return date.isValid() ? date.format(defaultFormat) : value;
};

const parseValue = (value, defaultValue = null) => {
  const date = dayjs(new Date(value));
  return date.isValid() ? date.toDate() : defaultValue;
};

const parseInit = (value, defaultValue = '') => {
  const date = dayjs(new Date(value));
  return date.isValid() ? date.format('MM/DD/YYYY') : defaultValue;
};

// const isDateValid = (value, orEmpty = false) => {
//   if ((isEmpty(value) || value === '__/__/____') && orEmpty) return true;
//   const date = dayjs(new Date(value));
//   return date.isValid();
// };

function FormDate({
  id: defaultId,
  icon: Icon,
  label,
  name,
  onChange,
  onSetFieldValue,
  value,
  error,
  required,
  panelClassName,
  maxDate,
  minDate,
  disabled,
  readOnly,
  hideYearDropdown,
  hideMonthDropdown,
  ...props
}) {
  const [val, setVal] = React.useState(parseInit(value));
  const [id] = useInputId(defaultId);
  const handleChange = ({ target }) => {
    setVal(target.value);

    if (target.value === '__/__/____') {
      if (typeof onChange === 'function') {
        onChange((state) => ({
          ...state,
          [name]: '',
        }));
      }
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, '');
      }
      return;
    }
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: formatDate(target.value),
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, formatDate(target.value));
    }
    // this allow yup to handle the validation
    // if (isDateValid(target.value)) {
    //   if (typeof onChange === 'function') {
    //     onChange((state) => ({
    //       ...state,
    //       [name]: formatDate(target.value),
    //     }));
    //   }
    //   if (typeof onSetFieldValue === 'function') {
    //     onSetFieldValue(name, formatDate(target.value));
    //   }
    // }
  };
  const showIcon = Icon || error;
  const isError = !!error;
  const [showPicker, setShowPicker] = React.useState(false);
  const myRef = React.useRef();
  useOnClickOutside(myRef, setShowPicker);
  const handleToggle = () => {
    setShowPicker(true);
  };
  const defaultDate = React.useMemo(() => parseValue(value), [value]);
  const handleSetDate = (v) => {
    const newDate = dayjs(v);
    if (typeof onChange === 'function') {
      onChange((state) => ({
        ...state,
        [name]: newDate.format('YYYY-MM-DD'),
      }));
    }
    if (typeof onSetFieldValue === 'function') {
      onSetFieldValue(name, newDate.format('YYYY-MM-DD'));
    }
    setVal(newDate.format('MM/DD/YYYY'));
    setShowPicker(false);
  };
  return (
    <Popover className="relative">
      <div
        ref={myRef}
        className={cn(
          'relative border rounded-md px-3 py-2 focus-within:ring-1 z-10',
          isError
            ? 'border-red-300 focus-within:ring-red-500 focus-within:border-red-500 animate-wiggle'
            : 'border-gray-200 focus-within:ring-primary-600 focus-within:border-primary-600',

          disabled || readOnly ? 'bg-gray-100' : 'bg-white'
        )}
      >
        <label
          htmlFor={id}
          className="w-full text-xs font-medium text-primary-500 flex justify-between"
        >
          <span className="space-x-2">
            <span>{label}</span>
            <span className="ml-1 text-gray-500 font-light">MM/DD/YYYY</span>
          </span>
          {!required && (
            <span className="tracking-wider px-2 text-[8px] py-0.5 text-gray-500 rounded-full bg-gray-100 uppercase ml-1">
              optional
            </span>
          )}
        </label>
        <InputMask
          mask="99/99/9999"
          id={id}
          name="name"
          className={cn(
            'block w-full border-0 p-0 placeholder-gray-500 focus:ring-0 text-base',
            'focus:outline-none bg-transparent disabled:bg-gray-100 disabled:text-gray-500 read-only:bg-gray-100',
            showIcon ? 'pr-10' : ''
          )}
          onChange={handleChange}
          value={val}
          disabled={disabled}
          readOnly={readOnly}
          {...props}
        />
        {showIcon && (
          <div
            className="absolute inset-y-0 right-0 pr-3 flex items-center z-10"
            title={error || ''}
          >
            {error ? (
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            ) : (
              <button
                className="border-none bg-transparent text-gray-400 focus:outline-none focus:text-primary-500"
                type="button"
                onClick={handleToggle}
                disabled={disabled}
              >
                <Icon className="h-5 w-5 mt-3" aria-hidden="true" />
              </button>
            )}
          </div>
        )}
        <Transition
          show={showPicker}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as={Fragment}
        >
          <Popover.Panel
            static
            className={cn('absolute z-[60]', panelClassName)}
          >
            <div className="border rounded-lg overflow-hidden shadow-lg">
              <DatePicker
                selected={defaultDate}
                onChange={handleSetDate}
                inline
                disabledKeyboardNavigation
                showYearDropdown={!hideYearDropdown}
                showMonthDropdown={!hideMonthDropdown}
                maxDate={maxDate}
                minDate={minDate}
              />
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
}

FormDate.defaultProps = {
  id: '',
  icon: BiCalendarAlt,
  error: null,
  required: false,
  panelClassName: 'right-0',
  onChange: false,
  onSetFieldValue: false,
  maxDate: null,
  minDate: null,
  hideYearDropdown: false,
  hideMonthDropdown: false,
  disabled: false,
  readOnly: false,
};

FormDate.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  panelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
  ]),
  hideYearDropdown: PropTypes.bool,
  hideMonthDropdown: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default React.memo(FormDate);
