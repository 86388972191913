import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

import { useInputId } from './hooks';

const FormPassword = React.forwardRef(
  (
    {
      id: defaultId,
      label,
      name,
      onChange,
      onSetFieldValue,
      value,
      error,
      required,
      noAutoFill,
      ...props
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const [id] = useInputId(defaultId);
    const handleChange = ({ target }) => {
      if (typeof onChange === 'function') {
        onChange((state) => ({
          ...state,
          [name]: target.value,
        }));
      }
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, target.value);
      }
    };

    return (
      <div
        className={cn(
          'relative border rounded-md bg-white px-3 py-2 focus-within:ring-1 z-[1]',
          error
            ? 'border-red-300 focus-within:ring-red-500 focus-within:border-red-500 animate-wiggle'
            : 'border-gray-200 focus-within:ring-primary-600 focus-within:border-primary-600'
        )}
      >
        <label
          htmlFor={id}
          className="w-full text-xs font-medium text-primary-500 flex justify-between"
        >
          <span>{label}</span>
          {!required && (
            <span className="tracking-wider px-2 text-[8px] py-0.5 text-gray-500 rounded-full bg-gray-100 uppercase ml-1">
              optional
            </span>
          )}
        </label>
        <input
          id={id}
          ref={ref}
          name="name"
          className={cn(
            'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-base',
            'focus:outline-none'
          )}
          onChange={handleChange}
          value={value || ''}
          {...props}
          type={showPassword ? 'text' : 'password'}
          autoComplete={noAutoFill ? 'new-password' : 'on'}
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 pr-3 flex items-center focus:outline-none"
          onClick={() => setShowPassword((val) => !val)}
          tabIndex="-1"
        >
          {!error ? (
            <>
              {showPassword ? (
                <HiOutlineEye className="h-5 w-5 text-gray-400" />
              ) : (
                <HiOutlineEyeOff className="h-5 w-5 text-gray-400" />
              )}
            </>
          ) : (
            <>
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </>
          )}
        </button>
      </div>
    );
  }
);

FormPassword.defaultProps = {
  id: '',
  type: 'text',
  error: null,
  required: false,
  onChange: false,
  onSetFieldValue: false,
  noAutoFill: false,
};

FormPassword.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  noAutoFill: PropTypes.bool,
};

export default React.memo(FormPassword);
