import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const COLORS = [
  '#999',
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
];

/* eslint-disable */
const hash = (s) => {
  return (s || '').split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};
/* eslint-enable */

const parseInitials = (str) => {
  const arr = (str || '').trim().split(' ');
  if (!str) return '';
  if (arr.length < 2) return `${str[0] || ''}${str[1] || ''}`.toUpperCase();
  return `${arr[0][0]}${arr[1][0]}`.toUpperCase();
};

function ThumbIcon({
  url,
  icon: Icon,
  iconClassName,
  alt,
  className,
  imgClassName,
  square,
}) {
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const backgroundColor = COLORS[Math.abs(hash(alt) % COLORS.length)];
  React.useEffect(() => {
    if (!url) setImageLoaded(false);
  }, [url, setImageLoaded]);
  return (
    <div
      className={cn(
        'flex justify-center flex-none items-center text-white text-center leading-6 relative overflow-hidden bg-white shadow-sm',
        square ? 'rounded-md' : 'rounded-full',
        className
      )}
      style={{ backgroundColor: !url ? backgroundColor : null }}
    >
      {!url ? (
        <span className="font-semibold">
          {Icon ? <Icon className={iconClassName} /> : parseInitials(alt)}
        </span>
      ) : null}
      <img
        className={cn('absolute h-full w-full', imgClassName, {
          'opacity-0': !imageLoaded,
        })}
        src={url}
        onLoad={() => setImageLoaded(true)}
        onError={() => setImageLoaded(false)}
        alt={alt}
      />
    </div>
  );
}

ThumbIcon.defaultProps = {
  url: null,
  className: '',
  alt: '',
  icon: null,
  iconClassName: 'h-4 w-4',
  imgClassName: 'object-cover object-center',
  square: false,
};

ThumbIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object)]),
  iconClassName: PropTypes.string,
  imgClassName: PropTypes.string,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  alt: PropTypes.string,
  className: PropTypes.string,
  square: PropTypes.bool,
};

export default React.memo(ThumbIcon);
