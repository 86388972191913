import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SignUp from 'modules/auth/containers/SignUp';
import SignIn from 'modules/auth/containers/SignIn';
import Landing from 'partial/landing/Landing';
import ComingSoon from 'partial/containers/ComingSoon';
import PageNotFound from 'partial/containers/PageNotFound';
import PrivacyContainer from 'documentation/PrivacyContainer';
import SetPassword from 'modules/auth/containers/SetPassword';

function Public() {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/signup" component={SignUp} />
      <Route
        path={['/forgot_password', '/set_password']}
        component={SetPassword}
      />
      <Route path="/privacy-policy" component={PrivacyContainer} />
      <Route path="/terms-and-conditions" component={ComingSoon} />
      <Route path="/logout">
        <Redirect to="/" />
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  );
}

Public.propTypes = {};

export default Public;
