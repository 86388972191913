import React, { useState } from 'react';
import cn from 'classnames';
import * as assets from 'assets/images';
import Button from 'partial/components/Button';
import { useHistory } from 'react-router-dom';
import { HiMenuAlt1 } from 'react-icons/hi';

function Header() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="h-20 flex items-center container mx-auto z-20 fixed inset-x-0 top-6">
        <div className="w-full flex items-center sm:justify-between justify-center rounded-2xl backdrop-blur-lg bg-slate-900/90 p-6 shadow-xl">
          <div
            // className="block md:hidden"
            className="hidden"
          >
            <button
              className="p-2 text-white"
              type="button"
              onClick={() => {
                setShow(!show);
              }}
            >
              <HiMenuAlt1 className="h-6 w-6" />
            </button>
          </div>
          <div className="flex-shrink-0 mx-auto sm:mx-0">
            <img className="h-8 sm:ml-4" src={assets.logoLight} alt="eGovPay" />
          </div>

          <div
            className={cn(
              'items-start md:items-center sm:space-x-5 space-x-0 px-4',
              show
                ? 'absolute bg-slate-900 rounded-b-2xl top-[68px] border-t border-slate-800 inset-x-0 flex-col pb-4'
                : 'hidden md:flex'
            )}
          >
            <ul className="flex flex-col md:flex-row md:space-x-10 font-bold text-sm w-full md:w-auto">
              {/* <li className="p-4 md:p-0">Overview</li>
              <li className="p-4 md:p-0">Partners</li> */}
              <li className="py-4 sm:p-0 text-center sm:text-left">
                <a
                  className="text-base px-4 text-white/90 hover:text-white transition"
                  href="/signin"
                >
                  Login
                </a>
              </li>
            </ul>
            <Button
              primary
              className="w-full sm:w-auto"
              size="lg"
              onClick={() => history.push('/signup')}
            >
              Sign Up
            </Button>
          </div>
          {/* <div className="w-full bg-gradient-to-l from-secondary-500 to-primary-500 h-1" /> */}
        </div>
      </div>
    </>
  );
}

Header.propTypes = {};

export default Header;
