import React from 'react';
// import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from 'partial/components/Button';
import * as assets from 'assets/images';

function ComingSoon() {
  const history = useHistory();
  return (
    <div className="h-screen pt-16 pb-12 flex flex-col bg-gray-50">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">eGovPay</span>
            <img className="h-16 w-auto" src={assets.logoIcon} alt="" />
          </a>
        </div>
        <div className="pb-16 pt-4">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Coming Soon
            </h1>
            <div className="mt-6 text-center">
              <Button
                className="mx-auto"
                default
                onClick={() => history.push('/')}
              >
                Go back to website<span aria-hidden="true"> &rarr;</span>
              </Button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

ComingSoon.propTypes = {};

export default ComingSoon;
