import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Button from 'partial/components/Button';
import Form from 'partial/form/Form';
import FormInput from 'partial/form/FormInput';
import FormPassword from 'partial/form/FormPassword';
import { toastError } from 'react-geek-toast';
import logo from './logo.png';

const ERECEIPT_WEBSITE_URL =
  process.env.REACT_APP_ERECEIPT_WEBSITE_URL ||
  'https://stg-ereceipt.oueg.info/';
const ERECEIPT_ROOT_WS =
  process.env.REACT_APP_ERECEIPT_ROOT_WS ||
  'https://stg-ereceipt-ws.oueg.info/';
const SECRET_CODE = 'EP';

export function connectWithEReceipt() {
  return new Promise((resolve) => {
    ReactDOM.render(
      <ConnectWidget
        onCallback={resolve}
        onClose={() => {
          ReactDOM.render(null, document.getElementById('e-receipt-widget'));
        }}
      />,
      document.getElementById('e-receipt-widget')
    );
  });
}

function ConnectWidget({ onCallback, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpSent, setOtpSent] = useState(false);
  const [form, setForm] = useState({
    email: '',
    password: '',
    pin: '',
  });

  return (
    <div className="fixed inset-0 z-50 bg-black/20 flex">
      <Form
        className="m-auto bg-white border rounded-lg p-6 space-y-4 w-full max-w-md"
        onSubmit={async () => {
          setIsLoading(true);

          if (isOtpSent) {
            const raw2 = await fetch(
              `${ERECEIPT_ROOT_WS}api/public/auth/authenticate`,
              {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                  'X-Secret-Code': SECRET_CODE,
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              }
            );
            setIsLoading(false);
            const res2 = await raw2.json();
            if (raw2.status !== 200) {
              if (res2.message) toastError(res2.message);
              return;
            }
            setIsLoading(true);
            const raw3 = await fetch(
              `${ERECEIPT_ROOT_WS}api/public/auth/profile`,
              {
                headers: {
                  'X-Secret-Code': SECRET_CODE,
                  Authorization: `Bearer ${res2.data.token}`,
                },
              }
            );
            setIsLoading(false);
            const res3 = await raw3.json();
            if (raw3.status !== 200) {
              if (res3.message) toastError(res3.message);
              return;
            }
            onCallback({
              code: res3?.data?.prefix,
              token: res3?.data?.partner_tokens?.[0]?.secret,
            });
            onClose();
            setIsLoading(false);
            return;
          }

          // FOR INITIAL VERIFICATION
          const raw = await fetch(
            `${ERECEIPT_ROOT_WS}api/public/auth/request_otp`,
            {
              method: 'POST',
              body: JSON.stringify({ email: form.email }),
              headers: {
                'X-Secret-Code': SECRET_CODE,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          );
          setIsLoading(false);
          if (raw.status === 200) {
            setOtpSent(true);
          }
          const res = await raw.json();
          if (res.message) toastError(res.message);
        }}
      >
        <div>
          <img className="w-48 mx-auto" src={logo} alt="" />
        </div>
        <div className="space-y-1">
          <FormInput
            label="Enter your eReceipt Account"
            name="email"
            placeholder="Email Address"
            onChange={setForm}
            value={form.email}
            required
            disabled={isOtpSent}
          />
          {isOtpSent && (
            <>
              <FormPassword
                label="Password"
                name="password"
                onChange={setForm}
                value={form.password}
                required
              />
              <div>
                <div className="pt-4">
                  <div className="pt-2 text-center bg-blue-100 text-blue-600 py-2 rounded-t-lg px-3">
                    Please check your email for the One-Time-PIN
                  </div>
                </div>
                <FormInput
                  wrapperClassName="rounded-t-none"
                  label="One-Time-PIN"
                  name="pin"
                  placeholder="6-Digit PIN"
                  onChange={setForm}
                  value={form.pin}
                  required
                />
              </div>
            </>
          )}
        </div>
        <div className="space-y-2">
          {!isOtpSent ? (
            <>
              <Button
                className="w-full"
                primary
                type="submit"
                disabled={isLoading}
              >
                Send OTP and Continue
              </Button>
              <Button
                className="w-full"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button
                className="w-full"
                primary
                type="submit"
                disabled={isLoading}
              >
                Continue
              </Button>
              <Button
                className="w-full"
                type="button"
                onClick={() => {
                  setOtpSent(false);
                }}
              >
                Back
              </Button>
            </>
          )}
        </div>
        <div className="text-center">
          Don&apos;t have an account yet?
          <br />
          <a href={ERECEIPT_WEBSITE_URL} target="_blank" rel="noreferrer">
            Create account
          </a>
        </div>
      </Form>
    </div>
  );
}

ConnectWidget.propTypes = {
  onCallback: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
