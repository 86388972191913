import { useState, useEffect, useCallback, useMemo } from 'react';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';
import { useAccountMeta, useActiveAccount } from 'modules/auth/hooks';

import * as c from './constants';
import * as actions from './actions';

export const useMerchantList = (params, onSuccess) => {
  const isLoading = useApiLoading(c.MERCHANT_LIST, 'get');
  const data = useApiGet(c.MERCHANT_LIST, []);
  const pager = useApiGet(c.MERCHANT_PAGER, {});
  useEffect(() => {
    actions.listMerchants(params, onSuccess);
    // eslint-disable-next-line
  }, [params]);
  return [isLoading, data, pager];
};

export const useMerchantListSelected = () => {
  const isLoading = useApiLoading(c.MERCHANT_LIST, 'get');
  const data = useApiGet(c.MERCHANT_LIST, []);
  const pager = useApiGet(c.MERCHANT_PAGER, {});
  return [isLoading, data, pager];
};

export const useMerchantSelected = () => {
  const isLoading = useApiLoading(c.MERCHANT_ITEM, 'get');
  const data = useApiGet(c.MERCHANT_ITEM, {});
  return [isLoading, data];
};

export const useQuickMerchant = () => {
  // const isLoading = useApiLoading(`${c.MERCHANT_ITEM}/quick`, 'get');
  const data = useApiGet(`${c.MERCHANT_ITEM}/quick`, {});
  return [data];
};

export const useSelectMerchant = (id) => {
  useEffect(() => {
    if (!id) {
      req.set(c.MERCHANT_ITEM, null);
      return;
    }
    actions.selectMerchant(id);
  }, [id]);
};

export const useOrgFirstMerchantData = () => {
  const isLoading = useApiLoading(c.ORG_MERCHANT, 'get');
  const data = useApiGet(c.ORG_MERCHANT, {});

  const fetchData = useCallback(() => actions.getOrgMerchant(), []);

  useEffect(fetchData, [fetchData]);

  return [isLoading, data, fetchData];
};

export const useCreateMerchant = () => {
  const isLoading = useApiLoading(c.MERCHANT_ITEM, 'post');
  const [activeAccount] = useActiveAccount();
  const submit = useCallback(
    (raw, onSuccess, onError) => {
      const payload = {
        organization_uuid: activeAccount.id,
        ...raw,
      };
      actions.createMerchant(payload, onSuccess, onError);
    },
    [activeAccount]
  );
  return [isLoading, submit];
};

export const useUpdateMerchant = () => {
  const isLoading = useApiLoading(c.MERCHANT_ITEM, 'put');
  const submit = useCallback((id, payload, onSuccess) => {
    actions.updateMerchant(id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateMerchantTheme = () => {
  const isLoading = useApiLoading(c.MERCHANT_ITEM_THEME, 'put');
  const { meta, update } = useAccountMeta();
  const submit = useCallback(
    (id, payload, onSuccess) => {
      actions.updateMerchantTheme(id, payload, (r) => {
        if (!meta?.is_theme_customized) update({ is_theme_customized: true });
        if (onSuccess) onSuccess(r);
      });
    },
    [meta, update]
  );
  return [isLoading, submit];
};

export const useRemoveMerchant = () => {
  const isLoading = useApiLoading(c.MERCHANT_ITEM, 'remove');
  const submit = useCallback((id, onSuccess) => {
    actions.removeMerchant(id, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useSettlementList = (params) => {
  const isLoading = useApiLoading(c.MERCHANT_SETTLEMENT_LIST, 'get');
  const list = useApiGet(c.MERCHANT_SETTLEMENT_LIST, []);
  const pager = useApiGet(c.MERCHANT_SETTLEMENT_PAGER, {});
  useEffect(() => {
    actions.listMerchantSettlement(params);
  }, [params]);
  return [
    false,
    [
      // FIXME: STATIC DATA
      {
        id: 1,
        created_at_human: 'Aug 26 2021, 04:12 PM',
        refno: 'REF1234',
        billing: 'Aug 24, 2021',
        debit: '1,000',
        credit: '',
        balance: '2,500',
        remarks: 'Static collection',
      },
      {
        id: 2,
        created_at_human: 'Aug 25 2021, 04:10 PM',
        refno: 'REF1233',
        billing: 'Aug 24, 2021',
        debit: '',
        credit: '500',
        balance: '1,500',
        remarks: 'Static settlement',
      },
      {
        id: 3,
        created_at_human: 'Aug 24 2021, 04:05 PM',
        refno: 'REF1233',
        billing: 'Aug 24, 2021',
        debit: '2,000',
        credit: '',
        balance: '2,000',
        remarks: 'Static collection',
      },
    ],
    {},
    isLoading,
    list,
    pager,
  ];
};

export const useSettlementCountCard = (name, filter) => {
  const isLoading = useApiLoading(`${c.COUNT_CARD}/${name}`, 'get');
  const { value } = useApiGet(`${c.COUNT_CARD}/${name}`, {});
  useEffect(() => {
    const action = actions.getCountCard[name];
    if (typeof action === 'function') action(filter);
  }, [name, filter]);
  return [isLoading, value];
};

/* MERCHANT PROFILE */
export const useGetGeneralInfo = () => {
  const [, merchant] = useMerchantSelected();
  const isLoading = useApiLoading(c.MERCHANT_GENERAL_INFO, 'get');
  const data = useApiGet(c.MERCHANT_GENERAL_INFO, {});
  useEffect(() => {
    actions.getGeneralInfo(merchant?.id);
  }, [merchant]);
  return [isLoading, data];
};

export const useGetBusinessInfo = () => {
  const [, info] = useGetGeneralInfo();
  const isLoading = useApiLoading(c.MERCHANT_BUSINESS_INFO, 'get');
  const data = useApiGet(c.MERCHANT_BUSINESS_INFO, {});
  useEffect(() => {
    if (info?.id && info?.business_type) {
      actions.getBusinessInfo(info?.id, info?.business_type);
    }
  }, [info]);
  return [isLoading, data];
};
/* MERCHANT PROFILE END */

/* MERCHANT CATEGORY & CHANNEL ATTACHMENT START */
export const useMerchantAttachedCategories = (id) => {
  const isLoading = useApiLoading(c.LIST_PAYMENT_CATEGORIES(id), 'get');
  const data = useApiGet(c.LIST_PAYMENT_CATEGORIES(id), []);

  const fetchData = useCallback(() => {
    actions.listMerchantAttachedCategories(id);
  }, [id]);

  useEffect(fetchData, [fetchData]);

  return [isLoading, data, fetchData];
};

export const usePaymentCategoryChannelList = (merchantId, categoryId) => {
  const isLoading = useApiLoading(
    c.LIST_PAYMENT_CATEGORY_CHANNELS(categoryId),
    'get'
  );
  const list = useApiGet(c.LIST_PAYMENT_CATEGORY_CHANNELS(categoryId), []);

  const fetchData = useCallback(() => {
    if (!merchantId || !categoryId) return;
    actions.listPaymentCategoryChannels(merchantId, categoryId);
  }, [merchantId, categoryId]);

  const setList = useCallback(
    (newList) => {
      req.set(c.LIST_PAYMENT_CATEGORY_CHANNELS(categoryId), newList);
    },
    [categoryId]
  );

  useEffect(fetchData, [fetchData]);

  return [isLoading, list, fetchData, setList];
};

export const useTogglePaymentChannelStatus = (
  initStatus,
  merchantId,
  categoryId,
  channelId,
  callback
) => {
  const [status, setStatus] = useState(initStatus);
  const isLoadingStatus = useApiLoading(c.TOGGLE_CATEGORY_STATUS, 'put');
  const activateChannel = useCallback(() => {
    setStatus('ACTIVE');
    actions.toggleAttachedChannelStatus(
      merchantId,
      categoryId,
      channelId,
      { status: 'ACTIVE' },
      callback,
      () => setStatus('INACTIVE')
    );
  }, [merchantId, categoryId, channelId, callback, setStatus]);
  const deactivateChannel = useCallback(() => {
    setStatus('INACTIVE');
    actions.toggleAttachedChannelStatus(
      merchantId,
      categoryId,
      channelId,
      { status: 'INACTIVE' },
      callback,
      () => setStatus('ACTIVE')
    );
  }, [merchantId, categoryId, channelId, callback, setStatus]);

  useEffect(() => {
    setStatus(initStatus);
  }, [initStatus]);

  return { status, isLoadingStatus, activateChannel, deactivateChannel };
};

export const useUpdatePaymentChannelSequence = (
  merchantId,
  categoryId,
  onSuccess = () => {},
  onError = () => {}
) => {
  const isLoading = useApiLoading(c.UPDATE_CHANNEL_SEQUENCE, 'put');
  // eslint-disable-next-line
  const updateChannelSequence = useCallback(
    actions.updateAttachedChannelSequence(
      merchantId,
      categoryId,
      onSuccess,
      onError
    ),
    [merchantId, categoryId, onSuccess, onError]
  );

  return [isLoading, updateChannelSequence];
};
/* MERCHANT CATEGORY & CHANNEL ATTACHMENT END */

export const useMerchantAttachments = (id) => {
  // const [, merchant] = useMerchantSelected();
  const isLoading = useApiLoading(c.MERCHANT_ATTACHMENTS, 'get');
  const data = useApiGet(c.MERCHANT_ATTACHMENTS, {});
  useEffect(() => {
    if (!id) {
      req.set(c.MERCHANT_ATTACHMENTS, {});
      return;
    }
    actions.merchantAttachments(id);
  }, [id]);
  return [isLoading, data];
};

export const useGetEReceipt = () => {
  const [, merchant] = useMerchantSelected();
  const isLoading = useApiLoading(c.MERCHANT_ERECEIPT, 'get');
  const data = useApiGet(c.MERCHANT_ERECEIPT, {});
  useEffect(() => {
    actions.getEReceipt(merchant?.id);
  }, [merchant]);
  const refetch = () => {
    actions.getEReceipt(merchant?.id);
  };
  return [isLoading, data, refetch];
};

export const useMerchantSettlementProfileOptions = (merchantId) => {
  const isLoading = useApiLoading(
    `${c.MERCHANT_SETTLEMENT_TEMPLATE}/options`,
    'get'
  );
  const options = useApiGet(`${c.MERCHANT_SETTLEMENT_TEMPLATE}/options`, []);
  useEffect(() => {
    actions.getMerchantSettlementProfileOptions(merchantId);
  }, [merchantId]);
  return [isLoading, options];
};

export const useMerchantSettlementTemplateList = (merchantId) => {
  const isLoading = useApiLoading(
    `${c.MERCHANT_SETTLEMENT_TEMPLATE}/${merchantId}`,
    'get'
  );
  const list = useApiGet(`${c.MERCHANT_SETTLEMENT_TEMPLATE}/${merchantId}`, []);
  useEffect(() => {
    actions.getMerchantSettlementTemplateList(merchantId);
  }, [merchantId]);
  return [isLoading, list];
};

export const useCreateMerchantSettlementTemplate = (merchantId) => {
  const isLoading = useApiLoading(c.MERCHANT_SETTLEMENT_TEMPLATE, 'post');
  const submit = useCallback(
    (payload, onSuccess, onError) => {
      actions.createMerchantSettlementTemplate(
        merchantId,
        payload,
        onSuccess,
        onError
      );
    },
    [merchantId]
  );
  return [isLoading, submit];
};

export const useUpdateMerchantSettlementTemplate = (merchantId) => {
  const isLoading = useApiLoading(c.MERCHANT_SETTLEMENT_TEMPLATE, 'put');
  const submit = useCallback(
    (id, payload, onSuccess, onError) => {
      actions.updateMerchantSettlementTemplate(
        merchantId,
        id,
        payload,
        onSuccess,
        onError
      );
    },
    [merchantId]
  );
  return [isLoading, submit];
};

export const useRemoveMerchantSettlementTemplate = (merchantId) => {
  const isLoading = useApiLoading(c.MERCHANT_SETTLEMENT_TEMPLATE, 'remove');
  const submit = useCallback(
    (id, onSuccess, onError) => {
      actions.deleteMerchantSettlementTemplate(
        merchantId,
        id,
        onSuccess,
        onError
      );
    },
    [merchantId]
  );
  return [isLoading, submit];
};

export const useMerchantSettlementDashboard = (name, filter) => {
  const isLoading = useApiLoading('SETTLEMENT_COUNT_CARD/aggs', 'get');
  const { value } = useApiGet(`SETTLEMENT_COUNT_CARD/${name}`, {});
  useEffect(() => {
    const action = actions.getSettlementDashboard[name];
    if (typeof action === 'function') action(filter);
    // eslint-disable-next-line
  }, [JSON.stringify(filter), name]);
  return [isLoading, value];
};

export const useSettlementLedgerBalance = (merchantId) => {
  const isLoading = useApiLoading(
    `${c.MERCHANT_SETTLEMENT_LEDGER_LIST}/balance`,
    'get'
  );
  const list = useApiGet(`${c.MERCHANT_SETTLEMENT_LEDGER_LIST}/balance`, []);
  useEffect(() => {
    actions.getSettlementLedgerBalance(merchantId);
    // eslint-disable-next-line
  }, [merchantId]);
  const flat = useMemo(() => {
    const arr = {};
    list.forEach((row) => {
      arr[row.settlement_profile_uuid] = row;
    });
    return arr;
  }, [list]);
  return [isLoading, list, flat];
};

export const useMerchantSettlementLedgerList = (merchantId, params) => {
  const isLoading = useApiLoading(c.MERCHANT_SETTLEMENT_LEDGER_LIST, 'get');
  const list = useApiGet(c.MERCHANT_SETTLEMENT_LEDGER_LIST, []);
  const pager = useApiGet(c.MERCHANT_SETTLEMENT_LEDGER_PAGER, {});
  useEffect(() => {
    actions.getMerchantSettlementLedgerList(merchantId, params);
    // eslint-disable-next-line
  }, [merchantId, params]);

  return [isLoading, list, pager];
};

export const useMerchantSettlementRequestList = (merchantId, params) => {
  const isLoading = useApiLoading(c.MERCHANT_SETTLEMENT_REQUEST_LIST, 'get');
  const list = useApiGet(c.MERCHANT_SETTLEMENT_REQUEST_LIST, []);
  const pager = useApiGet(c.MERCHANT_SETTLEMENT_REQUEST_PAGER, {});
  useEffect(() => {
    actions.getMerchantSettlementRequestList(merchantId, params);
    // eslint-disable-next-line
  }, [merchantId, params]);

  return [isLoading, list, pager];
};

export const useCreateMerchantSettlementRequest = () => {
  const isLoading = useApiLoading(c.MERCHANT_SETTLEMENT_REQUEST, 'post');
  const submit = useCallback((merchantId, payload, onSuccess) => {
    actions.createMerchantSettlementRequest(merchantId, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useUpdateMerchantSettlementRequest = () => {
  const isLoading = useApiLoading(c.MERCHANT_SETTLEMENT_REQUEST, 'put');
  const submit = useCallback((merchantId, id, payload, onSuccess) => {
    actions.updateMerchantSettlementRequest(merchantId, id, payload, onSuccess);
  }, []);
  return [isLoading, submit];
};

export const useDeleteMerchantSettlementRequest = () => {
  const isLoading = useApiLoading(c.MERCHANT_SETTLEMENT_REQUEST, 'remove');
  const submit = useCallback((merchantId, id, onSuccess) => {
    actions.deleteMerchantSettlementRequest(merchantId, id, onSuccess);
  }, []);
  return [isLoading, submit];
};
