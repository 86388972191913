import React, { useCallback } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { useInputId } from './hooks';

const FormInputNumber = React.forwardRef(
  (
    {
      id: defaultId,
      icon: Icon,
      label,
      name,
      onChange,
      onSetFieldValue,
      value,
      error,
      required,
      disabled,
      ...props
    },
    ref
  ) => {
    const [id] = useInputId(defaultId);
    const handleKeyPress = useCallback((e) => {
      if (
        (e.charCode >= 48 && e.charCode <= 57) ||
        e.charCode === 44 ||
        e.charCode === 46
      )
        return;
      e.preventDefault();
    }, []);
    const handleChange = ({ target }) => {
      if (typeof onChange === 'function') {
        onChange((state) => ({
          ...state,
          [name]: target.value,
        }));
      }
      if (typeof onSetFieldValue === 'function') {
        onSetFieldValue(name, target.value);
      }
    };
    const showIcon = Icon || error;
    const isError = !!error;
    return (
      <div
        className={cn(
          'relative border rounded-md bg-white px-3 py-2 focus-within:ring-1 z-[1]',
          isError
            ? 'border-red-300 focus-within:ring-red-500 focus-within:border-red-500 animate-wiggle'
            : 'border-gray-200 focus-within:ring-primary-600 focus-within:border-primary-600',
          disabled ? 'bg-gray-50 cursor-not-allowed' : ''
        )}
      >
        <label
          htmlFor={id}
          className="w-full text-xs font-medium text-primary-500 flex justify-between"
        >
          <span>{label}</span>
          {!required && (
            <span className="tracking-wider px-2 text-[8px] py-0.5 text-gray-500 rounded-full bg-gray-100 uppercase ml-1">
              optional
            </span>
          )}
        </label>
        <input
          id={id}
          ref={ref}
          type="text"
          name="name"
          className={cn(
            'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-base',
            'focus:outline-none',
            showIcon ? 'pr-10' : '',
            disabled ? 'bg-gray-50 cursor-not-allowed' : ''
          )}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          value={value || ''}
          disabled={disabled}
          {...props}
        />
        {showIcon && (
          <div
            className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            title={error || ''}
          >
            {error ? (
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            ) : (
              <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </div>
        )}
      </div>
    );
  }
);

FormInputNumber.defaultProps = {
  id: '',
  icon: false,
  type: 'text',
  error: null,
  required: false,
  onChange: false,
  onSetFieldValue: false,
  disabled: false,
};

FormInputNumber.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSetFieldValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.bool,
};

export default React.memo(FormInputNumber);
