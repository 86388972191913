import React, {
  Fragment,
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from 'react';
import * as yup from 'yup';
import cn from 'classnames';
import store from 'setup/store';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import { useFormik } from 'formik';
import history from 'setup/history';
import queryString from 'query-string';
import { Router } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { Provider, useApiLoading } from 'react-reqq-lite';
import ToastMarker, { toastError } from 'react-geek-toast';
import { XIcon, MinusIcon } from '@heroicons/react/outline';

import FormInput from 'partial/form/FormInput';

import Button from './Button';

const SIZE = {
  xs: 'max-w-sm',
  sm: 'max-w-lg',
  default: 'max-w-2xl',
  lg: 'max-w-4xl',
  xl: 'max-w-6xl',
  full: 'w-full',
};

const DrawerModal = ({ options, onClose }) => {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    setOpen(true);
  }, [setOpen]);
  const handleClose = () => {
    setOpen(false);
  };
  const sizeClassName = React.useMemo(
    () => SIZE[options.size] || SIZE.default,
    [options]
  );
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        open
        className={cn(
          'fixed inset-0 overflow-hidden',
          options?.zIndex || 'z-40'
        )}
        onClose={() => {
          if (options.closeOutside) handleClose();
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={onClose}
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={cn('relative w-screen', sizeClassName)}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-2 right-3 flex z-10">
                    <button
                      className={cn(
                        'rounded-full text-gray-600 hover:text-gray-900 focus:outline-none hover:ring-2 hover:ring-gray-600 bg-white transition p-3',
                        {
                          'opacity-0 pointer-events-none': !options.closeButton,
                        }
                      )}
                      onClick={handleClose}
                      type="button"
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white overflow-y-scroll">
                  {options.title && (
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {options.title}
                      </Dialog.Title>
                    </div>
                  )}
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    {options.content(handleClose)}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

DrawerModal.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const ModalMarker = () => {
  return <div id="modal-root" />;
};

export const closeModal = () => {
  const root = document.getElementById('modal-root');
  render(<ModalMarker />, root);
};

export const showDrawer = (options) => {
  const opt = {
    content: 'Modal Content',
    closeButton: true,
    closeOutside: false,
    onClose: () => {},
    size: 'default',
    title: '',
    // zIndex: '', optional props
    ...options,
  };
  const root = document.getElementById('modal-root');
  const handleClose = () => {
    opt.onClose();
    render(<ModalMarker />, root);
  };
  if (!root) {
    alert('Modal root not found!'); // eslint-disable-line
    return;
  }
  render(
    <Provider store={store}>
      <Router history={history}>
        <DrawerModal options={opt} onClose={handleClose} />
      </Router>
    </Provider>,
    root
  );
};

const AlertModal = ({ options, onClose }) => {
  const [form, setForm] = React.useState({ override: '' });
  const isLoading = useApiLoading(
    options.isLoadingKey,
    options.isLoadingMethod,
    false
  );
  const Icon = options.icon;
  const [open, setOpen] = React.useState(false);
  const overrideInputRef = React.useRef(null);
  const cancelButtonRef = React.useRef(null);
  React.useEffect(() => {
    setOpen(true);
  }, [setOpen]);
  const handleNo = () => {
    options.onNo(() => {
      setOpen(false);
    });
  };
  const handleYes = async (e) => {
    e.preventDefault();
    if (typeof options.onOverride === 'function') {
      if (await options.onOverride(form.override)) {
        options.onYes(() => {
          setOpen(false);
        });
        return;
      }
      setForm({ override: '' });
      toastError('Invalid!');
      return;
    }
    options.onYes(() => {
      setOpen(false);
    });
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto z-50"
        initialFocus={
          typeof options.onOverride === 'function'
            ? overrideInputRef
            : cancelButtonRef
        }
        open
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={onClose}
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              onSubmit={handleYes}
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  {Icon && (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-danger-100 sm:mx-0 sm:h-10 sm:w-10">
                      <Icon
                        className="h-6 w-6 text-danger-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 truncate"
                    >
                      {options.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {options.subTitle}
                      </p>
                    </div>
                    {typeof options.onOverride === 'function' ? (
                      <div className="my-2">
                        <FormInput
                          ref={overrideInputRef}
                          autoFocus
                          name="override"
                          onChange={setForm}
                          value={form?.override || ''}
                          placeholder={options?.overridePlaceholder || ''}
                          label=""
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse space-x-">
                <Button
                  className="w-full sm:w-auto sm:ml-3"
                  {...{ [options?.color]: true }}
                  disabled={isLoading}
                  type="submit"
                >
                  {options.yesLabel}
                </Button>
                <Button
                  className="w-full sm:w-auto sm:ml-3 mt-3 sm:mt-0"
                  onClick={handleNo}
                  ref={cancelButtonRef}
                >
                  {options.noLabel}
                </Button>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

AlertModal.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const AlertMarker = () => {
  return <div id="alert-root" />;
};

export const showAlert = (options) => {
  const opt = {
    icon: false,
    title: 'Confirmation',
    subTitle: 'Are you sure you want to proceed?',
    yesLabel: 'Proceed',
    onYes: (close) => close(),
    onNo: (close) => close(),
    noLabel: 'Cancel',
    color: 'danger',
    onOverride: false,
    overridePlaceholder: '',
    ...options,
  };
  const root = document.getElementById('alert-root');
  const handleClose = () => {
    render(<AlertMarker />, root);
  };
  if (!root) {
    alert('Alert root not found!'); // eslint-disable-line
    return;
  }
  render(
    <Provider store={store}>
      <Router history={history}>
        <AlertModal options={opt} onClose={handleClose} />
      </Router>
    </Provider>,
    root
  );
};

const LightboxModal = ({ options, onClose }) => {
  const [open, setOpen] = React.useState(false);
  const cancelButtonRef = React.useRef(null);
  React.useEffect(() => {
    setOpen(true);
  }, [setOpen]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto z-50"
        initialFocus={cancelButtonRef}
        open
        onClose={handleClose}
      >
        <div className="min-h-screen pt-4 px-4 pb-20 text-center flex items-center justify-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={onClose}
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="absolute top-0 right-0 pt-2 pr-2 flex z-50">
            <button
              className="rounded-full text-white hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-white transition bg-opacity-0 hover:bg-opacity-100 hover:bg-white p-3"
              onClick={handleClose}
              type="button"
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-xl text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl w-full">
              <div className="aspect-h-4 aspect-w-3 sm:aspect-w-3 sm:aspect-h-2">
                {queryString.parseUrl(options.content)?.query?.type ===
                'pdf' ? (
                  <iframe
                    src={options.content}
                    frameBorder="0"
                    title="File Preview"
                    allowtransparency="true"
                    style={{
                      overflow: 'hidden',
                      height: '80vh',
                      width: '100%',
                    }}
                    height="100%"
                    width="100%"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  />
                ) : (
                  <img
                    className="object-scale-down"
                    src={options.content}
                    alt={options.title}
                  />
                )}
              </div>
              <div className="text-slate-600 absolute bottom-0 left-0 w-full p-6 bg-slate-50">
                <div className="font-bold text-lg">{options.title}</div>
                <div>{options.description}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

LightboxModal.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const LightboxMarker = () => {
  return <div id="lightbox-root" />;
};

export const showLightbox = (options) => {
  const opt = {
    title: 'Image',
    description: '',
    content: '',
    ...options,
  };
  const root = document.getElementById('lightbox-root');
  const handleClose = () => {
    render(<LightboxMarker />, root);
  };
  if (!root) {
    alert('Lightbox root not found!'); // eslint-disable-line
    return;
  }
  render(
    <Provider store={store}>
      <Router history={history}>
        <LightboxModal options={opt} onClose={handleClose} />
      </Router>
    </Provider>,
    root
  );
};

/* NEW MODAL START */
const ModalContext = createContext();

function ModalTwo({ modal, i }) {
  const { setModals, states } = useContext(ModalContext);
  const [isTransformMinimize, setIsTransformMinimize] = useState(false);
  const { key, hookKey } = modal;

  const prevModalStateRef = useRef(null);
  const modalState = useMemo(() => {
    if (states[hookKey] !== prevModalStateRef.current) {
      prevModalStateRef.current = states[hookKey];
    }
    return states[hookKey];
  }, [states, hookKey]);

  const handleMaximizeModal = () => {
    setIsTransformMinimize(false);
  };

  const handleTransitionEnd = (e) => {
    if (Array.from(e.target.classList).includes('modal-minimize'))
      setIsTransformMinimize(true);
  };

  const handleCloseModal = (modalKey) => (e) => {
    e.stopPropagation();
    setModals((prev) => prev.filter((mdl) => mdl.key !== modalKey));
  };

  useEffect(() => {
    if (!isTransformMinimize) {
      setModals((prev) =>
        prev.map((mdl) => (mdl.key === key ? { ...mdl, status: 'open' } : mdl))
      );
    }
  }, [isTransformMinimize, key, setModals]);

  return (
    <>
      <div
        className={`border-l border-t border-r border-gray-500 mr-1 px-2 py-1
                    cursor-pointer rounded-tl-lg rounded-tr-lg flex items-center relative
                    ${isTransformMinimize ? '' : 'invisible'}`}
      >
        <button
          type="button"
          aria-label="maximze-tab"
          className="absolute inset-0 w-full h-full"
          onClick={handleMaximizeModal}
        />
        <p className="whitespace-nowrap text-sm">{modal.title}</p>
        <button
          type="button"
          className="border-l ml-2 pl-2 cursor-pointer relative z-10"
          onClick={handleCloseModal(modal.key)}
        >
          <XIcon className="w-4 h-4" />
        </button>
      </div>
      <div
        className={`modal-animate fixed inset-0 bg-gray-500 bg-opacity-75 transition-all duration-100 ease-linear overflow-auto p-4
         ${modal?.status === 'open' ? '' : 'modal-minimize cursor-pointer'}
         ${isTransformMinimize ? 'hidden' : ''}`}
        style={{
          zIndex: 9999,
          right: modal.status === 'minimize' ? `${i * 100}px` : '0',
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        {/* eslint-disable-next-line */}
        <div
          className="w-full h-full relative grid place-items-center"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="w-full p-4 grid place-items-center">
            {typeof modal?.content === 'function'
              ? modal?.content(modalState)
              : modal?.content}
          </div>
        </div>
        {modal.status === 'minimize' && (
          <div className="absolute w-full h-full" />
        )}
      </div>
    </>
  );
}

ModalTwo.defaultProps = {
  modal: {
    hookKey: '',
    title: '',
  },
};

ModalTwo.propTypes = {
  modal: PropTypes.shape({
    title: PropTypes.string,
    status: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    hookKey: PropTypes.string,
    content: PropTypes.instanceOf(Object).isRequired,
  }),
  i: PropTypes.number.isRequired,
};

export function ModalProvider({ children }) {
  const [modals, setModals] = useState([]);
  const [states, setStates] = useState({});

  return (
    <ModalContext.Provider value={{ setModals, modals, states, setStates }}>
      <ToastMarker />
      <AlertMarker />
      <ModalMarker />
      <LightboxMarker />
      <div className="fixed bottom-0 right-0 max-w-full flex flex-row-reverse z-[999] overflow-x-scroll">
        {modals.map((modal, i) => (
          <React.Fragment key={modal.key}>
            <ModalTwo modal={modal} i={i} />
          </React.Fragment>
        ))}
      </div>
      {children}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export function ModalCard({ title, children, onMinimize, onClose, size }) {
  const showHeader = useMemo(() => title || onClose || onMinimize, [
    title,
    onClose,
    onMinimize,
  ]);
  return (
    <div className={`modal-card ${size}`} style={{ height: 'max-content' }}>
      {showHeader && (
        <header className="p-5 flex border-b">
          {title && <h2 className="font-medium text-lg">{title}</h2>}
          <div className="ml-auto flex">
            {onMinimize && (
              <button type="button" onClick={onMinimize}>
                <MinusIcon className="w-6 h-6" />
              </button>
            )}
            {onClose && (
              <button type="button" className="ml-4" onClick={onClose}>
                <XIcon className="w-6 h-6" />
              </button>
            )}
          </div>
        </header>
      )}
      {children}
    </div>
  );
}

ModalCard.defaultProps = {
  title: '',
  size: 'md',
  onClose: null,
  onMinimize: null,
};

ModalCard.propTypes = {
  title: PropTypes.string,
  onMinimize: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(Object),
  ]),
  onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Object)]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export function AlertCard({
  children,
  onYes,
  onNo,
  isLoading,
  yesLabel,
  noLabel,
  disableYesBtn,
  primaryYesBtn,
  focusTarget,
}) {
  return (
    <div className="modal-card sm" style={{ height: 'max-content' }}>
      <div className="p-8 py-5">{children}</div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse space-x-">
        <Button
          {...(primaryYesBtn ? { primary: true } : { danger: true })}
          danger
          className="w-full sm:w-auto sm:ml-3"
          disabled={isLoading || disableYesBtn}
          onClick={onYes}
          autoFocus={focusTarget === 'yes'}
        >
          {yesLabel}
        </Button>
        <Button
          className="w-full sm:w-auto sm:ml-3 mt-3 sm:mt-0"
          onClick={onNo}
          disabled={isLoading}
          autoFocus={focusTarget === 'no'}
        >
          {noLabel}
        </Button>
      </div>
    </div>
  );
}

AlertCard.defaultProps = {
  yesLabel: 'Yes',
  noLabel: 'No',
  isLoading: false,
  disableYesBtn: false,
  primaryYesBtn: false,
  focusTarget: 'no',
};

AlertCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  disableYesBtn: PropTypes.bool,
  primaryYesBtn: PropTypes.bool,
  focusTarget: PropTypes.string,
  // focusTarget: PropTypes.oneOf('yes', 'no'),
};

export const useCreateModal = (key = 'modal', state = {}) => {
  const { setModals, setStates } = useContext(ModalContext);
  const prevStateRef = useRef(null);

  useEffect(() => {
    if (JSON.stringify(prevStateRef.current) === JSON.stringify(state)) return;
    prevStateRef.current = state;
    setStates((prev) => ({ ...prev, [key]: state }));
  }, [state, setStates, key]);

  const createModal = useCallback(
    ({ title = 'Title', content = () => null }) => {
      const modalKey = `${key}-${Date.now()}`;
      const minimizeModal = () =>
        setModals((prev) =>
          prev.map((modal) => {
            return modal.key === modalKey
              ? {
                  ...modal,
                  status: 'minimize',
                }
              : modal;
          })
        );

      const closeThisModal = () => {
        setModals((prev) => prev.filter((modal) => modal.key !== modalKey));
      };
      setModals((prev) => [
        ...prev,
        {
          key: modalKey,
          hookKey: key,
          title,
          status: 'open',
          content: content(closeThisModal, minimizeModal),
        },
      ]);
    },
    [key, setModals]
  );
  return createModal;
};

export const useDestroyModal = () => {
  const { setModals, setStates } = useContext(ModalContext);

  const destroyModal = useCallback(() => {
    setModals([]);
    setStates({});
  }, [setModals, setStates]);

  return destroyModal;
};

function TypeConfirmAlert({
  onYes,
  onNo,
  yesLabel,
  noLabel,
  label,
  subLabel,
  stringToMatch,
  icon: Icon,
}) {
  const destroyModal = useDestroyModal();
  const { handleSubmit, setFieldValue, errors, values } = useFormik({
    validateOnMount: true,
    initialValues: { confirm: '' },
    validationSchema: yup.object({
      confirm: yup
        .string()
        .matches(new RegExp(`^${stringToMatch}$`), 'Incorrect')
        .required(),
    }),
    onSubmit: () => {
      if (typeof onYes === 'function') return onYes(destroyModal);
      return destroyModal();
    },
  });

  return (
    <AlertCard
      onYes={handleSubmit}
      onNo={() => {
        if (typeof onNo === 'function') return onNo(destroyModal);
        return destroyModal();
      }}
      yesLabel={yesLabel}
      noLabel={noLabel}
      disableYesBtn={!isEmpty(errors)}
    >
      <div className="w-full space-y-4">
        <div className="flex space-x-4 items-center">
          {Icon && (
            <Icon
              size={60}
              style={{
                color: 'rgb(239, 68, 68)',
                flexShrink: 0,
              }}
            />
          )}
          <div className="space-y-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {label || ''}
            </h3>
            <p className="text-sm text-gray-500">{`${
              subLabel || ''
            }Kindly type "${stringToMatch}" to proceed.`}</p>
          </div>
        </div>
        <FormInput
          label=""
          name="confirm"
          value={values.confirm}
          onSetFieldValue={setFieldValue}
          placeholder={stringToMatch}
          autoFocus
          required
        />
      </div>
    </AlertCard>
  );
}

TypeConfirmAlert.defaultProps = {
  yesLabel: 'yes',
  noLabel: 'Noe',
  subLabel: '',
  icon: null,
};

TypeConfirmAlert.propTypes = {
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  stringToMatch: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object),
};

export const useOpenTypeConfirmAlert = () => {
  const createModal = useCreateModal();

  const defaultOptions = useMemo(
    () => ({
      onYes: () => {},
      onNo: () => {},
      yesLabel: 'Yes',
      noLabel: 'No',
      label: '',
      subLabel: '',
      stringToMatch: 'confirm',
    }),
    []
  );

  const openTypeConfirmAlert = useCallback(
    (
      options = {
        onYes: () => {},
        onNo: () => {},
        yesLabel: 'Yes',
        noLabel: 'No',
        label: '',
        subLabel: '',
        stringToMatch: '',
      }
    ) => {
      const opt = { ...defaultOptions, ...options };
      createModal({
        content: () => (
          <TypeConfirmAlert onNo={opt.onNo} onYes={opt.onYes} {...opt} />
        ),
      });
    },
    [createModal, defaultOptions]
  );

  return openTypeConfirmAlert;
};

/* NEW MODAL END */
