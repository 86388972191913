import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { HiOutlineMail } from 'react-icons/hi';
import ReCAPTCHA from 'react-google-recaptcha';

import FormInput from 'partial/form/FormInput';
import Button from 'partial/components/Button';
import Form, { ErrorLabel, setError422 } from 'partial/form/Form';

import { useForgotPassword } from '../hooks';

const initialValues = {
  email_address: '',
};

const validationSchema = yup.object({
  email_address: yup.string().email('Invalid').required('Required'),
});

function ForgotPasswordModal({ onClose, onSuccess }) {
  const recaptchaRef = useRef();
  const [validateOnChange, setValidateOnChange] = useState(false);

  const {
    isLoadingSendEmail: isLoading,
    sendEmailInstruction,
  } = useForgotPassword(null, () => recaptchaRef.current?.reset());

  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues,
    validateOnChange,
    validationSchema,
    onSubmit: async (form) => {
      const captcha = await recaptchaRef.current.executeAsync();
      sendEmailInstruction(
        { ...form, captcha },
        () => onSuccess(form?.email_address),
        (err) => {
          setError422(err, setFieldError);
        }
      );
    },
  });

  return (
    <Form onSubmit={handleSubmit} error={errors}>
      <div className="mb-2">
        <p className="text-gray-500 mb-3">
          Enter your registered email below to receive password reset
          instructions.
        </p>
        <small className="text-gray-500 mb-2 italic inline-block">
          <strong className="font-medium">Note:</strong> Once you reset your
          password both your test and prod creds will reset.
        </small>
        <FormInput
          name="email_address"
          label="Please enter your email address"
          onSetFieldValue={setFieldValue}
          value={values?.email_address}
          icon={HiOutlineMail}
          error={errors?.email_address}
          required
        />
        <ErrorLabel name="email_address" />
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6Lfg6XcmAAAAAFrfHdO6JqNzPQn4WtvgfTVZivSC" // TEMPORARY
        // sitekey={
        //   process.env.REACT_APP_RECAPTCHA ||
        //   '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        // }
      />
      <div className="flex gap-2 mt-2 text-sm justify-end">
        <Button onClick={onClose}>Close</Button>
        <Button
          type="submit"
          primary
          disabled={isLoading}
          onClick={() => setValidateOnChange(true)}
        >
          Send Instructions
        </Button>
      </div>
    </Form>
  );
}

ForgotPasswordModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ForgotPasswordModal;
