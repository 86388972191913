/* eslint-disable */
import React, { useRef } from 'react';
import { isEmpty } from 'lodash';
import { req } from 'react-reqq-lite';
import { BiInfoCircle } from 'react-icons/bi';
import ToolTip from 'partial/components/ToolTip';

import {
  useGeneralInfo,
  useSubmitApplication,
} from 'core-modules/merchant-onboarding/hooks';
import Splash from 'partial/containers/Splash';
import Button from 'partial/components/Button';
import { useCurrentAccount } from 'modules/auth/hooks';
import { useMerchantList } from 'core-modules/merchants/hooks';
import { MERCHANT_ITEM } from 'core-modules/merchants/constants';
import {
  promiseGetEReceipt,
  promiseGetGeneralInfo,
} from 'core-modules/merchant-onboarding/actions';

import ReviewForm from '../forms/ReviewForm';

const LIST_FILTER = { per_page: 1 };

const ReviewApplication = (props) => {
  const formRef = useRef();
  const [isLoading, submit] = useSubmitApplication();

  const [, orgProfileData] = useCurrentAccount();

  useMerchantList(LIST_FILTER, (list) => {
    const firstId = list?.[0]?.id;
    if (!firstId) return;
    req.set(MERCHANT_ITEM, list?.[0]); // SET MERCHANT AS TARGET FOR useSaveGeneralInfo
    promiseGetGeneralInfo(firstId);
    promiseGetEReceipt(firstId);
  });
  const [, data] = useGeneralInfo();
  if (isEmpty(data)) return <Splash />;
  return (
    <div>
      <ReviewForm
        {...props}
        ref={formRef}
        onSubmit={submit}
        onSuccess={props?.onNext}
      />
      <div className="flex space-x-0 sm:space-x-3 space-y-3 sm:space-y-0 flex-col sm:flex-row justify-end mt-4">
        {!props?.readOnly ? (
          <>
            <div className="mt-3 sm:mt-0 order-last sm:order-first">
              <Button
                size="lg"
                transparent
                className="w-full sm:w-auto"
                onClick={props?.onSkipped}
                disabled={isLoading}
              >
                <ToolTip
                  className="text-left font-normal"
                  tip="You can still skip this and continue to test your account."
                >
                  <div className="flex relative">
                    <div className="flex items-center border-dashed border-b-0 hover:border-gray-300 hover:border-b-1 space-x-2 cursor-pointer">
                      <div className="whitespace-nowrap">
                        Skip, I&apos;ll do this later.
                      </div>
                      <BiInfoCircle className="sm:block hidden" />
                    </div>
                  </div>
                </ToolTip>
              </Button>
              <p className="text-sm text-gray-500 mt-1 text-center sm:hidden flex space-x-1 items-center">
                <BiInfoCircle className="w-4 h-4 flex-shrink-0" />
                <span>
                  You can still skip this and continue to test your account.
                </span>
              </p>
            </div>
            <Button
              primary
              size="lg"
              className="whitespace-nowrap order-2 sm:order-1"
              onClick={() => {
                if (typeof formRef?.current?.submit === 'function')
                  formRef?.current?.submit();
              }}
              disabled={isLoading || !orgProfileData?.is_org_all_kyc_submitted}
            >
              {!orgProfileData?.is_org_all_kyc_submitted ? (
                <ToolTip
                  className="text-left font-normal"
                  tip="Complete all the previous steps to confirm your application."
                >
                  <div className="flex relative">
                    <div className="flex items-center border-dashed border-b-0 hover:border-gray-300 hover:border-b-1 space-x-2 cursor-pointer">
                      <div className="whitespace-nowrap">
                        Confirm and Submit
                      </div>
                      <BiInfoCircle className="sm:block hidden" />
                    </div>
                  </div>
                </ToolTip>
              ) : (
                'Confirm and Submit'
              )}
            </Button>
            {!orgProfileData?.is_org_all_kyc_submitted ? (
              <p className="text-sm text-gray-500 mt-1 text-center sm:hidden flex space-x-1 items-center">
                <BiInfoCircle className="w-4 h-4 flex-shrink-0" />
                <span>
                  Complete all the previous steps to confirm your application.
                </span>
              </p>
            ) : null}
          </>
        ) : (
          <Button
            primary
            size="lg"
            type="button"
            onClick={props?.onNext}
            className="whitespace-nowrap order-2 sm:order-1"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default ReviewApplication;
