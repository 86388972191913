/* eslint-disable */
import React, { useRef } from 'react';
import { isEmpty } from 'lodash';
import { BiInfoCircle } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import Splash from 'partial/containers/Splash';
import Button from 'partial/components/Button';
import ToolTip from 'partial/components/ToolTip';
// import FormUrl from 'partial/form/FormUrl';
import {
  useGeneralInfo,
  useSaveGeneralInfo,
} from 'core-modules/merchant-onboarding/hooks';
import {
  useCreateMerchant,
  useMerchantList,
} from 'core-modules/merchants/hooks';
import { promiseGetGeneralInfo } from 'core-modules/merchant-onboarding/actions';
import { MERCHANT_ITEM } from 'core-modules/merchants/constants';
import { req } from 'react-reqq-lite';
import { useAccountMeta } from 'modules/auth/hooks';
import MerchantForm from '../forms/MerchantForm';

const LIST_FILTER = { per_page: 1 };

const MerchantPage = (props) => {
  const history = useHistory();
  const formRef = useRef();

  const [error, setError] = React.useState(null);
  const [, create] = useCreateMerchant();

  const { meta, update } = useAccountMeta();
  const [isSubmitting, submit] = useSaveGeneralInfo();
  useMerchantList(LIST_FILTER, (list) => {
    const firstId = list?.[0]?.id;
    if (!firstId) {
      create(
        { name: 'My Merchant' },
        (newData) => {
          req.set(MERCHANT_ITEM, newData); // SET MERCHANT AS TARGET FOR useSaveGeneralInfo
          promiseGetGeneralInfo(newData?.id);
        },
        () => {
          setError('Unable to setup merchant. Please try again later.');
        }
      );
      return;
    }
    req.set(MERCHANT_ITEM, list?.[0]); // SET MERCHANT AS TARGET FOR useSaveGeneralInfo
    promiseGetGeneralInfo(firstId);
  });
  const [, data] = useGeneralInfo();
  if (error) return <div>{error}</div>;
  if (isEmpty(data)) return <Splash />;
  return (
    <div>
      <MerchantForm
        {...props}
        ref={formRef}
        onSubmit={submit}
        onSuccess={(v) => {
          props?.onNext(v);
          if (!meta?.is_first_merchant_general_submitted)
            update({ is_first_merchant_general_submitted: true });
        }}
      />
      <div className="flex space-x-0 sm:space-x-3 space-y-3 sm:space-y-0 flex-col sm:flex-row justify-end mt-4">
        {!props?.readOnly ? (
          <>
            <div className="mt-3 sm:mt-0 order-last sm:order-first">
              <Button
                size="lg"
                transparent
                className="w-full sm:w-auto"
                onClick={() => {
                  if (typeof props?.onNext === 'function') props?.onNext();
                }}
              >
                <ToolTip
                  className="text-left font-normal"
                  tip="Continue with the default merchant setup"
                >
                  <div className="flex relative">
                    <div className="flex items-center border-dashed border-b-0 hover:border-gray-300 hover:border-b-1 space-x-2 cursor-pointer">
                      <div className="whitespace-nowrap">
                        Skip project setup
                      </div>
                      <BiInfoCircle className="sm:block hidden" />
                    </div>
                  </div>
                </ToolTip>
              </Button>
              <p className="text-sm text-gray-500 mt-1 text-center sm:hidden flex space-x-1 items-center">
                <BiInfoCircle className="w-4 h-4 flex-shrink-0" />
                <span>Continue with the default project setup</span>
              </p>
            </div>
            <Button
              primary
              size="lg"
              disabled={isSubmitting}
              onClick={() => {
                formRef?.current?.submit();
                formRef?.current?.setValidateOnChange(true);
              }}
              className="whitespace-nowrap order-2 sm:order-1"
            >
              Save and Continue
            </Button>
          </>
        ) : (
          <Button
            primary
            size="lg"
            type="button"
            onClick={() => history.push('/dashboard')}
            className="whitespace-nowrap order-2 sm:order-1"
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default MerchantPage;
