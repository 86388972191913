export const SUFFIXES = ['Jr.', 'Sr.', 'II', 'III', 'IV', 'V', 'VI'];

export const MAP_BUSINESS_TYPE_DATA_SET_CODE = {
  GOVERNMENT: 'KYC_REQUIREMENT_GOVERNMENT',
  CORPORATION: 'KYC_REQUIREMENT_CORPORATION',
  SOLE_PROPRIETORSHIP: 'KYC_REQUIREMENT_SOLE',
  INDIVIDUAL: 'KYC_REQUIREMENT_INDIVIDUAL',
  PARTNERSHIP: 'KYC_REQUIREMENT_PARTNERSHIP',
};

export const E_RECEIPT_ROOT = 'https://ereceipt.gov.ph/';
