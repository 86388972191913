import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as assets from 'assets/images';

function ServiceItem({ className, img, title, description, ...props }) {
  return (
    <div
      className={cn(className, 'p-6 rounded-3xl')}
      data-aos="fade-up"
      {...props}
    >
      <div className="flex sm:items-center items-start space-x-2">
        <div className="flex-shrink-0 hidden sm:block">
          <img className="h-32 w-32 sm:h-32 sm:w-32" src={img} alt="Icon" />
        </div>
        <div className="space-y-2">
          <div className="text-lg sm:text-2xl font-semibold text-slate-900 mb-2">
            {title}
          </div>
          <div className="text-base sm:text-lg text-slate-500">
            <div className="flex-shrink-0 float-right sm:hidden block">
              <img className="h-32 w-32 sm:h-32 sm:w-32" src={img} alt="Icon" />
            </div>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

ServiceItem.propTypes = {
  className: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

function Services() {
  return (
    <div className="pt-16 md:pt-32 pb-16 overflow-hidden">
      <div className="container mx-auto">
        <div
          className="text-slate-900 text-3xl sm:text-5xl font-bold text-center mb-4"
          data-aos="fade-up"
        >
          Benefits of using our payment gateway
        </div>
        <div
          className="text-slate-500 text-xl sm:text-2xl text-center"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          Discover the Advantages of Our Secure and Convenient Payment
          Processing Solution
        </div>
        <div className="relative mt-12 md:mt-24">
          <div className="absolute bottom-[25%] left-[64px] -translate-x-full z-0 hidden md:block">
            <img
              className="flow-in w-[220px]"
              src={assets.flowIn}
              alt="Flow In"
            />
          </div>
          <div className="absolute bottom-[75%] right-[64px] translate-x-full z-0 hidden md:block">
            <img
              className="flow-out w-[220px]"
              src={assets.flowOut}
              alt="Flow Out"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 relative z-10">
            <ServiceItem
              className="col-span-1 md:col-span-2 bg-stone-100"
              img={assets.service1}
              title="Secure Transactions"
              description="Payment gateways provide secure payment processing, reducing the risk of fraud and chargebacks and giving everyone’s peace of mind."
              data-aos-delay="0"
            />
            <ServiceItem
              className="col-span-1 md:col-span-2 bg-indigo-100"
              img={assets.service4}
              title="Reduced Cost"
              description="We reduce the cost of payment processing for both the government and filipino customers. It is designed to minimize transaction fees and eliminate the need for manual payment processing."
              data-aos-delay="100"
            />

            <ServiceItem
              className="col-span-1 md:col-span-2 bg-teal-100"
              img={assets.service3}
              title="Easy Integration"
              description="Agencies can have user payments without the need for multiple logins or complicated payment procedures. This provides a seamless payment experience for users."
              data-aos-delay="0"
            />
            <ServiceItem
              className="col-span-1 md:col-span-2 bg-lime-100"
              img={assets.service2}
              title="Standard Process and Fees"
              description="By implementing a streamlined and standardized payment process with reasonable fees, agencies can improve the customer experience."
              data-aos-delay="100"
            />

            <ServiceItem
              className="col-span-1 md:col-span-2 bg-fuchsia-100"
              img={assets.service5}
              title="Multiple Payment Options"
              description="Our payment gateway supports a range of payment options popular in the Philippines, including credit and debit cards, online bank transfers, and e-wallets such as GCash, Maya, ShopeePay, GrabPay"
              data-aos-delay="0"
            />
            <ServiceItem
              className="col-span-1 md:col-span-2 bg-rose-100"
              img={assets.service6}
              title="Automated Reports"
              description="Agency can easily access important information related to their transactions, such as transaction history, payment status, and other relevant data"
              data-aos-delay="100"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

Services.propTypes = {};

export default Services;
